import { Component } from "@angular/core";

@Component({
    selector: "getting-started-markdown",
    templateUrl: "../../../../assets/docs/getting-started.md.html",
    styleUrls: ["./getting-started-markdown.component.scss"]
})
export class GettingStartedMarkdownComponent {

    constructor() {}
}

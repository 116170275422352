<header class="mgd-layout-page-title">
    <h2>
        <span class="nav-title">Users</span>

        <mat-icon *ngIf="editableUser">
            chevron_right
        </mat-icon>

        <span *ngIf="editableUser?.id">Edit User</span>

        <span *ngIf="editableUser && !editableUser.id">Create User</span>
    </h2>
</header>
<user-list *ngIf="!editableUser" [currentUser]="currentUser" [selectedTeam]="selectedTeam"
        (setEditableUser)="setEditableUser($event)" (selectedTeamUpdated)="selectedTeam = $event">
</user-list>
<user-details *ngIf="editableUser" [currentUser]="currentUser" [editableUser]="editableUser" [selectedTeam]="selectedTeam" (setEditableUser)="setEditableUser($event)">
</user-details>

import {
    Component, OnInit, Inject, Input, Output, EventEmitter, ErrorHandler
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { User } from "@shopliftr/common-js/shared";
import {
    UserService, NotificationService, CommonErrorHandler
} from "@shopliftr/common-ng";

@Component({
    selector: "user-bulk-confirmation",
    templateUrl: "./user-bulk-confirmation.component.html",
    styleUrls: ["./user-bulk-confirmation.component.scss"]
})
export class UserBulkConfirmationComponent implements OnInit {

    @Input() usersToUpdate: Array<User>;

    @Output() onUpdate = new EventEmitter<boolean>(false);

    @Output() afterUpdate = new EventEmitter<Array<User>>(false);

    showLimited = true;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
        private readonly _notificationService: NotificationService,
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler,
        private readonly _userService: UserService) {

        this.usersToUpdate = data.usersToUpdate;
    }


    ngOnInit(): void {

        if (this.usersToUpdate.length <= 3) {
            this.showLimited = false;
        }
    }


    toggleUserView(): void {

        this.showLimited = !this.showLimited;
    }


    deactivateSelectedUsers(): void {

        this.onUpdate.emit(true);
        let updateProgress = -1;

        const done = (): void => {

            this.onUpdate.emit(false);
            this.afterUpdate.emit(this.usersToUpdate);
        };

        const next = (): void => {

            if (updateProgress < this.usersToUpdate.length - 1) {
                updateProgress = updateProgress + 1;
                updateUser(this.usersToUpdate[updateProgress]);
            }
            else {
                done();
            }
        };

        const updateUser = (user: User): void => {

            // update user;
            if (user.active) {
                this._userService.deactivateUser(user.id).subscribe({
                    next: () => {

                        // success message
                        this._notificationService.displaySuccessMessage("User Deactivation Successful");
                        next();
                    },
                    error: (error) => {

                        // error message
                        this._errorHandler.handleComponentError(error);
                        next();
                    }
                });
            }
        };
        next();
    }
}

import { NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";

import { AdminMaterialModule } from "../shared/admin-material-module/admin-material.module";
import { SharedModule } from "../shared/shared.module";

import { PayloadGeneratorComponent } from "./payload-generator/payload-generator.component";
import { ToolsComponent } from "./tools.component";


@NgModule({
    declarations: [
        PayloadGeneratorComponent,
        ToolsComponent
    ],
    imports: [
        AdminMaterialModule,
        MatExpansionModule,
        SharedModule
    ],
    exports: [],
    providers: []
})
export class ToolsModule {}

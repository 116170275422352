import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { DealSearchManagementModule } from "./deal-search-management/deal-search-management.module";


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        DealSearchManagementModule,
        SharedModule
    ],
    exports: [],
    providers: []
})
export class DealSearchModule {}

import {
    Observable,
    throwError
} from "rxjs";
import {
    catchError,
    map
} from "rxjs/operators";

import {
    ErrorHandler,
    Inject,
    Injectable
} from "@angular/core";

import {
    DealSearchFilterOptions,
    ILocation,
    MarketArea
} from "@shopliftr/common-js/shared";
import {
    CommonErrorHandler,
    CoreUiException,
    MarketAreaService
} from "@shopliftr/common-ng";

import { DealSearchFiltersChipType } from "../enum/deal-search-filters-chip-type.enum";

import { IDealSearchFiltersChip } from "../interface/deal-search-filter-chip.interface";


/**
 * Service used for processing DealSearchFilterOptions models
 */
@Injectable({ providedIn: "root" })
export class DealSearchFiltersUtilityService {

    constructor(
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler,
        private readonly _marketAreaService: MarketAreaService
    ) {}


    /**
     * Retrieves the market areas near the given location
     */
    getMarketAreaIds(location: ILocation, chainIds?: Array<string>): Observable<Array<string> | CoreUiException> {

        return this._marketAreaService.nearby(location, chainIds).pipe(
            map((marketAreas: Array<MarketArea>) => {

                return marketAreas.map((marketArea: MarketArea) => {

                    return marketArea.id;
                });
            }),
            catchError((error) => {

                return throwError(() => this._errorHandler.handleServiceError(error));
            })
        );
    }


    /**
     * Extracts the selected chains and market areas and structures them for the query
     */
    processChainFilters(existingFilterOptions: DealSearchFilterOptions, chainMap: Map<string, Array<MarketArea>>): DealSearchFilterOptions {

        const options = existingFilterOptions.clone();

        const chainIds = new Array<string>();
        const marketAreaIds = new Array<string>();

        chainMap.forEach((value: Array<MarketArea>, key: string) => {

            chainIds.push(key);
            marketAreaIds.push(...value.map((marketArea: MarketArea) => {

                return marketArea.id;
            }));
        });

        // This is an AND because every chain must specify which market areas are being filtered
        if (chainIds.length && marketAreaIds.length) {
            options.chainIds = chainIds;
            options.marketAreaIds = marketAreaIds;
        }

        return options.clone();
    }


    /**
     * Sorts the chip filters into their individual types and returns a resolved set
     */
    sortFilterChips(existingFilterOptions: DealSearchFilterOptions, chips: Array<IDealSearchFiltersChip>): DealSearchFilterOptions {

        const options = existingFilterOptions.clone();

        const brands = new Array<string>();
        const manufacturers = new Array<string>();
        const upcs = new Array<string>();

        if (chips && chips.length) {
            chips.forEach((chip: IDealSearchFiltersChip) => {

                switch (chip.type) {
                case DealSearchFiltersChipType.Brand:
                    brands.push(chip.value);

                    break;
                case DealSearchFiltersChipType.Manufacturer:
                    manufacturers.push(chip.value);

                    break;
                case DealSearchFiltersChipType.Upc:
                    upcs.push(chip.value);

                    break;
                }
            });
        }

        options.brands = brands.length ? brands : undefined;
        options.manufacturers = manufacturers.length ? manufacturers : undefined;
        options.upcs = upcs.length ? upcs : undefined;

        return options.clone();
    }
}

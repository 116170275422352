import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";

import { AdminComponent } from "./admin.component";

import { AdminRoutingModule } from "./admin-routing.module";

import { PageNotFoundModule } from "./page-not-found/page-not-found.module";

import { ApiKeyModule } from "../api-key/api-key.module";
import { DashboardModule } from "../dashboard/dashboard.module";
import { DealSearchModule } from "../deal-search/deal-search.module";
import { GettingStartedModule } from "./getting-started/getting-started.module";
import { KeywordModule } from "../keyword/keyword.module";
import { UserModule } from "../user/user.module";

import { BatchIdDialogModule } from "../shared/dialogs/batch-id-dialog/batch-id-dialog.module";
import { SharedModule } from "../shared/shared.module";


@NgModule({
    declarations: [
        AdminComponent
    ],
    imports: [
        AdminRoutingModule,
        ApiKeyModule,
        BatchIdDialogModule,
        DashboardModule,
        DealSearchModule,
        GettingStartedModule,
        KeywordModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSidenavModule,
        PageNotFoundModule,
        SharedModule,
        UserModule
    ],
    exports: [
    ],
    providers: []
})
export class AdminModule {}

import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    Component,
    ErrorHandler,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { DataEntryDeal } from "@shopliftr/common-js/admin";
import {
    SortType,
    SortUtil
} from "@shopliftr/common-js/data";
import {
    DateTimeDisplayFormat,
    DateTimeMode,
    Deal,
    IListSortOption
} from "@shopliftr/common-js/shared";
import {
    CommonErrorHandler,
    ComponentEvent
} from "@shopliftr/common-ng";

import { DealSearchListActionEventType } from "./enum/deal-search-list-action-event-type.enum";

import { DealSearchListActionEvent } from "./event/deal-search-list-action-event";


@Component({
    selector: "deal-search-list",
    templateUrl: "./deal-search-list.component.html",
    styleUrls: ["./deal-search-list.component.scss"]
})
export class DealSearchListComponent implements OnChanges {

    @Input() elements: Array<Deal | DataEntryDeal>;

    @Input() disabled: boolean;

    @Input() displayColumns: Array<string>;

    @Input() highlightIds: Array<string>;

    @Input() pageIndex: number;

    @Input() pageSize: number;

    @Input() pageSizeOptions: Array<number>;

    @Input() processing: boolean;

    @Input() total: number;

    @Output() onAction: EventEmitter<DealSearchListActionEvent> = new EventEmitter<DealSearchListActionEvent>();

    dataSource = new MatTableDataSource<Deal | DataEntryDeal>();

    timezone: string;


    /**
     * Gets the correct display format for dates
     */
    get dateDisplayFormat(): string {

        return DateTimeDisplayFormat.getFormat(DateTimeMode.Date);
    }


    /**
     * Determines whether or not the empty state should be visible
     */
    get displayEmptyState(): boolean {

        return (!this.processing && this.dataSource && !this.dataSource.data.length);
    }


    constructor(
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler,
        @Inject("AppConfig") private readonly _appConfig: any
    ) {

        this.timezone = this._appConfig.timezone;
    }


    ngOnChanges(changes: SimpleChanges): void {

        // Store the deal search metadata before populating the data source
        if (changes["elements"]) {
            this.dataSource = new MatTableDataSource<Deal | DataEntryDeal>(this.elements);
        }
    }


    /**
     * Emits whenever the markup for the table changes
     */
    contentChanged(): void {

        this.onAction.emit(new ComponentEvent(DealSearchListActionEventType.ListLoad));
    }


    /**
     * Determines whether or not the given element is highlighted
     */
    isHighlighted(id: string): boolean {

        return coerceBooleanProperty(this.highlightIds && this.highlightIds.includes(id));
    }


    /**
     * Forces deselection of all products when the page changes
     */
    onPageChange(event: PageEvent): void {

        this.onAction.emit(new ComponentEvent(DealSearchListActionEventType.Page, event));
    }


    /**
     * Notifies the parent that a particular deal was selected
     */
    onSelect(event: Event, element: Deal | DataEntryDeal): void {

        this.onAction.emit(new ComponentEvent(DealSearchListActionEventType.Select, [element]));
    }


    /**
     * Forces deselection of all products when the sort changes
     */
    onSortChange(sortObject: IListSortOption | Sort): void {

        if (sortObject) {
            switch (SortUtil.getSortObjectType(sortObject)) {
            case SortType.AngularSort:
                this.onAction.emit(new ComponentEvent(
                    DealSearchListActionEventType.Sort,
                    {
                        field: (sortObject as Sort).active,
                        direction: sortObject.direction
                    }
                ));

                break;
            case SortType.ShopliftrSort:
                this.onAction.emit(new ComponentEvent(DealSearchListActionEventType.Sort, sortObject as IListSortOption));

                break;
            default:
                this._errorHandler.handleComponentError(new Error("Provided sort object is unsupported"));
            }
        }
    }


    /**
     * Notifies the parent to refresh the current deal set
     */
    reload(): void {

        this.onAction.emit(new ComponentEvent(DealSearchListActionEventType.ListReload));
    }
}

import {
    Component, Inject
} from "@angular/core";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    FormGroup, FormControl, Validators
} from "@angular/forms";
import {
    MatDialogRef, MAT_DIALOG_DATA
} from "@angular/material/dialog";


/**
 * The dialog that is used to collect a batch ID for certain batch tasks
 */
@Component({
    selector: "batch-id-dialog",
    templateUrl: "./batch-id-dialog.component.html",
    styleUrls: []
})
export class BatchIdDialogComponent {

    batchIdForm = new FormGroup({
        batchId: new FormControl("", [
            Validators.required
        ])
    });


    /**
     * Retrieves the form control associated with the batch ID
     */
    get batchIdControl(): FormControl {

        return this.batchIdForm.get("batchId") as FormControl;
    }


    /**
     * Determines whether or not hte dialog can resolve
     */
    get canSubmit(): boolean {

        return coerceBooleanProperty(this.batchIdForm.valid && this.batchIdForm.get("batchId"));
    }


    /**
     * Readonly property to display the dialog's title
     */
    get dialogTitle(): string {

        return (this._dialogTitle || "Please enter a batch ID");
    }

    private readonly _dialogTitle: string;


    constructor(
        private readonly _dialogRef: MatDialogRef<Component>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {

        this._dialogTitle = data.dialogTitle;
    }


    /**
     * Resolves the dialog with the user-supplied data
     */
    submit(): void {

        this._dialogRef.close(this.batchIdControl.value);
    }
}

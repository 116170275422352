/**
 * Indicates the type of events emitted by a DealSearchListComponent
 */
export enum DealSearchListActionEventType {
    ListLoad = "LIST_LOAD",
    ListReload = "LIST_RELOAD",
    Page = "PAGE",
    Select = "SELECT",
    Sort = "SORT"
}

import { NgModule } from "@angular/core";
import { CdkTableModule } from "@angular/cdk/table";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";

import {
    ActionButtonGroupModule, ExceptionHandlerModule, NotificationModule, TeamSelectorModule
} from "@shopliftr/common-ng";

import { SharedModule } from "../shared/shared.module";

import { ApiKeyComponent } from "./api-key.component";

import { ApiKeyListComponent } from "./api-key-list/api-key-list.component";
import { ApiKeyFormComponent } from "./api-key-form/api-key-form.component";


@NgModule({
    declarations: [
        ApiKeyComponent,
        ApiKeyFormComponent,
        ApiKeyListComponent
    ],
    imports: [
        ActionButtonGroupModule,
        CdkTableModule,
        ExceptionHandlerModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTableModule,
        NotificationModule,
        SharedModule,
        TeamSelectorModule
    ],
    providers: []
})
export class ApiKeyModule {}

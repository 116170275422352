import { NgModule } from "@angular/core";
import { MatLuxonDateModule } from "@angular/material-luxon-adapter";

import {
    ActionButtonGroupModule, DatePickerDirectivesModule, TeamSelectorModule
} from "@shopliftr/common-ng";

import { AdminMaterialModule } from "../shared/admin-material-module/admin-material.module";
import { SharedModule } from "../shared/shared.module";

import { DashboardComponent } from "./dashboard.component";
import { TaskChartViewComponent } from "./task-chart-view/task-chart-view.component";
import { TeamDashboardComponent } from "./team-dashboard/team-dashboard.component";
import { UserDashboardComponent } from "./user-dashboard/user-dashboard.component";


@NgModule({
    declarations: [
        UserDashboardComponent,
        DashboardComponent,
        TeamDashboardComponent,
        TaskChartViewComponent
    ],
    imports: [
        ActionButtonGroupModule,
        AdminMaterialModule,
        DatePickerDirectivesModule,
        MatLuxonDateModule,
        SharedModule,
        TeamSelectorModule
    ],
    exports: [],
    providers: []
})
export class DashboardModule {}

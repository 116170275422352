import {
    Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation
} from "@angular/core";
import { Subscription } from "rxjs";

import {
    FilterTimePeriod, User
} from "@shopliftr/common-js/shared";
import {
    TaskProgress, UserEventProgress
} from "@shopliftr/common-js/admin";

import {
    AuthenticationService, NotificationService
} from "@shopliftr/common-ng";

import { TaskService } from "../../services/task.service";
import { LuxonDateAdapter } from "@angular/material-luxon-adapter";
import {
    DateAdapter, MAT_DATE_LOCALE
} from "@angular/material/core";
import { DateTime } from "luxon";


@Component({
    selector: "user-dashboard",
    templateUrl: "./user-dashboard.component.html",
    styleUrls: ["./user-dashboard.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
            deps: [MAT_DATE_LOCALE]
        }
    ]
})
export class UserDashboardComponent implements OnDestroy, OnInit {

    @Output() onTaskNavigate: EventEmitter<string> = new EventEmitter<string>(false);


    debounceTimer: NodeJS.Timeout;

    averageDealEntryTime: number;

    currentUser: User;

    errorMessage: any;

    flyersCompleted: number;

    noFlyerSelected: boolean;

    progressDate: FilterTimePeriod;

    searching: boolean;

    taskProgress: Array<TaskProgress>;

    taskProgressCount: number;

    totalDealsEntered: number;

    users: Array<User>;


    // Exposing time periods to template
    filterTimePeriod = FilterTimePeriod;

    private readonly _subscriptions = new Subscription();

    private _startDate: DateTime;

    private _endDate: DateTime;

    private _previousStartDate: DateTime;

    private _previousEndDate: DateTime;

    constructor(
        private readonly _authService: AuthenticationService,
        private readonly _notificationService: NotificationService,
        private readonly _taskService: TaskService
    ) {}

    get timePeriodIsMonth(): boolean {

        return (this.progressDate === FilterTimePeriod.Month);
    }

    get timePeriodIsToday(): boolean {

        return (this.progressDate === FilterTimePeriod.Today);
    }

    get timePeriodIsWeek(): boolean {

        return (this.progressDate === FilterTimePeriod.Week);
    }


    get startDate(): DateTime {

        return this._startDate;
    }

    set startDate(startDate: DateTime) {

        this._endDate = null;
        this._startDate = startDate;
    }

    get endDate(): DateTime {

        return this._endDate;
    }

    set endDate(endDate: DateTime) {

        this._endDate = endDate;
        if (this._endDate &&
            endDate &&
            (this._previousStartDate?.valueOf() !== this._startDate?.valueOf() ||
            this._previousEndDate?.valueOf() !== this._endDate?.valueOf())) {
            this.search();
        }
    }

    ngOnDestroy(): void {

        clearTimeout(this.debounceTimer);

        this._subscriptions.unsubscribe();
    }


    ngOnInit(): void {

        this.progressDate = FilterTimePeriod.Today;
        this.flyersCompleted = 0;
        this.totalDealsEntered = 0;
        this.averageDealEntryTime = 0;
        this.currentUser = this._authService.authenticatedUser.getValue();
        this.setDateFilter(FilterTimePeriod.Today);
        this.search();
    }


    getTaskProgress(): void {

        this.searching = true;

        this.totalDealsEntered = 0;
        this.flyersCompleted = 0;
        this.averageDealEntryTime = 0;

        this._previousStartDate = this._startDate;
        this._previousEndDate = this._endDate;

        this._subscriptions.add(
            this._taskService.getTaskProgressForUser(
                this.progressDate,
                this.startDate?.valueOf(),
                this.endDate?.valueOf()
            ).subscribe({
                next: (userEventProgress: UserEventProgress) => {

                    this.taskProgress = userEventProgress.tasks;
                    this.taskProgressCount = this.taskProgress.length;
                    this.totalDealsEntered = userEventProgress.userProgress.dealsEntered;
                    this.averageDealEntryTime = userEventProgress.userProgress.avgEntryTime;
                    this.flyersCompleted = userEventProgress.flyersCompleted;

                    this.searching = false;
                },
                error: (error) => {

                    this.searching = false;
                    this._notificationService.displayErrorMessage(`Error getting task progress: ${error.message}`);
                },
                complete: () => {

                    this.searching = false;
                }
            })
        );
    }


    goToTask(filter?: string): void {

        this.onTaskNavigate.emit(filter);
    }


    search(): void {

        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(() => {

            this.getTaskProgress();
        }, 500);
    }


    setDateFilter(filter: FilterTimePeriod): void {

        this.progressDate = filter;

        const startDate = new Date();
        const endDate = new Date();

        const day = startDate.getDay();
        const date = startDate.getDate();

        switch (filter) {
        case FilterTimePeriod.Week:
            startDate.setDate(date - day);
            endDate.setDate(date + (7 - day));

            break;
        case FilterTimePeriod.Month:
            startDate.setDate(1);

            if ([1, 3, 5, 7, 8, 10, 12].includes(endDate.getMonth())) {
                endDate.setDate(31);
            }
            else if (endDate.getMonth() === 2) {
                endDate.setDate(endDate.getFullYear() % 4 === 0 ? 29 : 28);
            }
            else {
                endDate.setDate(30);
            }

            break;
        }

        this.startDate = DateTime.fromJSDate(startDate).startOf("day");
        this.endDate = DateTime.fromJSDate(endDate).endOf("day");

        this.search();
    }

}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    FormsModule, ReactiveFormsModule
} from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

import { LocationPickerComponent } from "./location-picker.component";

import { SharedModule } from "../shared.module";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
    declarations: [
        LocationPickerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        SharedModule,
        MatInputModule,
        MatFormFieldModule
    ],
    exports: [
        LocationPickerComponent
    ],
    providers: []
})
export class LocationPickerModule {}

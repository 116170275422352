import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ObserversModule } from "@angular/cdk/observers";
import { CdkTableModule } from "@angular/cdk/table";

import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";

import { DealSearchListComponent } from "./deal-search-list.component";

import { SharedModule } from "../../shared/shared.module";


@NgModule({
    declarations: [
        DealSearchListComponent
    ],
    imports: [
        CommonModule,
        CdkTableModule,
        MatIconModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        ObserversModule,
        SharedModule
    ],
    exports: [
        DealSearchListComponent
    ],
    providers: []
})
export class DealSearchListModule {}

import { Subscription } from "rxjs";

import {
    Component,
    ErrorHandler,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { MatExpansionPanel } from "@angular/material/expansion";

import { CommonRegex } from "@shopliftr/common-js/data";
import {
    Chain,
    DealSearchFilterOptions,
    ILocation,
    MarketArea
} from "@shopliftr/common-js/shared";
import {
    ChainService,
    CommonErrorHandler,
    ComponentEvent,
    Hotkey,
    HotkeysService,
    MarketAreaSearchOptions,
    MarketAreaService,
    NotificationService
} from "@shopliftr/common-ng";

import { DealSearchFiltersChipType } from "./enum/deal-search-filters-chip-type.enum";
import { DealSearchFiltersFormActionEventType } from "./enum/deal-search-filters-form-action-event-type.enum";
import { DealSearchFiltersPromotionPeriod } from "./enum/deal-search-filters-promotion-period.enum";

import { DealSearchFiltersFormActionEvent } from "./event/deal-search-filters-form-action-event";

import { IDealSearchFiltersChip } from "./interface/deal-search-filter-chip.interface";

import { DealSearchFiltersUtilityService } from "./service/deal-search-filters-utility.service";

import { DateTime } from "luxon";


@Component({
    selector: "deal-search-filters-form",
    templateUrl: "./deal-search-filters-form.component.html",
    styleUrls: ["./deal-search-filters-form.component.scss"]
})
export class DealSearchFiltersFormComponent implements OnChanges, OnDestroy, OnInit {

    @Input() disabled: boolean;

    @Input() filterOptions: DealSearchFilterOptions;

    /**
     * Will be set if the initial state has the historical flag set
     */
    @Input() searchHistoricalDealsDefault: boolean;

    @Output() onAction: EventEmitter<DealSearchFiltersFormActionEvent> = new EventEmitter<DealSearchFiltersFormActionEvent>();

    @ViewChild("locationPanel", { static: true }) locationPanel: MatExpansionPanel;

    /**
     * Alias to allow the promotion period enum values to be accessed in the template
     */
    dealSearchFiltersPromotionPeriod = DealSearchFiltersPromotionPeriod;

    /**
     * Alias to allow the chip type enum values to be accessed in the template
     */
    dealSearchFiltersChipType = DealSearchFiltersChipType;

    workingFilterOptions = new DealSearchFilterOptions();

    filterByMarketArea = false;

    searchHistoricalDeals = false;

    showCustomDateRange = false;

    allChains: Array<Chain>;

    // Used on the market area filter form
    addingNewChainFilter: boolean;

    workingChain: Chain;

    workingChainMarketAreas: Array<MarketArea> = [];

    workingMarketAreaIds: Array<string> = [];

    // Form fields which must be processed before they can be applied to the filter options
    chainIds = new Array<string>();

    chainMap = new Map<string, Array<MarketArea>>();

    chips = new Array<IDealSearchFiltersChip>();

    filterLocation: ILocation = {};

    searchEndDate: DateTime;

    searchStartDate: DateTime;

    maxDate: DateTime;

    minDate: DateTime;

    upcFocused = false;

    workingBrand: string;

    workingManufacturer: string;

    workingUpc: string;

    marketAreaText = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "=1": "1 Market Area",
        "other": "# Market Areas"
    };

    readonly defaultHotkeyOptions: Partial<Hotkey> = {
        allowIn: [
            "INPUT",
            "SELECT",
            "TEXTAREA"
        ],
        preventDefault: true
    };

    readonly submitFormHotkey = "meta.enter";

    private _lastChainId: string;

    private readonly _clearFieldEmitter = new EventEmitter<DealSearchFiltersChipType>(false);

    private _selectedPromotionPeriod: DealSearchFiltersPromotionPeriod;

    private readonly _subscriptions = new Subscription();

    /**
     * Determines whether or not the subForm for marketAreas is open
     */
    get filterByMarketAreaSubFormOpen() : boolean {
        return Boolean(this.workingChain || this.addingNewChainFilter);
    }

    /**
     * Determines whether or not a working chain filter can be saved
     */
    get canSaveFilter(): boolean {

        return Boolean(this.workingChain && this.workingMarketAreaIds?.length);
    }


    /**
     *  Parses the radius value
     */
    get distance(): number {

        if (this.filterLocation.distance) {
            const matches = new RegExp(CommonRegex.distance, "gi").exec(this.filterLocation.distance);

            return (matches ? (parseInt(matches.groups.value)) : undefined);
        }
        else {
            return undefined;
        }
    }

    set distance(value: number) {

        if (value) {
            this.filterLocation.distance = `${value}mi`;
        }
        else {
            this.filterLocation.distance = undefined;
        }
    }


    /**
     * Interfaces with the filterLocation to allow easy template access
     */
    get location(): any {

        // This guard prevents the location picker from starting with an error
        return (this.filterLocation.latitude && this.filterLocation.longitude) ? this.filterLocation : undefined;
    }

    // eslint-disable-next-line @typescript-eslint/method-signature-style
    set location(value: { latLng: { lat: () => number; lng: () => number }}) {

        this.filterLocation.latitude = value?.latLng?.lat();
        this.filterLocation.longitude = value?.latLng?.lng();
    }


    /**
     * If the promotion period is updated, set the appropriate start and end date
     */
    get selectedPromotionPeriod(): DealSearchFiltersPromotionPeriod {

        return this._selectedPromotionPeriod;
    }

    set selectedPromotionPeriod(value: DealSearchFiltersPromotionPeriod) {

        this.searchEndDate = DateTime.now().setZone(this.appConfig?.timezone as string);
        this.showCustomDateRange = false;

        switch (value) {
        case DealSearchFiltersPromotionPeriod.LastWeek:
            this.searchStartDate = this.searchEndDate.minus({
                weeks: 1
            });

            break;
        case DealSearchFiltersPromotionPeriod.LastTwoWeeks:
            this.searchStartDate = this.searchEndDate.minus({
                weeks: 2
            });

            break;
        case DealSearchFiltersPromotionPeriod.LastMonth:
            this.searchStartDate = this.searchEndDate.minus({
                months: 1
            });

            break;
        case DealSearchFiltersPromotionPeriod.LastTwoMonths:
            this.searchStartDate = this.searchEndDate.minus({
                months: 2
            });

            break;
        case DealSearchFiltersPromotionPeriod.LastSixMonths:
            this.searchStartDate = this.searchEndDate.minus({
                months: 6
            });

            break;
        case DealSearchFiltersPromotionPeriod.LastYear:
            this.searchStartDate = this.searchEndDate.minus({
                years: 1
            });

            break;
        case DealSearchFiltersPromotionPeriod.LastTwoYears:
            this.searchStartDate = this.searchEndDate.minus({
                years: 2
            });

            break;
        case DealSearchFiltersPromotionPeriod.Custom:
            this.showCustomDateRange = true;

            // falls through
        default:
            this.searchEndDate = undefined;
            this.searchStartDate = undefined;
        }

        this._selectedPromotionPeriod = value;
    }


    /**
     * Determines whether or not the location chip should be shown
     */
    get showLocationChip(): boolean {

        if (!this.filterByMarketArea) {
            return coerceBooleanProperty(this.location && this.distance);
        }
        else {
            // coerceBooleanProperty returns true for a value of 0
            return (this.chainMap.size > 0);
        }
    }


    constructor(
        private readonly _chainService: ChainService,
        private readonly _dealSearchFilterOptionsService: DealSearchFiltersUtilityService,
        private readonly _hotkeysService: HotkeysService,
        private readonly _marketAreaService: MarketAreaService,
        private readonly _notificationService: NotificationService,
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler,
        @Inject("AppConfig") public appConfig: any
    ) {

        const now = DateTime.now().setZone(appConfig.timezone as string);
        this.maxDate = now.endOf("day").plus({ week: 2 });
        this.minDate = DateTime.now().startOf("day")
            .minus({ year: 1 });
    }


    ngOnChanges(changes: SimpleChanges): void {

        if (changes.filterOptions) {
            this.workingFilterOptions = this.filterOptions ? this.filterOptions.clone() : new DealSearchFilterOptions();

            this.chips = new Array<IDealSearchFiltersChip>();

            if (this.workingFilterOptions.brands) {
                this.workingFilterOptions.brands.forEach((value: string) => {

                    this.addChip(DealSearchFiltersChipType.Brand, value);
                });
            }

            if (this.workingFilterOptions.manufacturers) {
                this.workingFilterOptions.manufacturers.forEach((value: string) => {

                    this.addChip(DealSearchFiltersChipType.Manufacturer, value);
                });
            }

            if (this.workingFilterOptions.upcs) {
                this.workingFilterOptions.upcs.forEach((value: string) => {

                    this.addChip(DealSearchFiltersChipType.Upc, value);
                });
            }

            if (
                !this.chainMap.size &&
                !this.workingMarketAreaIds?.length &&
                this.workingFilterOptions.chainIds &&
                this.workingFilterOptions.marketAreaIds
            ) {
                this._subscriptions.add(
                    this._marketAreaService.multiGet(
                        this.workingFilterOptions.marketAreaIds
                    ).subscribe((results: Array<MarketArea>) => {

                        this.chainMap = new Map<string, Array<MarketArea>>();
                        this.filterByMarketArea = true;

                        results.forEach((marketArea: MarketArea) => {

                            if (!this.chainMap.has(marketArea.chain.id)) {
                                this.chainMap.set(marketArea.chain.id, new Array<MarketArea>());
                            }

                            this.chainMap.set(marketArea.chain.id, this.chainMap.get(marketArea.chain.id).concat(marketArea));
                        });
                    })
                );
            }
        }

        if (changes.searchHistoricalDealsDefault && changes.searchHistoricalDealsDefault.isFirstChange) {
            this.searchHistoricalDeals = changes.searchHistoricalDealsDefault.currentValue;

            if (this.searchHistoricalDeals) {

                if (this.workingFilterOptions.startDate && this.workingFilterOptions.endDate) {
                    this.searchStartDate = DateTime.fromJSDate(this.workingFilterOptions.startDate)
                        .setZone(this.appConfig.timezone as string);
                    this.searchEndDate = DateTime.fromJSDate(this.workingFilterOptions.endDate)
                        .setZone(this.appConfig.timezone as string);
                }

                this._selectedPromotionPeriod = DealSearchFiltersPromotionPeriod.Custom;
                this.showCustomDateRange = true;
            }
        }
    }


    ngOnDestroy(): void {

        this._subscriptions.unsubscribe();
        this._removeHotkeys();
    }


    ngOnInit(): void {

        this._subscriptions.add(
            this._clearFieldEmitter.pipe().subscribe((type: DealSearchFiltersChipType) => {

                switch (type) {
                case DealSearchFiltersChipType.Brand:
                    this.clearWorkingBrand();

                    break;
                case DealSearchFiltersChipType.Manufacturer:
                    this.clearWorkingManufacturer();

                    break;
                case DealSearchFiltersChipType.Upc:
                    this.clearWorkingUpc();

                    break;
                }
            })
        );

        this._getChains();

        this._setupHotkeys();

        this.distance = 10;
    }

    /**
     * Adds a filter chip
     */
    addChip(type: DealSearchFiltersChipType, value?: string): void {

        if (value) {
            const chip: IDealSearchFiltersChip = {
                type: type,
                value: value
            };

            const existingChip = this.chips.filter((target: IDealSearchFiltersChip) => {

                return (target.type === chip.type && target.value === chip.value);
            });

            if (!existingChip.length) {
                this.chips.push(chip);
            }

            // Clear the field after the value is consumed
            this._clearFieldEmitter.emit(type);
        }
    }


    /**
     * Ensures that the chip only gets added if the UPC exists
     */
    addUpcChip(valid: boolean, value: string, event?: KeyboardEvent): void {

        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }

        if (valid) {
            this.addChip(DealSearchFiltersChipType.Upc, value);
        }
    }


    /**
     * Notifies the parent to clear the filters
     */
    clear(): void {

        this.distance = undefined;
        this.workingBrand = undefined;
        this.workingChain = undefined;
        this.workingChainMarketAreas = [];
        this.workingManufacturer = undefined;
        this.workingMarketAreaIds = [];
        this.workingUpc = undefined;
        this.chainIds = undefined;
        this.chainMap = new Map<string, Array<MarketArea>>();
        this.location = undefined;
        this.selectedPromotionPeriod = this.searchHistoricalDeals ? DealSearchFiltersPromotionPeriod.LastWeek : undefined;

        this.onAction.emit(new ComponentEvent(DealSearchFiltersFormActionEventType.Clear));
    }


    /**
     * Clears the distance value
     */
    clearDistance(): void {

        this.distance = undefined;
    }


    /**
     * Clears the keyword
     */
    clearKeyword(): void {

        this.workingFilterOptions.keyword = undefined;
    }


    /**
     * Clears the location
     */
    clearLocation(): void {

        this.location = undefined;
    }


    /**
     * Resets the view and closes the chain filter form
     */
    clearSelectedChain(): void {

        this.workingChain = undefined;
        this.setAddingNewChainFilter(false);
    }


    /**
     * Clears the working brand
     */
    clearWorkingBrand(): void {

        this.workingBrand = undefined;
    }


    /**
     * Clears the working manufacturer
     */
    clearWorkingManufacturer(): void {

        this.workingManufacturer = undefined;
    }


    /**
     * Clears the working upc
     */
    clearWorkingUpc(): void {

        this.workingUpc = undefined;
    }


    /**
     * Opens the chain filter for editing
     */
    editChainFilter(chainId: string): void {


        this.onTouched();
        // Market Areas are populated by the change handler for the workingChain model
        // '-> See getMarketAreasForChain
        this.workingChain = this._findChain(chainId);
    }


    /**
     * Displays the location filters
     */
    editLocation(): void {

        this.locationPanel.open();
    }


    /**
     * Builds a display string for the given chip
     */
    getChipLabel(chip: IDealSearchFiltersChip): string {

        let prefix: string;

        switch (chip.type) {
        case DealSearchFiltersChipType.Brand:
            prefix = "Brand: ";

            break;
        case DealSearchFiltersChipType.Manufacturer:
            prefix = "Manufacturer: ";

            break;
        case DealSearchFiltersChipType.Upc:
            prefix = "UPC: ";

            break;
        default:
            prefix = "";
        }

        return (prefix + chip.value);
    }


    /**
     * Retrieves the given chain's market areas
     */
    getMarketAreasForChain(chainId: string): void {

        // Only fetch the marketAreas if the chain has changed
        if (chainId && this._lastChainId !== chainId) {
            const filterOptions: MarketAreaSearchOptions = {
                chainId: chainId,
                sort: ["name"],
                sortDir: "asc"
            };

            this._subscriptions.add(
                this._marketAreaService.getAll(
                    filterOptions
                ).subscribe((results: Array<MarketArea>) => {

                    this.workingChainMarketAreas = results;

                    this.workingMarketAreaIds =
                        !this.chainMap.has(chainId) ? [] : this.chainMap.get(chainId).map((marketArea: MarketArea) => {

                            return marketArea.id;
                        });

                    this._lastChainId = chainId;
                })
            );
        }
    }


    /**
     * Constructs a tooltip showing the market areas associated with a chain filter
     */
    getMarketAreaTooltip(marketAreas: Array<MarketArea>): string {

        let output = "";

        if (marketAreas) {
            for (const marketArea of marketAreas) {
                output += `${marketArea.name}\x0A`;
            }
        }

        return output;
    }


    /**
     * Determines if the given chain is the selected chain
     */
    isChainSelected(id: string): boolean {

        return coerceBooleanProperty(this.workingChain && this.workingChain.id === id);
    }


    /**
     * Handles the user clicking on a chip
     */
    onChipClick(chip: IDealSearchFiltersChip): void {

        this.removeChip(chip);
    }


    /**
     * Notify the parent about a change in whether or not the user is searching against historical deals
     */
    onSearchHistoricalDealsChange(): void {

        this.selectedPromotionPeriod = this.selectedPromotionPeriod ?? DealSearchFiltersPromotionPeriod.LastWeek;

        this.onAction.emit(new ComponentEvent(DealSearchFiltersFormActionEventType.SearchHistorical, this.searchHistoricalDeals));
    }


    /**
     * Removes the given chain filter
     */
    removeChainFilter(chainId: string): void {

        this.onTouched();
        this.chainMap.delete(chainId);
    }


    /**
     * Removes the given chip from the active filters
     */
    removeChip(chip: IDealSearchFiltersChip): void {

        this.onTouched();
        this.chips = this.chips.filter((target: IDealSearchFiltersChip) => {

            return (target.type !== chip.type || target.value !== chip.value);
        });
    }


    /**
     * Adds the given chain filter to the filter map
     */
    saveChainFilter(): void {

        const marketAreas = this.workingChainMarketAreas.filter((marketArea: MarketArea) => {

            return (this.workingMarketAreaIds.includes(marketArea.id));
        });

        this.chainMap.set(this.workingChain.id, marketAreas);

        this.clearSelectedChain();
    }


    /**
     * Notifies the parent to return to the previous view
     */
    saveFilterOptions(): void {

        // Brands/Manufacturers/Upcs
        this.workingFilterOptions = this._dealSearchFilterOptionsService.sortFilterChips(this.workingFilterOptions, this.chips);

        // Time Period
        if (this.searchHistoricalDeals) {
            this.workingFilterOptions.startDate = this.searchStartDate?.toJSDate();
            this.workingFilterOptions.endDate = this.searchEndDate?.toJSDate();
        }
        else {
            this.workingFilterOptions.startDate = undefined;
            this.workingFilterOptions.endDate = undefined;
        }

        // MarketAreaIds
        if (this.showLocationChip) {
            if (this.filterByMarketArea) {
                this.workingFilterOptions =
                        this._dealSearchFilterOptionsService.processChainFilters(this.workingFilterOptions, this.chainMap);

                this._resolveFilterOptions();
            }
            else {
                this._subscriptions.add(
                    this._dealSearchFilterOptionsService.getMarketAreaIds(this.filterLocation, this.chainIds).subscribe(
                        {
                            next: (marketAreaIds: Array<string>) => {

                                this.workingFilterOptions.chainIds = this.chainIds.length ? this.chainIds : undefined;
                                this.workingFilterOptions.marketAreaIds = marketAreaIds;

                                this._resolveFilterOptions();
                            },
                            error: (error: Error) => {

                                const resolvedError = this._errorHandler.handleComponentError(error);

                                this._notificationService.displayErrorMessage(resolvedError.message);
                            }
                        }
                    )
                );
            }
        }
        else {
            this.workingFilterOptions.chainIds = this.chainIds?.length ? this.chainIds : undefined;
            this.workingFilterOptions.marketAreaIds = undefined;

            this._resolveFilterOptions();
        }
    }


    /**
     * Sets the value of addingNewChainFilter and preps the chain filter form
     */
    setAddingNewChainFilter(value: boolean): void {

        this.addingNewChainFilter = coerceBooleanProperty(value);

        if (this.addingNewChainFilter) {
            this.workingChain = undefined;
            this.workingChainMarketAreas = [];
            this.workingMarketAreaIds = [];
        }
    }


    /**
     * Sets the flag that indicates whether or not the UPC filter field is focused
     */
    setUpcFocused(state: boolean): void {

        this.upcFocused = coerceBooleanProperty(state);
    }

    /**
     * Triggers a componentEvent with the Touched action type.
     * gets called when a input emits the event
     */
    onTouched(): void {

        this.onAction.emit(new ComponentEvent(DealSearchFiltersFormActionEventType.Touched));
    }

    /**
     * Returns the chain that corresponds to the given ID
     */
    private _findChain(id: string): Chain {

        return this.allChains.find((chain: Chain) => {

            return (chain.id === id);
        });
    }


    /**
     * Populates the chain set
     */
    private _getChains(): void {

        this._subscriptions.add(
            this._chainService.getAll().subscribe((result: Array<Chain>) => {

                this.allChains = result;
            })
        );
    }


    /**
     * Notifies the parent that the filterOptions have been updated
     */
    private _resolveFilterOptions(): void {

        this.onAction.emit(new ComponentEvent(DealSearchFiltersFormActionEventType.Update, this.workingFilterOptions.clone()));
    }

    private _setupHotkeys(): void {
        this._hotkeysService.addShortcut({
            keys: this.submitFormHotkey,
            ...this.defaultHotkeyOptions
        }).subscribe(() => {

            this.saveFilterOptions();
        });
    }

    private _removeHotkeys(): void {
        this._hotkeysService.removeShortcuts([this.submitFormHotkey]);
    }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
// Configure the agent for the production agent
const options = {

    init: {
        distributed_tracing: { enabled: true },
        privacy: { cookies_enabled: true },
        ajax: { deny_list: ["bam.nr-data.net"] }
    },
    loader_config: {
        accountID: "446647",
        trustKey: "446647",
        agentID: "772317287",
        licenseKey: "2aaefc2e11",
        applicationID: "772317287"
    },
    info: {
        beacon: "bam.nr-data.net",
        errorBeacon: "bam.nr-data.net",
        licenseKey: "2aaefc2e11",
        applicationID: "772317287",
        sa: 1
    }
};

new BrowserAgent(options);

import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild
} from "@angular/core";
import {
    HttpClient, HttpHeaders
} from "@angular/common/http";
import {
    MatPaginator
} from "@angular/material/paginator";

import {
    ApiKeyService,
    NotificationService,
    IAction
} from "@shopliftr/common-ng";

import { ApiKey } from "@shopliftr/common-js/admin";
import {
    Team, User
} from "@shopliftr/common-js/shared";

import { ApiKeyDataSource } from "../model/api-key-data-source";
import { ApiKeySearchOptions } from "../model/api-key-search-options";

@Component({
    selector: "api-key-list",
    templateUrl: "./api-key-list.component.html",
    styleUrls: ["./api-key-list.component.scss"]
})
export class ApiKeyListComponent implements OnInit, OnChanges {

    @Input() currentUser: User;

    @Input() searchOptions: ApiKeySearchOptions;

    @Output() selectApiKey: EventEmitter<ApiKey> = new EventEmitter<ApiKey>(false);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @ViewChild("table", { static: true }) table;

    actions: Array<IAction>;

    apiKeys: Array<ApiKey>;

    next: string;

    dataSource: ApiKeyDataSource;

    forceRefresh: boolean;

    displayedColumns: Array<string>;


    get team(): Team {

        return this._team;
    }

    set team(team: Team) {

        this._team = team;
        if (!this.searchOptions) {
            this.searchOptions = {};
        }
        this.searchOptions.teamId = team ? team.id : undefined;
        this.resetFilters();
    }

    private _team: Team;

    constructor(
        private readonly _httpClient: HttpClient,
        private readonly _apiKeyService: ApiKeyService,
        private readonly _notificationService: NotificationService
    ) {}


    ngOnInit(): void {

        this.actions = [
            {
                id: "add",
                icon: "add",
                label: "Create New API Key"
            }
        ];

        this.searchOptions = this.searchOptions || {};
        this.displayedColumns = [
            "name",
            "plan",
            "key",
            "actions"
        ];

        this.forceRefresh = false;
    }


    ngOnChanges(changes: SimpleChanges): void {

        if (changes.currentUser) {

            if (this.currentUser) {
                this.team = Team.deserialize({

                    id: this.currentUser.teamId,
                    name: this.currentUser.teamName
                });
            }
            else {
                this.team = undefined;
            }
        }
    }


    actionTriggered(actionId: string): void {

        if (actionId === "add") {
            this.newApiKey();
        }
    }


    newApiKey(): void {

        const apiKey = new ApiKey();
        apiKey.teamId = this.searchOptions.teamId;
        apiKey.enabled = true;
        this.selectApiKey.emit(apiKey);
    }


    /**
     * Forces deselection of all products when the page changes
     *
     * @param {PageEvent} event Details of the new page
     * @memberof ProductListComponent
     */
    onPageChange(): void {

    }


    resetFilters(): void {

        this.searchOptions.includeDisabled = false;
        this.searchOptions.page = 0;

        this._setDataSource(this.searchOptions);
    }


    setSelectApiKey(apiKey: ApiKey): void {

        this.selectApiKey.emit(apiKey);
    }


    /**
     * When the user selects a team, propagate it to this control and refresh the data source
     *
     * @param team Selected team
     */
    teamUpdated(team: Team): void {

        this.searchOptions.teamId = team ? team.id : undefined;
        this.resetFilters();
    }


    /**
     * Function for testing whether or not a particular API key is still valid. Runs a low-overhead call to the
     * MGD API with the chosen key as the auth token and reports the success
     *
     * @param key {ApiKey} The target API key
     * @param event {MouseEvent} The click event associated with this button. Need to prevent the row from triggering
     *      when the icon is clicked
     */
    testApiKey(key: ApiKey, event?: MouseEvent): void {

        if (event) {
            event.stopImmediatePropagation();
        }

        const url = "https://api.mygrocerydeals.com/v2/categories/major";
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const headers = new HttpHeaders({ "x-api-key": key.key });
        const options = {
            headers: headers
        };

        this._httpClient.request("GET", url, options).subscribe({
            next: () => {

                this._notificationService.displaySuccessMessage("Success: API key is active");
            },
            error: () => {

                this._notificationService.displayErrorMessage("The selected key is no longer valid");
            }
        });
    }


    /**
     * Public wrapper for setDataSource, called when the filters change
     *
     * @param searchOptions The filter values
     */
    updateFilters(searchOptions: ApiKeySearchOptions): void {

        this._setDataSource(searchOptions);
    }


    /**
     * Sets the data table's source based on the given options
     *
     * @param searchOptions Associated search filters, if applicable
     */
    private _setDataSource(searchOptions: ApiKeySearchOptions): void {

        if (searchOptions) {
            this.dataSource = new ApiKeyDataSource(this.paginator, searchOptions, this._apiKeyService);
        }
    }
}

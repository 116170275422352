import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
    ActionButtonGroupModule, MultiSelectorModule
} from "@shopliftr/common-ng";

import { AdminMaterialModule } from "../shared/admin-material-module/admin-material.module";
import { SharedModule } from "../shared/shared.module";

import { KeywordComponent } from "./keyword.component";

import { KeywordFormComponent } from "./keyword-form/keyword-form.component";
import { KeywordListComponent } from "./keyword-list/keyword-list.component";


@NgModule({
    declarations: [
        KeywordComponent,
        KeywordListComponent,
        KeywordFormComponent
    ],
    imports: [
        ActionButtonGroupModule,
        AdminMaterialModule,
        CommonModule,
        MultiSelectorModule,
        SharedModule
    ],
    exports: [
        KeywordComponent
    ],
    providers: []
})
export class KeywordModule {}

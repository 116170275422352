<div class="mgd-layout-page-content">
    <action-button-group [actions]="actions" (onAction)="actionTriggered($event)"></action-button-group>

    <section class="mgd-list-filter">
        <mat-form-field class="team-field">
            <mat-label>Select a Team</mat-label>
            <team-selector [(ngModel)]="team"></team-selector>
        </mat-form-field>
        <mat-slide-toggle class="disabled-toggle" [(ngModel)]="searchOptions.includeDisabled" (ngModelChange)="updateFilters(searchOptions)"
                labelPosition="before">
            Show Disabled Keys:
        </mat-slide-toggle>
    </section>
    <section class="mgd-list-with-filter">
        <mat-table #table [dataSource]="dataSource" class="api-key-list">
            <ng-container cdkColumnDef="name">
                <mat-header-cell *cdkHeaderCellDef>
                    Name
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" [ngClass]="{ grayed: !row.enabled }">
                    {{ row.name }}
                </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="plan">
                <mat-header-cell *cdkHeaderCellDef>
                    Usage Plan
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" [ngClass]="{ grayed: !row.enabled }">
                    {{ row.usagePlan.name }}
                </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="key">
                <mat-header-cell *cdkHeaderCellDef>
                    Key
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" [ngClass]="{ grayed: !row.enabled }">
                    <span>{{ row.key }}</span>
                </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="actions">
                <mat-header-cell *cdkHeaderCellDef>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" [ngClass]="{ grayed: !row.enabled }">
                    <button type="button" mat-icon-button (click)="testApiKey(row, $event)"
                            matTooltip="Test this key" matTooltipPosition="above">
                        <mat-icon>
                            launch
                        </mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <!-- ----- -->

            <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: displayedColumns;" class="clickable" (click)="setSelectApiKey(row)">
            </mat-row>
        </mat-table>

        <mat-paginator #paginator
                [pageSize]="20"
                [pageSizeOptions]="[20, 50, 100]">
        </mat-paginator>
    </section>
</div>

import { Component } from "@angular/core";

@Component({
    selector: "tools",
    templateUrl: "./tools.component.html",
    styleUrls: ["./tools.component.scss"]
})
export class ToolsComponent {

    constructor() {}
}

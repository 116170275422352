import {
    Component, OnInit
} from "@angular/core";
import { ApiKey } from "@shopliftr/common-js/admin";
import { User } from "@shopliftr/common-js/shared";
import { AuthenticationService } from "@shopliftr/common-ng";

@Component({
    selector: "api-key",
    templateUrl: "./api-key.component.html",
    styleUrls: ["./api-key.component.scss"]
})
export class ApiKeyComponent implements OnInit {

    apiKey: ApiKey;

    currentUser: User;

    constructor(
        private readonly _authService: AuthenticationService
    ) {}


    ngOnInit(): void {

        this.currentUser = this._authService.authenticatedUser.getValue();
    }


    clearApiKey(): void {

        this.apiKey = null;
    }


    setApiKey(apiKey: ApiKey): void {

        this.apiKey = apiKey;
    }
}

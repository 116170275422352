/**
 * Shorthand for various promotion periods
 */
export enum DealSearchFiltersPromotionPeriod {
    Custom = "CUSTOM",
    LastMonth = "LAST_MONTH",
    LastSixMonths = "LAST_SIX_MONTHS",
    LastTwoMonths = "LAST_TWO_MONTHS",
    LastTwoWeeks = "LAST_TWO_WEEKS",
    LastTwoYears = "LAST_TWO_YEARS",
    LastWeek = "LAST_WEEK",
    LastYear = "LAST_YEAR"
}

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
    Injectable, Inject
} from "@angular/core";
import {
    HttpClient, HttpResponse
} from "@angular/common/http";
import {
    HttpService, ExceptionHandlerService
} from "@shopliftr/common-ng";
import { Results } from "@shopliftr/common-js/shared";
import { ModelUtil } from "@shopliftr/common-js/data";
import { Keyword } from "@shopliftr/common-js/admin";


@Injectable({ providedIn: "root" })
export class KeywordService extends HttpService {

    private readonly _keywordUrl: string;

    constructor(
        httpClient: HttpClient,
        exceptionHandlerService: ExceptionHandlerService,
        @Inject("AppConfig") private readonly _appConfig: any
    ) {

        super(httpClient, exceptionHandlerService);
        this._keywordUrl = `${this._appConfig.apiUrl}/keyword`;
    }


    getKeywords(query?: string, from?: number, size?: number): Observable<Results<Keyword>> {

        const body: any = {};

        if (query) {
            body.query = query;
        }
        if (from) {
            body.from = from;
        }
        if (size) {
            body.size = size;
        }
        const url = `${this._keywordUrl}/search`;
        return this.post(url, body).pipe(
            map((response: HttpResponse<any>): Results<Keyword> => {

                const results: Results<Keyword> = Results.deserialize(response.body, ModelUtil.CamelCase, Keyword);
                return results;
            })
        );
    }


    deleteKeyword(keyword: Keyword): Observable<boolean> {

        const url = `${this._keywordUrl}/${keyword.id}`;
        return this.delete(url, { esVersion: keyword.esVersion }).pipe(
            map((): boolean => {

                return true;
            })
        );
    }


    createKeyword(keyword: Keyword): Observable<Keyword> {

        const body = keyword.serialize();
        return this.post(this._keywordUrl, body).pipe(
            map((): Keyword => {

                return Keyword.deserialize(body);
            })
        );
    }


    updateKeyword(keyword: Keyword): Observable<Keyword> {

        const url = `${this._keywordUrl}/${keyword.id}`;

        return this.put(url, keyword.serialize()).pipe(
            map((response: HttpResponse<any>): Keyword => {

                return Keyword.deserialize(response.body);
            })
        );
    }
}

import {
    Component, ElementRef, Input, OnInit, OnChanges, ViewChild
} from "@angular/core";

import {
    Chart, ChartConfiguration, registerables
} from "chart.js";

import {
    TaskCounts, TeamProgress
} from "@shopliftr/common-js/admin";


@Component({
    selector: "task-chart",
    templateUrl: "./task-chart-view.component.html",
    styleUrls: ["./task-chart-view.component.scss"]
})
export class TaskChartViewComponent implements OnInit, OnChanges {

    @Input() taskCounts: TaskCounts;

    @Input() teamProgress: TeamProgress;

    @ViewChild("canvas", { static: true }) canvas: ElementRef<HTMLCanvasElement>;

    chartRef: Chart<"pie", Array<number>, string>;

    chartData: Array<number>;

    chartLabels = [
        "Completed",
        "In Progress",
        "Assigned Not Started",
        "Unassigned"
    ];

    options = {
        legend: {
            display: true
        },
        responsive: true
    };


    constructor() {}


    ngOnInit(): void {

        this.updateCount();
    }


    ngOnChanges(): void {

        this.updateCount();
    }


    // http://www.chartjs.org/docs/latest/
    refreshChart(): void {

        const context = this.canvas.nativeElement.getContext("2d");

        if (this.taskCounts.total && this.canvas) {

            if (!this.chartRef) {
                Chart.register(...registerables);
                const config: ChartConfiguration<"pie", Array<number>, string> = {
                    type: "pie",
                    data: {
                        labels: this.chartLabels,
                        datasets: [{
                            label: "Tasks",
                            data: this.chartData,
                            backgroundColor: [
                                "#264589",
                                "#72b1e5",
                                "#3872bb",
                                "#e4f1fa"
                            ],
                            borderWidth: 0
                        }]
                    },
                    options: {
                        animation: {
                            animateRotate: true,
                            animateScale: true
                        },
                        cutout: 0,
                        maintainAspectRatio: false
                    }
                };
                this.chartRef = new Chart(context, config);
            }
            else {
                this.chartRef.data.datasets[0].data = this.chartData;

                this.chartRef.update();
            }
        }
        else {
            this.chartRef = undefined;
        }
    }


    updateCount(): void {

        if (this.taskCounts) {
            this.chartData = [
                this.taskCounts.complete,
                this.taskCounts.progress,
                this.taskCounts.assignedNotStarted,
                this.taskCounts.unassigned
            ];

            this.refreshChart();
        }
    }
}

import {
    AfterViewInit,
    Component,
    ErrorHandler,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    MatPaginator
} from "@angular/material/paginator";

import {
    DateTimeDisplayFormat, User, Team
} from "@shopliftr/common-js/shared";
import {
    CommonErrorHandler,
    IAction, NotificationService, UserService
} from "@shopliftr/common-ng";

import { UserBulkConfirmationComponent } from "../dialogs/user-bulk-confirmation/user-bulk-confirmation.component";

import { UserDataSource } from "../model/user-data-source";
import { UserSearchOptions } from "../model/user-search-options";


@Component({
    selector: "user-list",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.scss"]
})
export class UserListComponent implements AfterViewInit, OnChanges, OnInit {

    @Input() currentUser: User;

    @Input() searchOptions: UserSearchOptions;

    @Input() selectedTeam: Team;

    @Output() selectedTeamUpdated = new EventEmitter<Team>();

    @Output() setEditableUser = new EventEmitter<{ user?: User; selectedTeam?: Team }>();

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild("table", { static: true }) table;

    selectionSet: Array<boolean>;

    selectedUsers: Array<User>;

    actions: Array<IAction>;

    bulkActions = new Array<string>();

    dataSource: UserDataSource;

    debounceTimer: number;

    forceRefresh: boolean;

    navigate = true;

    displayedColumns: Array<string>;

    filterableRoles = Array<string>();

    canEdit: boolean;

    updating: boolean;

    realms: Array<string> = [];

    private _team: Team;

    get team(): Team {

        return this._team;
    }

    set team(team: Team) {

        this._team = team;

        if (!this.searchOptions) {
            this.searchOptions = {};
        }
        this.searchOptions.teamId = team ? team.id : undefined;
        this.debounceDataSource(this.searchOptions);
        this.selectedTeamUpdated.emit(team);
    }


    /**
     * Gets the correct display format for dates
     *
     * @memberof UserListComponent
     */
    get dateDisplayFormat(): string {

        return DateTimeDisplayFormat.getFormat();
    }


    constructor(
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler,
        private readonly _userService: UserService,
        private readonly _notificationService: NotificationService,
        public dialog: MatDialog
    ) {}


    ngAfterViewInit(): void {

        this._setDataSource(this.searchOptions);
    }


    ngOnChanges(changes: SimpleChanges): void {

        if (changes.currentUser) {

            if (this.currentUser) {
                const realms = [];
                if (this.currentUser.hasPermission("team-deal-entry-realm-create")) {
                    realms.push("deal-entry");
                }
                if (this.currentUser.hasPermission("team-partner-realm-create")) {
                    realms.push("partner");
                }
                this.realms = realms;
            }
            else {
                this.realms = [];
            }
        }

        if (changes.selectedTeam) {
            this.team = this.selectedTeam;
        }
        if (changes.searchOptions) {
            const searchOptions: UserSearchOptions = changes.searchOptions.currentValue;
            this.debounceDataSource(searchOptions);
        }

    }


    ngOnInit(): void {

        this.searchOptions = this.searchOptions || {};
        this.selectionSet = new Array<boolean>();

        if (this.currentUser.hasPermission("user-update") || this.currentUser.hasPermission("user-delete")) {
            this.canEdit = true;
        }
        else {
            this.canEdit = false;
        }

        if (this.currentUser.hasPermission("user-create")) {
            this.actions = [
                {
                    id: "create-user",
                    icon: "add",
                    label: "Create User"
                }
            ];
        }

        if (this.currentUser.hasPermission("user-delete")) {
            this.bulkActions = ["deactivate"];
        }

        this.displayedColumns = [
            "checkbox",
            "avatar",
            "name",
            "email",
            "history",
            "roles"
        ];

        this.resetFilters();

        this.forceRefresh = false;
    }


    actionTriggered(actionId: string): void {

        if (actionId === "create-user") {

            this.createUser();
        }
    }


    /**
     * Forces deselection of all products when the page changes
     *
     * @param {PageEvent} event Details of the new page
     * @memberof ProductListComponent
     */
    onPageChange(): void {

        this.toggleAllItems(false, this.table);
    }


    /**
     * Will emit a new user object to notify the user component of a new user in need to be created.
     * This user will inherit the team relevant in the current confirmButtonText
     *
     * ie: If you are viewing users for team X, then newly created user will be for team X.
     *
     * This value can be changed on the user-details component, but will be prefilled based on the
     * current viewed team.
     *
     * This will have the effect of hiding the user list and displaying the user details component
     * to be able to edit (fill required information) for this user.
     *
     * @memberOf UserListComponent
     */
    createUser(): void {

        const user = new User();
        user.setDefaults();

        if (this.searchOptions.teamId) {
            user.teamId = this.searchOptions.teamId;
            user.teamAccess = [this.searchOptions.teamId];
        }

        this.setEditableUser.emit({
            user: user,
            selectedTeam: this.selectedTeam
        });
    }


    deactivateConfirmation(user?: User): void {

        let usersToUpdate: Array<User>;

        if (user) {
            usersToUpdate = [user];
        }
        else if (this.selectedUsers && this.selectedUsers.length > 0) {
            usersToUpdate = this.selectedUsers;
        }

        if (usersToUpdate && usersToUpdate.length) {
            const dialogRef = this.dialog.open(UserBulkConfirmationComponent, {
                data: {
                    usersToUpdate: usersToUpdate
                },
                width: "728px"
            });
            dialogRef.componentInstance.afterUpdate.subscribe(() => {

                dialogRef.close();
                this._refreshDataSource();
            });
            dialogRef.componentInstance.onUpdate.subscribe((updating: boolean) => {

                this.updating = updating;
            });
        }
        else {
            this._notificationService.displayErrorMessage("No user selected");
        }
    }


    /**
     * Debounce wrapper for updating the search filters
     *
     * @param searchOptions New filters for the data source
     */
    debounceDataSource(searchOptions: UserSearchOptions): void {

        clearTimeout(this.debounceTimer);

        // Must be done separately to prevent the view from failing on initialisation with no data source present
        if (!this.dataSource) {
            this._setDataSource(searchOptions);
        }
        else {
            this.debounceTimer = setTimeout(() => {

                this._setDataSource(searchOptions);
            }, 300) as unknown as number;
        }
    }


    /**
     * Will emit the provided user as the user to edit.
     *
     * This will have the effect of removing the user list component and displaying the user details
     * component with the provided user"s details filled and ready to be edited.
     *
     * @param {User} user
     *
     * @memberOf UserListComponent
     */
    editUser(user: User): void {

        if (this.navigate) {
            if (this.currentUser.hasPermission("user-update") ||
                (this.currentUser.hasPermission("user-this-update") && this.currentUser.id === user.id)) {

                this.setEditableUser.emit({
                    user: user,
                    selectedTeam: this.selectedTeam
                });
            }
        }
        else {
            this.navigate = true;
        }
    }


    preventNavigation(): void {

        this.navigate = false;
    }


    resetFilters(): void {

        this.searchOptions = { teamId: this.searchOptions.teamId };
        this.debounceDataSource(this.searchOptions);
    }


    /**
     * Builds a set of roles from those available within the current userset
     */
    setFilterableRoles(): void {

        this.filterableRoles = new Array<string>();

        for (const user of this.dataSource.subject.value) {
            for (const role of user.roles) {
                if (!this.filterableRoles.includes(role)) {
                    this.filterableRoles.push(role);
                }
            }
        }
    }


    /**
     * Event handler for the master toggle
     *
     * @param {boolean} state The new value of the checkbox
     * @param {*} table The containing table
     * @memberof UserListComponent
     */
    toggleAllItems(state: boolean, table: any): void {

        const setBuilder = new Array<User>();

        this.selectionSet["master"] = state;

        for (const user of table._data) {
            this.selectionSet[user.id] = state;

            if (state) {
                setBuilder.push(user as User);
            }
        }

        this.selectedUsers = setBuilder;
        this._refreshDataSource();
    }


    /**
     * Even handler for the row toggles
     *
     * @param {boolean} state The new value of the checkbox
     * @memberof ProductListComponent
     */
    toggleSingleItem(state: boolean, user: User): void {

        this.selectionSet[user.id] = state;

        if (!state) {
            this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
        }
        else {
            this.selectedUsers.push(user);
        }

        this.selectionSet["master"] = this.selectedUsers.length === this.dataSource.total;
    }


    triggerBulkAction(bulkAction: any): void {

        if (bulkAction === "deactivate" && this.selectedUsers.length > 0) {
            this.deactivateConfirmation();
        }
    }


    /**
     * Re-assigns the data source to itthis to trick the data table into thinking it has changed and trigger a redraw
     *
     * @private
     * @memberof ProductListComponent
     */
    private _refreshDataSource(): void {

        this.forceRefresh = true;
    }


    /**
     * Sets the data table"s source based on the given options
     *
     * @param searchOptions Associated search filters, if applicable
     */
    private _setDataSource(searchOptions: UserSearchOptions): void {

        if (searchOptions) {
            this.selectedUsers = new Array<User>();
            this.selectionSet = new Array<boolean>();

            this.dataSource = new UserDataSource(
                this.paginator, searchOptions, this._userService, this._notificationService, this._errorHandler
            );
        }
    }
}

<input #locationPickerInput name="location"
        [formControl]="locationControl"
        (blur)="onBlur()"
        (input)="search()"
        [matAutocomplete]="locationAutocomplete"
        [required]="required" />

<mat-autocomplete #locationAutocomplete="matAutocomplete">
    <mat-option *ngIf="!storedLocation.latLng" disabled>
        No matches found
    </mat-option>

    <mat-option *ngIf="storedLocation.latLng" (onSelectionChange)="setText(storedLocation.label, false)" [value]="storedLocation">
        <div class="prediction">
            Closest Match: <strong class="prediction">{{ storedLocation.label }}</strong>
        </div>
    </mat-option>
</mat-autocomplete>

import {
    Component, EventEmitter, Inject, Input, OnInit, Output
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import {
    ApiKey, ApiUsagePlan
} from "@shopliftr/common-js/admin";
import {
    Team, User
} from "@shopliftr/common-js/shared";
import {
    ApiKeyService, ExceptionHandlerService, NotificationService, UserService, TeamService
} from "@shopliftr/common-ng";

@Component({
    selector: "team-create",
    templateUrl: "./team-create.component.html",
    styleUrls: ["./team-create.component.scss"]
})
export class TeamCreateComponent implements OnInit {

    @Input() currentUser: User;

    @Output() onCreate = new EventEmitter<{ team: Team; createKey: boolean; apiKey: ApiKey }>(false);

    apiUsagePlans: Array<ApiUsagePlan>;

    createNewKey: boolean;

    realms: Array<string>;

    newApiKey: ApiKey;

    newTeam: Team;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private readonly _apiKeyService: ApiKeyService,
        private readonly _exceptionHandlerService: ExceptionHandlerService,
        private readonly _notificationService: NotificationService,
        private readonly _teamService: TeamService,
        private readonly _userService: UserService
    ) {

        this.currentUser = data.currentUser;
        this.realms = new Array<string>();
        this.newApiKey = new ApiKey();
        this.newTeam = new Team();
        this.newTeam.realm = new Array<string>();
    }


    ngOnInit(): void {

        const permissions = this.currentUser.permissions;

        for (const permission of permissions) {
            // Get realms current user is allowed to assign
            if (permission.includes("realm-create")) {
                this.realms.push(permission.replace("team-", "").replace("-realm-create", ""));
            }
        }

        if (this.realms.length === 1) {
            this.newTeam.realm = this.realms;
        }
    }


    /**
     * Creates a new team and, if desired, a new API key for that team before emitting that data back to the parent
     */
    createTeam(): void {

        if (!this.newTeam.name || !this.newTeam.realm || this.createNewKey && (!this.newApiKey.name || !this.newApiKey.usagePlan)) {
            return;
        }

        this._teamService.create(this.newTeam).subscribe((createdTeam: Team) => {

            this.newTeam = createdTeam;

            const response = {
                team: this.newTeam,
                createKey: this.createNewKey,
                apiKey: undefined
            };

            if (this.createNewKey) {
                this.newApiKey.teamId = this.newTeam.id;
                this.newApiKey.enabled = true;

                this._apiKeyService.createApiKey(this.newApiKey).subscribe((createdKey: ApiKey) => {

                    this.newApiKey = createdKey;
                    response.apiKey = this.newApiKey;

                    this._notificationService.displaySuccessMessage("New API Key Created");
                    this.onCreate.emit(response);
                },
                (error) => {

                    this._exceptionHandlerService.sendException(error);
                });
            }
            else {
                this.onCreate.emit(response);
            }
        }, (error: any) => {

            this._exceptionHandlerService.sendException(error);
        });
    }


    /**
     * Function to query the internal api to fetch the usage plans for selection.
     * This is done in it's own function since it is not always fetched, and therefore, we only
     * fetch the usage plans when required instead of always getting them and rarely use them.
     *
     * @memberOf TeamCreateComponent
     */
    getUsagePlans(): void {

        this._apiKeyService.getApiUsagePlans().subscribe((apiUsagePlans: Array<ApiUsagePlan>) => {

            this.apiUsagePlans = apiUsagePlans;
        });
    }


    /**
     * Will update the new API key name with the team name.  Most of the use cases are that these two
     * inputs will be the same.  If the key name is changed, then they will no longer sync.
     *
     * @param {string} newTeamName
     *
     * @memberOf UserDetailsComponent
     */
    updateKeyName(): void {

        this.newApiKey.name = this.newTeam.name;
    }
}

import {
    Pipe, PipeTransform
} from "@angular/core";


@Pipe({
    name: "filterDateDropdown"
})
export class FilterDateDropdownPipe implements PipeTransform {

    transform(items: Array<any>, type: string, date: number | Date, offset?: number): Array<any> {

        return items.filter((item) => {

            const resolvedItem: number = (item.value === undefined) ? item : item.value;

            if (date && resolvedItem !== undefined) {
                const resolvedDate: Date = (date instanceof Date) ? date : new Date(date);

                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                return (resolvedItem + (offset || 0)) === resolvedDate[`get${type || "Day"}`]();
            }
            else {
                return true;
            }
        });
    }
}

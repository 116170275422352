<header class="mgd-layout-page-title">
    <h2>Deal Search</h2>
</header>

<mgd-breadcrumbs [crumbs]="breadcrumbs" (onAction)="onCrumbSelected($event)"></mgd-breadcrumbs>

<div class="mgd-layout-page-content batch-product-management-component">
    <div [hidden]="selectedDeal">
        <div class="mgd-list-filter">
            <deal-search-filters-form
                    [filterOptions]="dealSearchFilterOptions"
                    [searchHistoricalDealsDefault]="searchHistoricalDeals"
                    (onAction)="handleDealSearchFiltersFormAction($event)"
                >
            </deal-search-filters-form>
        </div>

        <section class="mgd-list-with-filter">
            <div *ngIf="deals">
                <action-button-group [actions]="dealSearchListActions" (onAction)="actionTriggered($event)"></action-button-group>

                <deal-search-list
                        [elements]="deals"
                        [displayColumns]="dealDisplayColumns"
                        [highlightIds]="dealHighlightIds"
                        [pageIndex]="dealPage"
                        [pageSize]="dealPageSize"
                        [pageSizeOptions]="dealPageSizeOptions"
                        [processing]="processing"
                        [total]="dealTotal"
                        (onAction)="handleDealSearchListAction($event)"
                    >
                </deal-search-list>
            </div>
        </section>
    </div>

    <div *ngIf="selectedDeal">
        <section class="mgd-layout-page-content-section">
            <deal-search-details-view [deal]="selectedDeal" (onAction)="handleDealSearchDetailsViewAction($event)"></deal-search-details-view>
        </section>
    </div>
</div>

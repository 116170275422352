export enum AdminRoutingId {

    RouterService = "ADMIN_ROUTER_SERVICE",
    Dashboard = "DASHBOARD",
    ApiKeys = "API_KEYS",
    DealSearch = "DEAL_SEARCH",
    DealSearchView = "DEAL_SEARCH_VIEW",
    FlyerVersion = "FLYER_VERSION",
    GettingStarted = "GETTING_STARTED",
    Keywords = "KEYWORDS",
    Product = "PRODUCT",
    Reporting = "REPORTING",
    Settings = "SETTINGS",
    Smart = "SMART",
    Tasks = "TASKS",
    Tools = "TOOLS",
    Users = "USERS",
    UserView = "USER_VIEW",
    PageNotFound = "PAGE_NOT_FOUND"
}

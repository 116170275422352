import {
    Observable,
    map
} from "rxjs";

import { Injectable } from "@angular/core";
import {
    Deal,
    DealSearchFilterOptions,
    GetResponse,
    ISearchSort,
    RequestUtil,
    SearchRequest,
    SearchResponse,
    SearchSortDirection
} from "@shopliftr/common-js/shared";

import {
    InternalApiRequestService,
    IInternalApiService
} from "@shopliftr/common-ng";

import { ModelAction } from "@shopliftr/common-js/data";


/**
 * Leverages the Deal endpoint to retrieve deals
 */
@Injectable({ providedIn: "root" })
export class DealService implements IInternalApiService<Deal> {

    private readonly _basePath: string;


    constructor(
        private readonly _internalApiRequestService: InternalApiRequestService
    ) {

        this._basePath = `deal`;
    }


    get(id: string): Observable<Deal> {

        const request = RequestUtil.createGetRequest<Deal>(id, Deal.modelName, ModelAction.GetRequest);

        return this._internalApiRequestService.get(request, this._basePath).pipe(map((response: GetResponse<Deal>) => {

            return response.payload;
        }));
    }


    getAll(filterOptions: DealSearchFilterOptions, sort?: Array<ISearchSort>): Observable<Array<Deal>> {

        const request = RequestUtil.createSearchRequest(
            ModelAction.SearchRequest, Deal.modelName, undefined, undefined, filterOptions, sort ?? [{
                field: "name",
                direction: SearchSortDirection.ascending
            }]
        );

        return this._internalApiRequestService.getAll<Deal, DealSearchFilterOptions>(request, this._basePath);
    }


    search(
        filterOptions: DealSearchFilterOptions,
        page?: number,
        pageSize?: number,
        sort?: Array<ISearchSort>
    ): Observable<SearchResponse<Deal>> {

        const request: SearchRequest<DealSearchFilterOptions> = RequestUtil.createSearchRequest(
            ModelAction.SearchRequest,
            Deal.modelName,
            (page !== undefined && pageSize !== undefined) ? page * pageSize : undefined,
            pageSize,
            filterOptions,
            sort
        );

        return this._internalApiRequestService.search<Deal, DealSearchFilterOptions>(request, this._basePath);
    }
}

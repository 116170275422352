import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { merge } from "rxjs";

import { DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";

import {
    Results, User
} from "@shopliftr/common-js/shared";
import {
    CommonErrorHandler, NotificationService, UserService
} from "@shopliftr/common-ng";

import { UserSearchOptions } from "./user-search-options";
import {
    ErrorHandler, Inject
} from "@angular/core";


export class UserDataSource extends DataSource<any> {

    total: number;

    subject: BehaviorSubject<Array<User>>;

    constructor(
        private readonly _paginator: MatPaginator,
        private readonly _searchOptions: UserSearchOptions,
        private readonly _userService: UserService,
        private readonly _notificationService: NotificationService,
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler
    ) {

        super();
        this.total = 0;
    }


    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Array<User>> {

        this.subject = new BehaviorSubject<Array<User>>([]);

        if (!this.subject.closed) {
            this._getData();
        }

        return merge(this.subject);
    }


    disconnect(): void {

        this.subject.complete();
    }


    private _getData(): void {

        // Not paged
        this._searchOptions.page = 0;

        if (!this._searchOptions.teamId) {
            this.total = 0;
            this.subject.next(new Array<User>());
            return;
        }

        this._userService.getUsersForTeam(this._searchOptions.teamId).pipe(
            map((results: Results<User>) => {

                const filteredResults = new Array<User>();
                let valid: boolean;

                for (const result of results.results) {
                    valid = true;

                    // Active filter
                    if (!this._searchOptions.seeInactive) {
                        valid = result.active;
                    }

                    // Name filter
                    if (valid && this._searchOptions.query) {
                        valid = false;

                        const querySegments = this._searchOptions.query.toLocaleLowerCase().split(" ");

                        for (const segment of querySegments) {
                            if (result.name.toLocaleLowerCase().includes(segment)) {
                                valid = true;
                                break;
                            }
                        }
                    }

                    // Role filter
                    if (valid && this._searchOptions.role && this._searchOptions.role !== "all") {
                        valid = false;

                        for (const role of result.roles) {
                            if (role === this._searchOptions.role) {
                                valid = true;
                                break;
                            }
                        }
                    }


                    if (valid) {
                        filteredResults.push(result);
                    }
                }

                this.total = filteredResults.length;
                this.subject.next(filteredResults);
            })
        )
            .subscribe({
                error: (error) => {
                    const message = this._errorHandler.handleServiceError(error).message;
                    this._notificationService.displayErrorMessage(`An exception occured getting the users: ${message}`);
                }
            });
    }
}

import {
    Pipe, PipeTransform
} from "@angular/core";
import { Injectable } from "@angular/core";

@Pipe({
    name: "SecondsToTimePipe"
})

@Injectable()
export class SecondsToTimePipe implements PipeTransform {

    transform(value: any): any {

        const minutes = Math.floor(value / 60);
        const seconds = Math.floor(value - minutes * 60);
        let fommattedDate;

        if (minutes > 0) {
            fommattedDate = `${minutes}m ${seconds}s`;
        }
        else {
            fommattedDate = `${seconds}s`;
        }
        return fommattedDate;
    }
}

<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Payload Generator
            </mat-panel-title>
            <mat-panel-description>
                Generate a payload for Pages application
            </mat-panel-description>
        </mat-expansion-panel-header>

        <payload-generator></payload-generator>
    </mat-expansion-panel>
</mat-accordion>

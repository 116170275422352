<h1 id="shopliftradministrativeportal">Shopliftr Administrative Portal</h1>
<p><a href="https://admin2.mygrocerydeals.com">admin2.mygrocerydeals.com</a> (version 3.0.0)</p>
<h2 id="introduction">Introduction</h2>
<p>Shoplifter's admin portal is your one-stop shop for data entry and application management for the Shopliftr and MyGroceryDeals systems, intended to streamline your workflow and maximise throughput while reducing repetitive strain. This document is intended to outline the features that you'll need to navigate deal entry and get familiar with the great feautre offerings we've built to help you be your most productive self.</p>
<p>If you'd like to submit feedback, ideas for improvement, or bug reports, see the Submitting Feedback section below.</p>
<h2 id="tableofcontents">Table of Contents</h2>
<ul>
<li><a href="#shopliftr-administrative-portal">Shopliftr Administrative Portal</a><ul>
<li><a href="#introduction">Introduction</a></li>
<li><a href="#table-of-contents">Table of Contents</a></li>
<li><a href="#overview">Overview</a><ul>
<li><a href="#viewing-your-tasks">Viewing Your Tasks</a><ul>
<li><a href="#a-flyer-name">A) Flyer Name</a></li>
<li><a href="#b-task-filters">B) Task Filters</a></li>
<li><a href="#c-timeframe-selector">C) Timeframe Selector</a></li>
<li><a href="#d-task-actions">D) Task Actions</a></li></ul></li>
<li><a href="#flyer-confirmation">Flyer Confirmation</a><ul>
<li><a href="#a-flyer-url">A) Flyer URL</a></li>
<li><a href="#b-notes">B) Notes</a></li>
<li><a href="#c-edit-flyer">C) Edit Flyer</a></li>
<li><a href="#d-page-count">D) Page Count</a></li>
<li><a href="#e-confirm-flyer-details">E) Confirm Flyer Details</a></li></ul></li>
<li><a href="#deal-entry">Deal Entry</a><ul>
<li><a href="#product-search">Product Search</a><ul>
<li><a href="#a-search-field">A) Search Field</a></li>
<li><a href="#b-product-row">B) Product Row</a></li>
<li><a href="#c-hotkeys">C) Hotkeys</a></li></ul></li>
<li><a href="#deal-entry-form">Deal Details</a><ul>
<li><a href="#a-sale-type">A) Sale Type</a></li>
<li><a href="#b-live-preview">B) Live Preview</a></li>
<li><a href="#c-remove-product">C) Remove Product</a></li>
<li><a href="#d-confirm-deal">D) Confirm Deal</a></li></ul></li>
<li><a href="#deal-entry-list">Deal Review</a><ul>
<li><a href="#a-deal-entry">A) Deal Entry</a></li>
<li><a href="#b-bulk-actions">B) Bulk Actions</a></li>
<li><a href="#c-edit-deal">C) Edit Deal</a></li>
<li><a href="#d-remove-deal">D) Remove Deal</a></li>
<li><a href="#e-submit-to-qa">E) Submit to QA</a></li></ul></li></ul></li></ul></li>
<li><a href="#possible-task-states">Possible Task States</a></li>
<li><a href="#typical-data-entry-process">Typical Data Entry Process</a></li>
<li><a href="#submitting-feedback">Submitting Feedback</a></li></ul></li>
</ul>
<h2 id="overview">Overview</h2>
<p>This section will go over the main sections of the application and hopefully enable easy navigation and onboarding onto the application.  </p>
<pre><code>Note: Different users, due to different permission levels, will see things singly differently, therefore, you might not see exactly the same content on the screens displayed below.
</code></pre>
<h3 id="viewingyourtasks">Viewing Your Tasks</h3>
<pre><code>Note: Tasks here refers to the work required for flyer entry
</code></pre>
<p>Assigned tasks can be viewed from the task list, accessed via the left-hand navigation bar.</p>
<p><img src="./assets/docs/images/01_task-list.png" alt="Task List" title="Task List" /></p>
<h4 id="aflyername">A) Flyer Name</h4>
<p>Each task is associated with a parent flyer, which is shown in the list. The glyph next to the task name shows the priority of that task (A being high priority, and D being lower priority). If a task is starred, then additional work will be required to update the child stores which can be viewed by clicking on the row. If child view is not enabled then only the children that require updates will be visible.</p>
<pre><code>Note: The type field indicates whether this task is for a parent or a child flyer.
</code></pre>
<h4 id="btaskfilters">B) Task Filters</h4>
<p>You can filter the available tasks using the pre-determined filters provided. To select a filter, simply click on the associated tab. The active filter is noted by an underline.</p>
<pre><code>Note: The available filters depend on your role. The provided image shows the filters available to an agent, so team leaders and supervisor will see additional options.
</code></pre>
<h4 id="ctimeframeselector">C) Timeframe Selector</h4>
<p>The task list defaults its view to the current cycle of flyers, but if you'd like to view older flyers you can change the date range manually. To select a new date, click on the calendar icon to make the date picker visible, then simply navigate to and select the date for the selected boundary. Once you've set up your timeframe, click <strong>Search</strong> to view those tasks.</p>
<h4 id="dtaskactions">D) Task Actions</h4>
<p>The action buttons on the right-hand side of the task row allow you to interact with that task. The most important action -- usually beginning or continuing data entry -- is available directly on the row, but additional actions can be accessed by clicking the menu icon. Actions like enabling child view (even for tasks with no active dependents), re-assigning the task, and changing the status can be found here.</p>
<pre><code>Note: The available actions depend on both your role and the status of the current task.
</code></pre>
<h3 id="flyerconfirmation">Flyer Confirmation</h3>
<p>Before beginning work on a task you must confirm the details of the associated flyer. This ensures that the system is working with accurate information that will propagate correctly.</p>
<p><img src="./assets/docs/images/02_flyer-details.png" alt="Flyer Details" title="Flyer Details" /></p>
<h4 id="aflyerurl">A) Flyer URL</h4>
<p>The deals for a particular flyer come from the online offering, and the URL for that offering should be added to the flyer itself. Once added, the link will be preserved and available when the next iteration of the flyer is generated.</p>
<h4 id="bnotes">B) Notes</h4>
<p>Each flyer and chain can also have associated notes. Flyer notes exist only for this particular version of the flyer, while chain notes persist for any flyers released by the chain and will appear on future iterations.</p>
<h4 id="ceditflyer">C) Edit Flyer</h4>
<p>Clicking the eidt icon or pressing <code>ctrl + shift + e</code> puts the flyer into edit mode. Normally only the page count of a particular iteration can be changed, but in edit mode all of the relevant details can be updated as needed.</p>
<h4 id="dpagecount">D) Page Count</h4>
<p>You must enter the number of pages in this particular iteration of the flyer before you can proceed (required field).</p>
<h4 id="econfirmflyerdetails">E) Confirm Flyer Details</h4>
<p>Once you've confirmed that a flyer's details are correct, and have added the page count for this iteration, you can save them by clicking <strong>Confirm Flyer Details</strong>. Doing so will take you to the deal entry section.</p>
<h3 id="dealentry">Deal Entry</h3>
<p>Deal entry is broken into a few different parts, each of which will be explained in detail:</p>
<ol>
<li>Product Search</li>
<li>Deal Details</li>
<li>Deal Review</li>
</ol>
<h4 id="productsearch">Product Search</h4>
<p>When adding a new deal, the first step is to search for the associated product</p>
<p><img src="./assets/docs/images/03_product-search.png" alt="Product Search" title="Product Search" /></p>
<h5 id="asearchfield">A) Search Field</h5>
<p>To find the product you need for the current deal, simply enter details into the search field. You can search by product name, UPC, even description.</p>
<h5 id="bproductrow">B) Product Row</h5>
<p>The entire row of product details is clickable to select the product. When you're sure the product matches the one in the flyer, select it to proceed.</p>
<h5 id="chotkeys">C) Hotkeys</h5>
<p>Pressing the hotkey listed on a product row (e.g. <code>ctrl + 1</code>), you can select the product without having to click the row. With practice, using keyboard shortcuts can save you a lot of time when going through a flyer.</p>
<h4 id="dealdetails">Deal Details</h4>
<p>The deal details page is where you enter the information related to the deal in the flyer.</p>
<p><img src="./assets/docs/images/04_deal-entry-form.png" alt="Deal Details" title="Deal Details" /></p>
<h5 id="asaletype">A) Sale Type</h5>
<p>The sale type if the first field that should be updated, as it changes the available fields for the rest of the deal -- in particular price.</p>
<h5 id="blivepreview">B) Live Preview</h5>
<p>As you fill out the details, they will be reflected in real time in the right-hand panel. The preview reflects how the deal will look when displayed on MyGroceryDeals.com.</p>
<h5 id="cremoveproduct">C) Remove Product</h5>
<p>If the selected product needs to change, click the X to remove the product and return to the search.</p>
<h5 id="dconfirmdeal">D) Confirm Deal</h5>
<p>Once you've entered the details and ensured that they are correct, click <strong>Create/Update Deal</strong> to complete the form and return to the deal review. You will be shown another preview and asked to confirm the details before finishing the process.</p>
<pre><code>Note: All of the fields on the deal details form are accessible with keyboard shortcuts.
</code></pre>
<h4 id="dealreview">Deal Review</h4>
<p>The deal review page allows you to see all of the deals that have been added to the flyer so that you can confirm their correctness and, if needed, go back and make changes.</p>
<p><img src="./assets/docs/images/05_deal-entry-list.png" alt="Deal Review" title="Deal Review" /></p>
<h5 id="adealentry">A) Deal Entry</h5>
<p>If you need to add more deals to the flyer, you can either select the deal entry tab at the top left of the screen, or select <strong>Add more deals</strong> in the action group on the bottom right.</p>
<h5 id="bbulkactions">B) Bulk Actions</h5>
<p>Deals can be updated or deleted in bulk. After you've selected at least one deal, the bulk action menu becomes enabled. Selecting an action will open a dialog to confirm the details.</p>
<h5 id="ceditdeal">C) Edit Deal</h5>
<p>If you need to update a specific deal, you can click on that deal's row in the table to return to the deal details for that item.</p>
<h5 id="dremovedeal">D) Remove Deal</h5>
<p>If you need to remove a deal from the flyer, simply click the delete icon on the right side of that deal's row.</p>
<h5 id="esubmittoqa">E) Submit to QA</h5>
<p>Once you have confirmed that the deals in the flyer are correct, you can submit it to QA for review from the action group at the bottom right. If the status of the flyer is different (for example if you are a team lead reviewing a submitted flyer), then the actions will change accordingly.</p>
<h2 id="possibletaskstates">Possible Task States</h2>
<p><img src="./assets/docs/images/06_adm2-task-states.png" alt="Task States" title="Task States" /></p>
<h2 id="typicaldataentryprocess">Typical Data Entry Process</h2>
<p>This next section describes a typical data entry process.  This will walk you through the basic steps required to complete the standard use-case..</p>
<ol>
<li>Login (username and password will be provided to you)<ul>
<li>Upon successful login, you will be automatically directed to the dashboard</li></ul></li>
<li>Click on the <code>Unassigned</code> tab</li>
<li>Chose a task and assign it to yourself<ul>
<li>If you are a supervisor or team leader:<ul>
<li>Click the <code>Assign</code> button</li>
<li>On the popup, click the <code>Assign to me</code> button</li>
<li>Click <code>Assign</code></li></ul></li>
<li>If you are an agent:<ul>
<li>Click the <code>Take</code> button</li></ul></li></ul></li>
<li>Go to the <code>Available</code> tab</li>
<li>Find the task you have just assigned to yourself and click the <code>Begin</code> button</li>
<li>Confirm the flyer details<ul>
<li>You will have to enter the number of pages before being able to proceed</li></ul></li>
<li>Search for a product</li>
<li>Select from search results</li>
<li>Enter price</li>
<li>Click <code>Add Deal</code></li>
<li>Confirm new deal</li>
<li>Repeat steps 7-11 as many times as desired</li>
<li>Click <code>Deal Review</code> tab on upper right</li>
<li>Have a look at entered deals and when satisfied click on <code>Submit Flyer (to QA)</code>
Steps 15 and beyond assume you have QA privilege</li>
<li>From the <code>Task List</code> click the <code>Pending Approval</code> tab</li>
<li>Previously submitted flyer (from step 14) will be there</li>
<li>Click <code>Review</code></li>
<li>Confirm flyer details</li>
<li>Review deals</li>
<li>Click <code>Publish</code></li>
<li>From the task list, said task will now be under the <code>Approved</code> tab</li>
</ol>
<h2 id="submittingfeedback">Submitting Feedback</h2>
<p>To submit feedback, simply click on <strong>Feedback</strong> on the left-hand navigation. A contact form will open (without affecting your view) and will allow you to enter the details of your claim. Once submitted, the information will be sent to the relevant parties and will be addressed as soon as possible.</p>
<p><img src="./assets/docs/images/07_contact.png" alt="Contact Us Form" title="Contact Us Form" /></p>
<header class="mgd-layout-page-title">
    <h2>
        <span class="nav-title">Keyword List</span>

        <mat-icon *ngIf="keyword">
            chevron_right
        </mat-icon>

        <span *ngIf="keyword && !keyword.id">New Keyword</span>

        <span *ngIf="keyword && keyword.id">Edit Keyword</span>
    </h2>
</header>
<section>
    <div class="keyword-wrapper-component">
        <keyword-list *ngIf="!keyword" (selectKeyword)="setKeyword($event)"></keyword-list>
        <keyword-form *ngIf="keyword" [selectedKeyword]="keyword" (complete)="clearKeyword()"></keyword-form>
    </div>
</section>

import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { AuthenticationGuard } from "@shopliftr/common-ng";
import { AdminComponent } from "./admin.component";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { GettingStartedComponent } from "./getting-started/getting-started.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { UserComponent } from "../user/user.component";
import { KeywordComponent } from "../keyword/keyword.component";
import { ApiKeyComponent } from "../api-key/api-key.component";
import { ToolsComponent } from "../tools/tools.component";
import { DealSearchManagementComponent } from "../deal-search/deal-search-management/deal-search-management.component";
import { SmartRedirectGuard } from "./smart-redirect.guard";
import { AdminRoutingId } from "./enums/admin-routing-id";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: "admin",
                component: AdminComponent,
                children: [
                    {
                        path: "",
                        redirectTo: "dashboard",
                        pathMatch: "full"
                    },
                    {
                        path: "api-keys",
                        component: ApiKeyComponent,
                        data: {
                            breadcrumb: "API Keys",
                            routeId: AdminRoutingId.ApiKeys,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "dashboard",
                        component: DashboardComponent,
                        data: {
                            breadcrumb: "Dashboard",
                            routeId: AdminRoutingId.Dashboard,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "deal-search",
                        component: DealSearchManagementComponent,
                        data: {
                            breadcrumb: "Deal Search",
                            routeId: AdminRoutingId.DealSearch,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "deal-search/deal/:dealId",
                        component: DealSearchManagementComponent,
                        data: {
                            breadcrumb: "Deal",
                            routeId: AdminRoutingId.DealSearchView,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "flyer-version",
                        loadChildren: () => import("../flyer-version/flyer-version.module").then((m) => m.FlyerVersionModule),
                        data: {
                            breadcrumb: "Flyer Versions",
                            routeId: AdminRoutingId.FlyerVersion,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "getting-started",
                        component: GettingStartedComponent,
                        data: {
                            breadcrumb: "Getting Started",
                            routeId: AdminRoutingId.GettingStarted,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "keywords",
                        component: KeywordComponent,
                        data: {
                            breadcrumb: "Keywords",
                            routeId: AdminRoutingId.Keywords,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "product",
                        loadChildren: () => import("../product/product.module").then((m) => m.ProductModule),
                        data: {
                            breadcrumb: "Products",
                            routeId: AdminRoutingId.Product,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "reporting",
                        canActivate: [SmartRedirectGuard],
                        component: SmartRedirectGuard
                    },
                    {
                        path: "settings",
                        loadChildren: () => import("../settings/settings.module").then((m) => m.SettingsModule),
                        data: {
                            breadcrumb: "Settings",
                            routeId: AdminRoutingId.Settings,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "smart",
                        canActivate: [SmartRedirectGuard],
                        component: SmartRedirectGuard
                    },
                    {
                        path: "tasks",
                        loadChildren: () => import("../task/task.module").then((m) => m.TaskModule),
                        data: {
                            breadcrumb: "Tasks",
                            routeId: AdminRoutingId.Tasks,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "tools",
                        component: ToolsComponent,
                        data: {
                            breadcrumb: "Tools",
                            routeId: AdminRoutingId.Tools,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "users",
                        component: UserComponent,
                        data: {
                            breadcrumb: "Users",
                            routeId: AdminRoutingId.Users,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "users/:profileId",
                        component: UserComponent,
                        data: {
                            breadcrumb: "Users",
                            routeId: AdminRoutingId.UserView,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "page-not-found",
                        component: PageNotFoundComponent,
                        data: {
                            routeId: AdminRoutingId.PageNotFound,
                            routerServiceId: AdminRoutingId.RouterService
                        }
                    },
                    {
                        path: "**",
                        redirectTo: "/admin/page-not-found"
                    }
                ],
                canActivate: [AuthenticationGuard]
            }
        ])
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthenticationGuard
    ]
})
export class AdminRoutingModule {}

<div class="payload-generator">
    <mat-card appearance="outlined">
        <mat-card-header>
            <mat-card-title>
                Payload generator
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div data-layout="row">
                <div data-flex="100">
                    <label>Location</label>
                </div>
            </div>
            <div data-layout="row" data-layout-gap="1rem">
                <div data-flex="25">
                    <mat-form-field>
                        <mat-label>Zip</mat-label>
                        <input matInput placeholder="Zip" [(ngModel)]="zip" />
                    </mat-form-field>
                </div>
                <div data-flex="25">
                    <mat-form-field>
                        <mat-label>Lat</mat-label>
                        <input matInput placeholder="Lat" [(ngModel)]="latitude" />
                    </mat-form-field>
                </div>
                <div data-flex="25">
                    <mat-form-field>
                        <mat-label>Long</mat-label>
                        <input matInput placeholder="Long" [(ngModel)]="longitude" />
                    </mat-form-field>
                </div>
                <div data-flex="25">
                    <mat-form-field>
                        <mat-label>Distance</mat-label>
                        <input matInput placeholder="Distance" [(ngModel)]="distance" />
                    </mat-form-field>
                </div>
            </div>
            <div data-layout="row">
                <div data-flex="100">
                    <label>Data</label>
                </div>
            </div>
            <div data-layout="row" data-layout-gap="1rem">
                <div data-flex="25">
                    <mat-form-field>
                        <mat-label>Deal Id</mat-label>
                        <input matInput placeholder="Deal Id" [(ngModel)]="dealId" />
                    </mat-form-field>
                </div>
                <div data-flex="25">
                    <mat-form-field>
                        <mat-label>Chain Id</mat-label>
                        <input matInput placeholder="Chain Id" [(ngModel)]="chainId" />
                    </mat-form-field>
                </div>
                <div data-flex="25">
                    <mat-form-field>
                        <mat-label>Chain Name</mat-label>
                        <input matInput placeholder="Chain Name" [(ngModel)]="chainName" />
                    </mat-form-field>
                </div>
                <div data-flex="25">
                    <mat-form-field>
                        <mat-label>UPC</mat-label>
                        <input matInput placeholder="UPC" [(ngModel)]="upc" />
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="generatePayload()" class="pull-right main-action-button">
                Generate payload
            </button>
        </mat-card-actions>
        <mat-card-footer>
            <div data-layout="row">
                <div data-flex="100">
                    {{ payload }}
                </div>
            </div>
            <div data-layout="row">
                <div data-flex="100">
                    {{ decoded }}
                </div>
            </div>
        </mat-card-footer>
    </mat-card>
</div>

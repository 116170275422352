<div matDialogTitle>
    <h2>
        Create a Team
    </h2>

    <button mat-icon-button matDialogClose class="mat-dialog-close">
        <mat-icon>
            clear
        </mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div data-layout="row" *ngIf="realms.length > 1">
        <div data-flex="100">
            <mat-form-field class="full-width">
                <mat-label>Realm</mat-label>
                <mat-select placeholder="Realm" name="realm" required [ngModel]="newTeam?.realm[0]"
                        (ngModelChange)="newTeam.realm[0] = $event" class="full-width">
                    <mat-option *ngFor="let realm of realms" [value]="realm">
                        {{ realm }}
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="!newTeam.realm">
                    You must select a team realm
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div data-layout="row">
        <div data-flex="100">
            <mat-form-field class="full-width">
                <mat-label>Team Name</mat-label>
                <input matInput placeholder="Team Name" required type="text" #newTeamNameInput
                        name="newTeamName" [(ngModel)]="newTeam.name" (input)="updateKeyName()" />

                <mat-error *ngIf="!newTeam.name">
                    You must enter a name for this team
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div data-layout="row">
        <div data-flex="100">
            <mat-checkbox *ngIf="newTeam?.realm[0] === 'partner'" name="newTeamApiKey"
                    [(ngModel)]="createNewKey" (click)="getUsagePlans()">
                Create new API key for team?
            </mat-checkbox>
        </div>
    </div>

    <div data-layout="row" data-layout-gap="1rem" class="create-key-wrapper" *ngIf="createNewKey">
        <div data-flex="50">
            <mat-form-field class="full-width">
                <mat-label>Key Name</mat-label>
                <input matInput placeholder="Key Name" required name="newKeyName" [(ngModel)]="newApiKey.name" />

                <mat-error *ngIf="!newApiKey.name">
                    You must enter a name for this key
                </mat-error>
            </mat-form-field>
        </div>

        <div data-flex="50">
            <mat-form-field class="full-width">
                <mat-label>Usage Plan</mat-label>
                <mat-select placeholder="Usage Plan" required name="usagePlan"
                        [(ngModel)]="newApiKey.usagePlan" class="full-width">
                    <mat-option *ngFor="let usagePlan of apiUsagePlans" [value]="usagePlan">
                        {{ usagePlan.name }}
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="!newApiKey.usagePlan">
                    You must select a usage plan for this key
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="actions">
        <button mat-raised-button matDialogClose>
            Cancel
        </button>

        <button mat-raised-button (click)="createTeam()">
            Create Team
        </button>
    </div>
</mat-dialog-actions>

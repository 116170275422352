import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ObserversModule } from "@angular/cdk/observers";
import { FormGroupDirective } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";

import {
    BrandSelectorModule,
    ChainSelectorModule,
    DatePickerDirectivesModule,
    FormDirectivesModule,
    ManufacturerSelectorModule,
    MgdHotkeyModule,
    MultiSelectorModule,
    NotificationModule
} from "@shopliftr/common-ng";

import { DealSearchFiltersFormComponent } from "./deal-search-filters-form.component";

import { LocationPickerModule } from "../../shared/location-picker/location-picker.module";
import { SharedModule } from "../../shared/shared.module";


@NgModule({
    declarations: [
        DealSearchFiltersFormComponent
    ],
    imports: [
        BrandSelectorModule,
        ChainSelectorModule,
        CommonModule,
        FormDirectivesModule,
        LocationPickerModule,
        ManufacturerSelectorModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatDatepickerModule,
        DatePickerDirectivesModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MultiSelectorModule,
        NotificationModule,
        ObserversModule,
        SharedModule,
        MgdHotkeyModule
    ],
    exports: [
        DealSearchFiltersFormComponent
    ],
    providers: [
        FormGroupDirective
    ]
})
export class DealSearchFiltersFormModule {}

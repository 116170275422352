import {
    Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { Subscription } from "rxjs";

import {
    Category, Results
} from "@shopliftr/common-js/shared";
import { Keyword } from "@shopliftr/common-js/admin";
import {
    ExceptionHandlerService, NotificationService, IAction, CategoryService
} from "@shopliftr/common-ng";

import { KeywordService } from "../keyword.service";
import { KeywordDataSource } from "../model/keyword-data-source";

@Component({
    selector: "keyword-list",
    templateUrl: "./keyword-list.component.html",
    styleUrls: ["./keyword-list.component.scss"]
})
export class KeywordListComponent implements OnInit, OnDestroy {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @Output() selectKeyword: EventEmitter<Keyword> = new EventEmitter<Keyword>(false);

    actions: Array<IAction>;

    categories: Array<Category>;

    keywords: Array<Keyword>;

    next: number;

    query: string;

    delay: NodeJS.Timeout;

    dataSource: KeywordDataSource;

    displayedColumns = ["keyword", "categories", "query", "actions"];

    private readonly _subscriptions = new Subscription();

    constructor(private readonly _keywordService: KeywordService,
        private readonly _categoryService: CategoryService,
        private readonly _notificationService: NotificationService,
        private readonly _exceptionHandlerService: ExceptionHandlerService) {}


    ngOnInit(): void {

        this.actions = [
            {
                id: "add",
                icon: "add",
                label: "Create New Keyword"
            }
        ];

        this._subscriptions.add(
            this._categoryService.getCategories().subscribe({
                next: (categories: Results<Category>) => {

                    this.categories = categories.results;
                },
                error: (error) => {

                    this._exceptionHandlerService.sendException(error);
                }
            })
        );
        this.updateQuery();

    }

    ngOnDestroy(): void {

        this._subscriptions.unsubscribe();
    }

    actionTriggered(actionId: string): void {

        if (actionId === "add") {
            this.newKeyword();
        }
    }


    clearQuery(): void {

        this.query = null;
        this.updateQuery();
    }


    deleteKeyword(keyword: Keyword, event: Event): void {

        event.stopPropagation();

        this._subscriptions.add(
            this._notificationService.displayConfirmation(
                `Are you sure you would like to delete ${keyword.keyword}?`,
                "Delete keyword?",
                "Yes",
                "No"
            ).subscribe((confirm: boolean) => {
                if (confirm) {
                    this._subscriptions.add(
                        this._keywordService.deleteKeyword(keyword).subscribe({
                            next: () => {

                                this.clearQuery();
                                this._notificationService.displaySuccessMessage("Keyword deleted");
                            },
                            error: (error) => {

                                this._exceptionHandlerService.sendException(error);
                            }
                        })
                    );
                }
            })
        );
    }


    newKeyword(): void {

        this.selectKeyword.next(new Keyword());
    }


    onSearchChange(): void {

        // The delay is rolling, and gets reset with each new input
        clearTimeout(this.delay);

        this.delay = setTimeout(() => {

            this.updateQuery();
        }, 1000);
    }


    setSelectedKeyword(keyword: Keyword): void {

        this.selectKeyword.emit(keyword);
    }


    updateKeywords(): void {

        this.dataSource = new KeywordDataSource(this.paginator, this._keywordService, this.query);
    }


    updateQuery(): void {

        this.next = null;
        this.keywords = [];
        this.updateKeywords();
    }
}


/**
 * A pipe that iterates over an object and
 * returns it's individual keys and values
 */
import {
    Pipe, PipeTransform
} from "@angular/core";

@Pipe({
    name: "capitalizePipe"
})

export class CapitalizePipe implements PipeTransform {

    transform(value: string): string {

        if (value !== null) {
            const input = value.toLowerCase();
            return `${input.substring(0, 1).toUpperCase()}${input.substring(1)}`;
        }
    }
}

import { NgModule } from "@angular/core";
import { PageNotFoundComponent } from "./page-not-found.component";

@NgModule({
    imports: [],
    exports: [
        PageNotFoundComponent
    ],
    declarations: [
        PageNotFoundComponent
    ],
    providers: []
})

export class PageNotFoundModule {}

import { SupportedApplication } from "@shopliftr/common-js/shared";
import packageInfo from "../../package.json";

export const environment = {
    appVersion: packageInfo.version,
    appName: SupportedApplication.Admin,
    production: false,
    staging: false,
    googleMapsApiKey: "AIzaSyB4Io364-HZr1Htaw8ZmEUXfkT7F8p8Xfs",
    batchUrl: "http://dev.batch.mygrocerydeals.com",
    apiUrl: "https://dev.internal-api.mygrocerydeals.com",
    smartAppUrl: "https://dev.smart.shopliftr.com",
    rollbar: {
        enabled: false,
        environment: "development",
        accessToken: "05b836cbd4ff43748bb83b653af71ba1"
    },
    flyerVersion: {
        elasticSearchDelay: 1000
    },
    product: {
        assetsUrl: "s3.amazonaws.com/mgd.web.products.dev",
        cloudfrontUrl: "dev.products.mygrocerydeals.com",
        elasticSearchDelay: 1500
    },
    smart: {
        assetsUrl: "https://assets.dev.ads.mygrocerydeals.com"
    },
    timezone: "America/Chicago",
    authentication: {
        postLoginPath: "/admin",
        logoUrl: "/assets/images/logo-black-shopliftr.svg",
        heading: "Data Entry Portal",
        logins: [
            {
                title: "Deal Data Entry Agent Login",
                email: "uat_agent@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Product Data Entry Agent Login",
                email: "uat_product_data_entry_agent@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Store Data Entry Agent Login",
                email: "uat_store_data_entry_agent@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Supervisor Login",
                email: "uat_supervisor@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Team Lead Login",
                email: "uat_teamlead@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Data Entry Super User",
                email: "uat_superuser@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Admin Login",
                email: "uat_admin@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Partner Admin Login",
                email: "uat_partner_admin@shopliftr.com",
                password: "Password!2"
            },
            {
                title: "Sysadmin Login",
                email: "uat_sysadmin@shopliftr.com",
                password: "Password!2"
            }
        ]
    },
    displayUncaughtErrorMessage: true
};

import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Subscription } from "rxjs";
import { merge } from "rxjs";
import { throwError } from "rxjs";

import { DataSource } from "@angular/cdk/collections";
import {
    MatPaginator
} from "@angular/material/paginator";

import { Results } from "@shopliftr/common-js/shared";
import { Keyword } from "@shopliftr/common-js/admin";
import { KeywordService } from "../keyword.service";

export class KeywordDataSource extends DataSource<any> {

    total: number;

    query: string;

    subject: BehaviorSubject<Array<Keyword>>;

    pageSubscription: Subscription;

    constructor(
        private readonly _paginator: MatPaginator,
        private readonly _keywordService: KeywordService,
        query: string
    ) {

        super();
        this.query = query;
        this._paginator.pageIndex = 0;
    }


    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Array<Keyword>> {

        this.subject = new BehaviorSubject<Array<Keyword>>([]);

        this.pageSubscription = this._paginator.page.subscribe(() => {

            this._getData();
        });

        if (!this.subject.isStopped) {
            this._getData();
        }

        return merge(this.subject);
    }


    disconnect(): void {

        this.pageSubscription.unsubscribe();
        this.subject.complete();
    }


    private _getData(): void {

        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        this._keywordService.getKeywords(this.query, startIndex, this._paginator.pageSize).subscribe((results: Results<Keyword>) => {

            this.total = results.total;
            this._paginator.length = results.total;
            this.subject.next(results.results);
        },
        (error) => {

            throwError(error);
        });
    }
}

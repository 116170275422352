import {
    Component, OnInit, Input, Output, EventEmitter, OnDestroy
} from "@angular/core";
import { Subscription } from "rxjs";

import { Keyword } from "@shopliftr/common-js/admin";
import {
    Category, Results
} from "@shopliftr/common-js/shared";
import { KeywordService } from "../keyword.service";
import {
    ExceptionHandlerService,
    NotificationService,
    CategoryService
} from "@shopliftr/common-ng";

@Component({
    selector: "keyword-form",
    templateUrl: "./keyword-form.component.html",
    styleUrls: ["./keyword-form.component.scss"]
})
export class KeywordFormComponent implements OnInit, OnDestroy {

    @Input() selectedKeyword: Keyword;

    @Output() complete: EventEmitter<Keyword> = new EventEmitter<Keyword>(false);

    categories: Array<Category>;

    selectedCategories: Array<string>;

    private readonly _subscriptions = new Subscription();

    constructor(private readonly _categoryService: CategoryService,
        private readonly _keywordService: KeywordService,
        private readonly _exceptionHandlerService: ExceptionHandlerService,
        private readonly _notificationService: NotificationService) {}


    ngOnInit(): void {

        if (!this.selectedKeyword.majorCategoryIds) {
            this.selectedKeyword.majorCategoryIds = [];
        }
        this._subscriptions.add(
            this._categoryService.getCategories().subscribe((results: Results<Category>) => {

                this.categories = results.results;
            })
        );
    }

    ngOnDestroy(): void {

        this._subscriptions.unsubscribe();
    }

    submitKeyword(event: Event): void {

        event.preventDefault();

        if (this.selectedKeyword.id) {
            this._subscriptions.add(
                this._keywordService.updateKeyword(this.selectedKeyword).subscribe({
                    next: (keyword: Keyword) => {

                        this._notificationService.displaySuccessMessage("Keyword Updated");
                        this.complete.emit(keyword);
                    },
                    error: (error) => {

                        this._exceptionHandlerService.sendException(error);
                    }
                })
            );
        }
        else {
            this._subscriptions.add(
                this._keywordService.createKeyword(this.selectedKeyword).subscribe({
                    next: (keyword: Keyword) => {

                        this._notificationService.displaySuccessMessage("Keyword Created");
                        this.complete.emit(keyword);
                    },
                    error: (error) => {

                        this._exceptionHandlerService.sendException(error);
                    }
                })
            );
        }
    }


    cancel(event: Event): void {

        event.preventDefault();
        this.complete.next(undefined);
    }
}

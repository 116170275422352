import { Injectable } from "@angular/core";
import {
    FileSaverOptions,
    saveAs
} from "file-saver";

@Injectable({
    providedIn: "root"
})
export class FileSaverService {

    saveFile(blob: Blob, fileName: string, option?: unknown): void {

        saveAs(blob, fileName, option as FileSaverOptions);
    }
}

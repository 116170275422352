import { environment as baseConfig } from "./environment.base";
baseConfig.googleMapsApiKey = "AIzaSyBdte_nNIvL657MasaOnzTkH8zfgxPO--o";
baseConfig.production = true;
baseConfig.staging = false;
baseConfig.batchUrl = "http://batch.mygrocerydeals.com";
baseConfig.apiUrl = "https://internal-api.mygrocerydeals.com";
baseConfig.smartAppUrl = "https://smart.shopliftr.com";
baseConfig.rollbar.environment = "production";
baseConfig.rollbar.enabled = true;
baseConfig.product.assetsUrl = "s3.amazonaws.com/mgd.web.products";
baseConfig.product.cloudfrontUrl = "products.mygrocerydeals.com";
baseConfig.authentication.logins = null;
baseConfig.displayUncaughtErrorMessage = false;
export const environment = baseConfig;

import {
    Pipe, PipeTransform
} from "@angular/core";
import { Category } from "@shopliftr/common-js/shared";
@Pipe({
    name: "categoryName"
})
export class CategoryNamePipe implements PipeTransform {

    constructor() {}


    transform(value: Array<unknown>, args?: Array<unknown>): any {

        try {
            const categories = args.reduce((result: Map<string, Category>, item: Category) => {

                result[item.id] = item;
                return result;
            }, {});
            const names = value.map((id: string) => {

                return categories[id].name;
            });

            return names.sort();
        }
        catch (exception) {
            return [];
        }
    }
}

<div class="mgd-layout-page-content">
    <section class="mgd-layout-page-content-section">
        <form #apiKeyForm="ngForm" (submit)="submitApiKey($event)">
            <div class="api-key-form-content">
                <div data-layout="row">
                    <div data-flex="100">
                        <mat-form-field class="full-width">
                            <mat-label>Name</mat-label>
                            <input matInput #name="ngModel" [(ngModel)]="selectedApiKey.name" name="keyword"
                                    placeholder="name" [disabled]="processing" required />
                        </mat-form-field>
                    </div>
            </div>

                <div data-layout="row" data-layout-gap="1rem">
                    <div data-flex="50">
                        <mat-form-field class="full-width">
                            <mat-label>Select a team</mat-label>
                            <team-selector [realms]="['partner']" [(ngModel)]="team"
                                    placeholder="Team" [required]="true" [isDisabled]="processing" name="team">
                            </team-selector>
                        </mat-form-field>
                    </div>
                    <div data-flex="50">
                        <mat-form-field class="full-width">
                            <mat-label>Usage Plan</mat-label>
                            <mat-select #usagePlan="ngModel" [(ngModel)]="selectedUsagePlan" name="usagePlan"
                                    placeholder="Usage Plan" (ngModelChange)="setSelectedUsagePlan()" [disabled]="processing" required>
                                <mat-option *ngFor="let apiUsagePlan of usagePlans" [value]="apiUsagePlan">{{ apiUsagePlan.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div data-layout="row">
                    <div data-flex="100">
                        <mat-slide-toggle [(ngModel)]="selectedApiKey.enabled" name="enabled" labelPosition="before">
                            ENABLED
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>

            <div class="mgd-layout-action-buttons-section">
                <button mat-raised-button type="button" [disabled]="processing" (click)="cancel($event)">
                    Cancel
                </button>

                <button mat-raised-button type="submit" [disabled]="!apiKeyForm.form.valid || processing">
                    Save
                </button>
            </div>
        </form>
    </section>
</div>

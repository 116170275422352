import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Subscription } from "rxjs";
import { merge } from "rxjs";

import { DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";

import { ApiKey } from "@shopliftr/common-js/admin";
import { Results } from "@shopliftr/common-js/shared";
import { ApiKeyService } from "@shopliftr/common-ng";

import { ApiKeySearchOptions } from "./api-key-search-options";

export class ApiKeyDataSource extends DataSource<any> {

    total: number;

    subject: BehaviorSubject<Array<ApiKey>>;

    pageSubscription: Subscription;

    constructor(
        private readonly _paginator: MatPaginator,
        private readonly _searchOptions: ApiKeySearchOptions,
        private readonly _apiKeyService: ApiKeyService
    ) {

        super();
        this.total = 0;
    }


    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<Array<ApiKey>> {

        this.subject = new BehaviorSubject<Array<ApiKey>>([]);

        this._getData();
        this.pageSubscription = this._paginator.page.subscribe(() => {

            this._getData();
        });

        return merge(this.subject);
    }


    disconnect(): void {

        this.pageSubscription.unsubscribe();
        this.subject.complete();
    }


    private _getData(): void {

        this._searchOptions.page = this._paginator.pageIndex ?? 0;
        if (!this._searchOptions.teamId) {
            this.total = 0;
            this.subject.next(new Array<ApiKey>());
            return;
        }

        // TODO: startIndex and pageSize should not be strings in the common-ng service.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        this._apiKeyService
            .getApiKeys(this._searchOptions.teamId,
                this._searchOptions.includeDisabled,
                String(startIndex),
                String(this._paginator.pageSize))
            .subscribe({
                next: (results: Results<ApiKey>) => {

                    this.total = results.total;
                    this._paginator.length = results.total;
                    this.subject.next(results.results);
                }
            });
    }
}

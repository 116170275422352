import {
    Component, OnInit
} from "@angular/core";
import {
    Router, ActivatedRoute
} from "@angular/router";

import {
    User, Team
} from "@shopliftr/common-js/shared";
import {
    AuthenticationService, NotificationService
} from "@shopliftr/common-ng";

@Component({
    selector: "user-container",
    templateUrl: "./user.component.html",
    styleUrls: ["./user.component.scss"]
})

export class UserComponent implements OnInit {

    currentUser: User;

    selectedTeam: Team;

    editableUser: User;

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _authService: AuthenticationService,
        private readonly _notificationService: NotificationService
    ) {}


    ngOnInit(): void {

        // Get current User
        this.currentUser = this._authService.authenticatedUser.getValue();

        this.selectedTeam = Team.deserialize({

            id: this.currentUser.teamId,
            name: this.currentUser.teamName
        });

        this._route.params.subscribe(
            (data) => {

                if (!data["edit"]) {
                    this.editableUser = null;
                }
            }
        );
    }


    setEditableUser($event: {user?: User; selectedTeam?: Team}): void {

        if ($event && $event.user) {
            const user = $event.user;
            let canEdit = true;
            const permissions = this.currentUser.permissions;
            const rolePattern = "user-set-role-";
            const roles = new Array<string>();

            for (const permission of permissions) {
                // Get roles current user is allowed to assign
                if (permission.includes(rolePattern)) {
                    const role = permission.replace(rolePattern, "");
                    roles.push(role);
                }
            }

            if (user.roles) {
                for (const role of user.roles) {
                    if (!roles.includes(role)) {
                        canEdit = false;
                        break;
                    }
                }

                if (canEdit) {
                    this._setUserAndTeam(user, $event.selectedTeam);
                    void this._router.navigate(["/admin/users", { edit: "user" }]);
                }
                else {
                    this._notificationService.displayErrorMessage("You cannot edit this user with your current role");
                }
            }
            else {
                this._setUserAndTeam(user, $event.selectedTeam);
            }
        }
    }


    /**
     * Added for code readability. This logic in this component needs to be reviewed for accuracy and correctness.
     */
    private _setUserAndTeam(user: User, selectedTeam?: Team): void {

        this.editableUser = user;

        if (selectedTeam) {
            this.selectedTeam = selectedTeam.clone();
        }
    }
}

<div class="page-not-found-component">
    <h1>Oops!</h1>
    <h2>
        We are unable to find what
        <br/>
        you are looking for.
    </h2>
    
    <button mat-raised-button (click)="goToDashboard()" class="primary">Back to dashboard</button>
</div>

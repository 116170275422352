import {
    Pipe, PipeTransform
} from "@angular/core";
import { GeoRegex } from "@shopliftr/common-js/shared";


@Pipe({
    name: "displayAddress"
})
export class DisplayAddressPipe implements PipeTransform {

    transform(value: unknown, format?: "short" | "medium" | "long"): any {

        if (!value) {
            return "";
        }

        const output = new Array<string>();

        const address = value as Record<string, string>;
        if (address.address) {
            output.push(address.address);
        }

        if (address.city) {
            output.push(address.city);
        }

        if (address.state && (format === "medium" || format === "long")) {
            output.push(address.state);
        }

        if (address.country && format === "long") {
            output.push(address.country);
        }

        if (address.zip && (format === "medium" || format === "long")) {
            const processedZip = GeoRegex.processZip(address.zip);

            output.push(processedZip ? processedZip.pretty : address.zip);
        }

        return output.join(", ");
    }
}

import { NgModule } from "@angular/core";

import { GettingStartedComponent } from "./getting-started.component";
import { GettingStartedMarkdownComponent } from "./getting-started-markdown/getting-started-markdown.component";

@NgModule({
    imports: [],
    exports: [
        GettingStartedComponent
    ],
    declarations: [
        GettingStartedComponent,
        GettingStartedMarkdownComponent
    ],
    providers: []
})

export class GettingStartedModule {}

import {
    Observable,
    map
} from "rxjs";

import {
    Injectable
} from "@angular/core";

import {
    DataEntryDeal, DataEntryDealRequestOptions
} from "@shopliftr/common-js/admin";
import {
    ModelAction,
    StringUtil
} from "@shopliftr/common-js/data";
import {
    BulkResponse,
    BulkUtil,
    CreateRequest,
    CreateResponse,
    DealSearchFilterOptions,
    DeleteRequest,
    DeleteResponse,
    GetResponse,
    ISearchSort,
    RequestUtil,
    SearchRequest,
    SearchResponse,
    SearchSortDirection,
    UpdateRequest,
    UpdateResponse
} from "@shopliftr/common-js/shared";

import {
    InternalApiRequestService,
    IInternalApiService,
    ServiceException
} from "@shopliftr/common-ng";


@Injectable({ providedIn: "root" })
/**
 * Leverages the DataEntryDeal endpoint to retrieve and manipulate data entry deals
 */
export class DataEntryDealService implements IInternalApiService<DataEntryDeal> {

    private readonly _basePath: string;


    constructor(
        private readonly _internalApiRequestService: InternalApiRequestService
    ) {

        this._basePath = `data-entry-deal`;
    }


    bulkDelete(items: Array<DeleteRequest<DataEntryDealRequestOptions>>): Observable<BulkResponse> {


        if (!items?.length) {
            throw new ServiceException("Could not perform a bulk operation with no requests");
        }


        const bulkRequest = BulkUtil.createBulkRequest(items.map((request: DeleteRequest<DataEntryDealRequestOptions>) => {

            return BulkUtil.createRequestItem(request);
        }));


        return this._internalApiRequestService.bulk(bulkRequest);
    }


    bulkUpdate(
        requests: Array<UpdateRequest<DataEntryDeal, DataEntryDealRequestOptions>>
    ): Observable<BulkResponse> {


        if (!requests?.length) {
            throw new ServiceException("Could not perform a bulk operation with no requests");
        }

        const bulkRequest = BulkUtil.createBulkRequest(requests.map(
            (request: UpdateRequest<DataEntryDeal, DataEntryDealRequestOptions>) => {

                return BulkUtil.createRequestItem(request);
            }
        ));

        return this._internalApiRequestService.bulk(bulkRequest);
    }


    create(deal: DataEntryDeal, options: DataEntryDealRequestOptions): Observable<DataEntryDeal> {

        if (!options) {
            throw new ServiceException("Could not create data entry deal: options are required, but were not provided");
        }

        const request: CreateRequest<DataEntryDeal, DataEntryDealRequestOptions> = RequestUtil.createCreateRequest(
            ModelAction.CreateRequest,
            deal,
            options
        );

        this._sanitizeLocalizations(request.fields);

        return this._internalApiRequestService.create(
            request,
            this._basePath
        ).pipe(map((response: CreateResponse<DataEntryDeal>) => response.payload));
    }


    delete(id: string, version: number, options: DataEntryDealRequestOptions): Observable<DeleteResponse> {

        if (!options) {
            throw new ServiceException("Could not delete data entry deal: options are required, but were not provided");
        }

        const request = RequestUtil.createDeleteRequest(id, DataEntryDeal.modelName, ModelAction.DeleteRequest, version, options);

        return this._internalApiRequestService.delete(request, this._basePath);
    }


    get(id: string): Observable<DataEntryDeal> {

        const request = RequestUtil.createGetRequest<void>(id, DataEntryDeal.modelName, ModelAction.GetRequest);

        return this._internalApiRequestService.get(request, this._basePath)
            .pipe(map((response: GetResponse<DataEntryDeal>) => response.payload));
    }


    getAll(filterOptions: DealSearchFilterOptions, sort?: Array<ISearchSort>): Observable<Array<DataEntryDeal>> {

        const resolvedSort: Array<ISearchSort> = sort ?? [{
            field: "name",
            direction: SearchSortDirection.ascending
        }];

        const request = RequestUtil.createSearchRequest(
            ModelAction.SearchRequest,
            DataEntryDeal.modelName,
            undefined,
            undefined,
            filterOptions,
            resolvedSort
        );
        return this._internalApiRequestService.getAll(request, this._basePath);
    }


    search(
        filterOptions: DealSearchFilterOptions,
        page?: number,
        pageSize?: number,
        sort?: Array<ISearchSort>
    ): Observable<SearchResponse<DataEntryDeal>> {

        const request: SearchRequest<DealSearchFilterOptions> = RequestUtil.createSearchRequest(
            ModelAction.SearchRequest,
            DataEntryDeal.modelName,
            (page !== undefined && pageSize !== undefined) ? page * pageSize : undefined,
            pageSize,
            filterOptions,
            sort
        );

        return this._internalApiRequestService.search(request, this._basePath);
    }


    update(
        id: string,
        version: number,
        fields: DataEntryDeal,
        removeFields: Array<string> | undefined,
        options: DataEntryDealRequestOptions
    ): Observable<DataEntryDeal> {

        if (!options) {
            throw new ServiceException("Could not update data entry deal: options are required, but were not provided");
        }

        const request = RequestUtil.createUpdateRequest(id, ModelAction.UpdateRequest, fields, version, removeFields, options);

        return this._internalApiRequestService.update(request, this._basePath).pipe(map(
            (response: UpdateResponse<DataEntryDeal>) => response.payload
        ));
    }


    /**
     * Returns the total number of deals for the flyer
     *
     */
    getCount(flyerId: string): Observable<number> {

        const filterOptions = new DealSearchFilterOptions();
        filterOptions.setDefaults();
        filterOptions.flyerId = flyerId;
        return this.search(filterOptions, 0, 0).pipe(map((value) => value.total));
    }

    /**
     * Returns true if the a deal has been enetered on the flyer for the given upc
     */
    dealExistsForUpc(flyerId: string, upc: string): Observable<boolean> {

        const filterOptions = new DealSearchFilterOptions();
        filterOptions.setDefaults();
        filterOptions.flyerId = flyerId;
        filterOptions.upcs = [upc];
        return this.search(filterOptions, 0, 0).pipe(map((value) => value.total > 0));
    }


    /**
     * Remove empty strings from the localizations
     */
    private _sanitizeLocalizations(fields: DataEntryDeal): void {

        if (fields?.localizations) {
            for (const localization of fields.localizations) {
                for (const fieldName in localization) {
                    if (StringUtil.isString(fields[fieldName]) && StringUtil.emptyString(fields[fieldName] as string)) {
                        delete localization[fieldName];
                    }
                }
            }
        }
    }
}

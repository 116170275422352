import {
    Observable,
    of,
    Subscription
} from "rxjs";
import { mergeMap } from "rxjs/operators";

import {
    Component,
    ErrorHandler,
    Inject,
    OnDestroy,
    OnInit
} from "@angular/core";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Location } from "@angular/common";
import { HttpParams } from "@angular/common/http";
import { PageEvent } from "@angular/material/paginator";
import {
    ActivatedRoute,
    Params
} from "@angular/router";

import {
    BreadcrumbsEventType,
    CommonDatePipe,
    CommonErrorHandler,
    ComponentEvent,
    FileService,
    IAction,
    IInternalApiService,
    InvalidEventActionException,
    NotificationService
} from "@shopliftr/common-ng";
import {
    ModelUtil,
    StringUtil
} from "@shopliftr/common-js/data";
import {
    DateTimeDisplayFormat,
    DateTimeMode,
    Deal,
    DealSearchFilterOptions,
    IListSortOption,
    ISearchSort,
    SearchResponse,
    SearchSortDirection
} from "@shopliftr/common-js/shared";

import { DealSearchFiltersFormActionEventType } from "../deal-search-filters-form/enum/deal-search-filters-form-action-event-type.enum";
import { DealSearchDetailsViewActionEventType } from "../deal-search-details-view/enum/deal-search-details-view-action-event-type.enum";
import { DealSearchListActionEventType } from "../deal-search-list/enum/deal-search-list-action-event-type.enum";

import { DataEntryDealService } from "../../services/data-entry-deal.service";
import { DealService } from "../../services/deal.service";
import { DataEntryDeal } from "@shopliftr/common-js/admin";


/**
 * Wrapper component for deal search
 */
@Component({
    selector: "deal-search-management",
    templateUrl: "./deal-search-management.component.html"
})
export class DealSearchManagementComponent implements OnDestroy, OnInit {

    processing = false;

    scrollPosition: number;

    searchHistoricalDeals = false;

    selectedDeal: Deal | DataEntryDeal;

    dealSearchFilterOptions = new DealSearchFilterOptions();

    deals: Array<Deal | DataEntryDeal>;

    dealHighlightIds: Array<string>;

    dealPage = 0;

    dealPageSize = 25;

    dealPageSizeOptions = [25, 50, 100, 1000];

    dealTotal: number;

    dealSortDirection: SearchSortDirection;

    dealSortField: string;

    dateTimeDisplayFormat = DateTimeDisplayFormat.getFormat(DateTimeMode.DateTime, false);

    dealSearchListActions: Array<IAction>;

    dealDisplayColumns: Array<string> = [
        "image",
        "upc",
        "brand",
        "name",
        "description",
        "uom",
        "saleStart",
        "saleEnd",
        "price",
        "entryFlyerName"
    ];

    breadcrumbs: Array<string>;

    private _lastQueryParams: any = {};

    private readonly _dateTimeFormat = DateTimeDisplayFormat.getFormat(DateTimeMode.Date);

    private readonly _subscriptions = new Subscription();


    constructor(
        private readonly _dataEntryDealService: DataEntryDealService,
        private readonly _datePipe: CommonDatePipe,
        private readonly _dealService: DealService,
        private readonly _fileService: FileService,
        private readonly _location: Location,
        private readonly _notificationService: NotificationService,
        private readonly _route: ActivatedRoute,
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler,
        @Inject("AppConfig") private readonly _appConfig: any
    ) {}


    ngOnDestroy(): void {

        this._subscriptions.unsubscribe();
    }


    ngOnInit(): void {

        this._resetBreadCrumbs();
        this._subscriptions.add(
            this._route.params.pipe(
                mergeMap((params: Params): Observable<Deal | DataEntryDeal> => {

                    if (params.dealId) {
                        if (!this.selectedDeal || this.selectedDeal.id !== params.dealId) {
                            if (StringUtil.toBoolean(this._route.snapshot?.queryParams?.historical as string)) {
                                return this._dataEntryDealService.get(params.dealId as string);
                            }
                            else {
                                return this._dealService.get(params.dealId as string);
                            }
                        }
                        else {
                            return of(this.selectedDeal);
                        }
                    }
                    else {
                        return of(undefined);
                    }
                })
            ).subscribe(
                {
                    next: (deal: Deal | DataEntryDeal) => {

                        // If the route contains a deal ID, ensure it is selected
                        if (deal) {
                            if (!this.selectedDeal || this.selectedDeal.id !== deal.id) {
                                this.selectDeal(deal, true);
                            }
                        }
                        // If the route does not contain a dealId but a deal is selected, clear the selection
                        else if (this.selectedDeal) {
                            this._clearSelectedDeal(true);
                        }
                    },
                    error: (error) => {

                        const resolvedError = this._errorHandler.handleComponentError(error);

                        this._notificationService.displayErrorMessage(`Unable to retrieve deal: ${resolvedError.message}`);
                        // Reset back to the search screen
                        this._clearSelectedDeal();
                    }
                }
            )
        );

        this._subscriptions.add(
            this._route.queryParams.subscribe((queryParams: Params) => {

                if (this._lastQueryParams !== queryParams) {
                    this._processQueryParams(queryParams);
                }

                this._lastQueryParams = queryParams;
            })
        );

        this._subscriptions.add(
            this._fileService.downloadState.subscribe(() => {
                this._setupActions();
            })
        );
    }

    /**
     * Handles actions triggered by the ActionButtonGroup
     */
    actionTriggered(actionId: string): void {

        if (actionId === "export") {
            this._exportCsv();
        }
    }


    /**
     * Handles events emitted by the deal search form
     */
    handleDealSearchDetailsViewAction(event: ComponentEvent<DealSearchDetailsViewActionEventType>): void {

        switch (event.type) {
        case DealSearchDetailsViewActionEventType.Cancel:
            this._clearSelectedDeal();

            break;
        default:
            this._errorHandler.handleComponentError(
                new InvalidEventActionException(
                    `DealSearchManagementComponent cannot handle an event with type ${event.type} from the DealSearchDetailsView`
                )
            );

            this._notificationService.displayErrorMessage("We were unable to process your request. Please contact support if you see this message again.");
        }
    }


    /**
     * Handles events emitted by the deal search filters form
     */
    handleDealSearchFiltersFormAction(event: ComponentEvent<DealSearchFiltersFormActionEventType, DealSearchFilterOptions>): void {

        switch (event.type) {

        case DealSearchFiltersFormActionEventType.SearchHistorical:
            this.searchHistoricalDeals = coerceBooleanProperty(event.subject);

            break;
        case DealSearchFiltersFormActionEventType.Update:
            this.dealSearchFilterOptions = event.subject.clone();

            this.dealPage = 0;
            this._search();

            break;
        case DealSearchFiltersFormActionEventType.Clear:
            this.dealSearchFilterOptions = new DealSearchFilterOptions();
            //falls through

        case DealSearchFiltersFormActionEventType.Touched:
            this.deals = undefined;
            this.dealPage = 0;
            this.dealTotal = 0;

            break;
        default:
            this._errorHandler.handleComponentError(
                new InvalidEventActionException(
                    `DealSearchManagementComponent cannot handle an event with type ${event.type} from the DealSearchFiltersForm`
                )
            );

            this._notificationService.displayErrorMessage("We were unable to process your request. Please contact support if you see this message again.");
        }
    }


    /**
     * Handles events emitted by the batch product list
     */
    handleDealSearchListAction(
        event: ComponentEvent<DealSearchListActionEventType, Array<Deal | DataEntryDeal> | IListSortOption | PageEvent>
    ): void {

        switch (event.type) {
        case DealSearchListActionEventType.ListLoad:
            this.resetScrollPosition();

            break;
        case DealSearchListActionEventType.ListReload:
            this._search();

            break;
        case DealSearchListActionEventType.Page:
            this.onPage(event.subject as PageEvent);

            break;
        case DealSearchListActionEventType.Select:
            this.selectDeal((event.subject as Array<Deal | DataEntryDeal>)[0]);

            break;
        case DealSearchListActionEventType.Sort:
            this.dealPage = 0;

            this.onSort(event.subject as IListSortOption);

            break;
        default:
            this._errorHandler.handleComponentError(
                new InvalidEventActionException(
                    `DealSearchManagementComponent cannot handle an event with type ${event.type} from the DealSearchList`
                )
            );

            this._notificationService.displayErrorMessage("We were unable to process your request. Please contact support if you see this message again.");
        }
    }


    /**
     * Consumes the selected crumb and resolves the state
     */
    onCrumbSelected(crumbEvent: ComponentEvent<BreadcrumbsEventType, string>): void {

        const crumb: string = crumbEvent.subject;

        if (crumb === "Deals") {
            this._clearSelectedDeal();
        }
    }


    /**
     * Refreshes the batch product list with the new page data
     */
    onPage(event: PageEvent): void {

        this.dealPage = event.pageIndex;
        this.dealPageSize = event.pageSize;

        this._search();
    }


    /**
     * Refreshes the batch product list with the new sort parameters
     */
    onSort(event: IListSortOption): void {

        if (!StringUtil.emptyString(event.direction)) {
            this.dealSortDirection = event.direction.toUpperCase() as SearchSortDirection;
        }
        this.dealSortField = event.field;

        this._search();
    }


    /**
     * Scrolls the view to the saved position. Will not scroll if the current view has a selected entity
     */
    resetScrollPosition(): void {

        if (this.scrollPosition) {
            if (!this.selectedDeal) {
                window.scrollTo(0, this.scrollPosition);
                this.scrollPosition = undefined;
            }
        }
    }


    /**
     * Makes the given BatchProduct active and updates the view
     */
    selectDeal(deal: Deal | DataEntryDeal, skipParams?: boolean): void {

        this._addCrumb("Deal Details");

        this.dealHighlightIds = [deal.id];
        this.selectedDeal = deal.clone();

        this.scrollPosition = window.scrollY;

        if (!skipParams) {
            this._setUrl();
        }
    }


    /**
     * Deselects the selected deal
     */
    private _clearSelectedDeal(skipParams?: boolean): void {

        this.selectedDeal = undefined;

        this._resetBreadCrumbs();

        if (!skipParams) {
            this._setUrl();
        }
    }


    /**
     * Adds a step to the breadcrumbs
     */
    private _addCrumb(crumb: string): void {

        this.breadcrumbs = this.breadcrumbs.concat([crumb]);
    }


    private _exportCsv(): void {

        const targetService: DealService | DataEntryDealService =
            this.searchHistoricalDeals ? this._dataEntryDealService : this._dealService;

        // This can't exist within the subscriptions block because we need it to continue to run if this component is destroyed
        this._subscriptions.add(
            this._fileService.generateTabDelimitedFile(
                targetService.getAll(
                    this.dealSearchFilterOptions
                ),
                this.searchHistoricalDeals ? DataEntryDeal : Deal,
                "deals",
                {
                    fields: [
                        {
                            fieldName: "upc",
                            label: "UPC"
                        },
                        {
                            fieldName: "brand",
                            label: "Brand"
                        },
                        {
                            fieldName: "name",
                            label: "Product Name"
                        },
                        {
                            fieldName: "description",
                            label: "Description"
                        },
                        {
                            fieldName: "manufacturer",
                            label: "Manufacturer"
                        },
                        {
                            fieldName: "size",
                            label: "Size"
                        },
                        {
                            fieldName: "uom",
                            label: "UOM"
                        },
                        {
                            fieldName: "count",
                            label: "Count"
                        },
                        {
                            fieldName: "container",
                            label: "Container"
                        },
                        {
                            fieldName: "saleStart",
                            label: "Sale Start"
                        },
                        {
                            fieldName: "saleEnd",
                            label: "Sale End"
                        },
                        {
                            fieldName: "customPrice",
                            label: "Price"
                        },
                        {
                            fieldName: "entryFlyerName",
                            label: "Flyer"
                        }
                    ],
                    lineTransformer: (item: any) => {

                        item.saleStart = this._datePipe.transform(
                            item.saleStart as Date,
                            this._dateTimeFormat,
                            this._appConfig.timezone as string
                        );

                        item.saleEnd = this._datePipe.transform(
                            item.saleEnd as Date,
                            this._dateTimeFormat,
                            this._appConfig.timezone as string
                        );
                    }
                }
            ).subscribe(
                {
                    next: (success: boolean) => {

                        if (success) {
                            this._notificationService.displaySuccessMessage("Deals successfully exported!");
                        }
                        else {
                            this._notificationService.displaySuccessMessage("Deal export cancelled");
                        }
                    },
                    error: (error) => {

                        const resolvedError = this._errorHandler.handleComponentError(error);

                        this._notificationService.displayErrorMessage(
                            `Unable to export the deals because the full deal set could not be retrieved: ${resolvedError.message}`
                        );
                    }
                }
            )
        );
    }


    /**
     * Resolves the search criteria contained in the query params
     */
    private _processQueryParams(queryParams: Params): void {

        if (Object.keys(queryParams).length) {
            this.searchHistoricalDeals = queryParams.historical ? StringUtil.toBoolean(queryParams.historical as string) : false;
            this.dealPage = queryParams.page ? parseInt(queryParams.page as string) : undefined;
            this.dealPageSize = queryParams.pageSize ? parseInt(queryParams.pageSize as string) : undefined;
            this.dealSortField = queryParams.sort ? queryParams.sort : undefined;
            this.dealSortDirection = queryParams.sortDirection ? queryParams.sortDirection : undefined;

            this.dealSearchFilterOptions = DealSearchFilterOptions.deserialize({
                brands: queryParams.filterBrands ? JSON.parse(queryParams.filterBrands as string) : undefined,
                chainIds: queryParams.filterChainIds ? JSON.parse(queryParams.filterChainIds as string) : undefined,
                endDate: queryParams.filterEndDate,
                keyword: queryParams.filterKeyword,
                manufacturers: queryParams.filterManufacturers ? JSON.parse(queryParams.filterManufacturers as string) : undefined,
                marketAreaIds: queryParams.filterMarketAreaIds ? JSON.parse(queryParams.filterMarketAreaIds as string) : undefined,
                startDate: queryParams.filterStartDate,
                upcs: queryParams.filterUpcs ? JSON.parse(queryParams.filterUpcs as string) : undefined
            });

            // Since we're consuming the query parameters here, it doesn't make sense to rebuild them
            this._search(true);
        }
        else {
            this.dealSearchFilterOptions = new DealSearchFilterOptions();

            this.deals = undefined;
        }
    }


    /**
     * Resolves the selected sort criteria into a proper form for the search endpoint
     */
    private _processSortFields(): Array<ISearchSort> {

        if (this.dealSortField) {
            switch (this.dealSortField) {
            case "uom":
                return [
                    {
                        field: this.dealSortField,
                        direction: this.dealSortDirection
                    },
                    {
                        field: "size",
                        direction: this.dealSortDirection
                    }
                ];
            default:
                return [
                    {
                        field: this.dealSortField,
                        direction: this.dealSortDirection
                    }
                ];
            }
        }
        else {
            return undefined;
        }
    }


    /**
     * Removes a step from the breadcrumbs
     */
    private _resetBreadCrumbs(): void {

        this.breadcrumbs = ["Deals"];
    }


    /**
     * Fetches the batch products for the current context
     */
    private _search(skipParams?: boolean): void {

        this.processing = true;

        if (!skipParams) {
            this._setUrl();
        }

        const targetService: IInternalApiService<Deal | DataEntryDeal> =
            this.searchHistoricalDeals ? this._dataEntryDealService : this._dealService;

        this._subscriptions.add(
            targetService.search(
                this.dealSearchFilterOptions,
                this.dealPage,
                this.dealPageSize,
                this._processSortFields()
            ).subscribe(
                {
                    next: (response: SearchResponse<Deal | DataEntryDeal>) => {

                        this.deals = response.results;
                        this.dealTotal = response.total;
                        this._setupActions();
                        this.processing = false;
                    },
                    error: (error) => {

                        this._notificationService.displayErrorMessage(`Unable to retrieve deals: ${this._errorHandler.handleComponentError(error).message}`);

                        this.deals = new Array<Deal | DataEntryDeal>();
                        this.processing = false;
                    }
                }
            )
        );
    }


    /**
     * Sets up the URL when the state changes
     */
    private _setUrl(): void {

        let resolvedParams: HttpParams;

        let url = "/admin/deal-search";

        if (this.selectedDeal) {
            url += `/deal/${this.selectedDeal.id}`;

            if (this.selectedDeal instanceof DataEntryDeal) {
                url += "?historical=true";
            }
        }
        else {
            resolvedParams = new HttpParams();

            if (this.dealPage) {
                resolvedParams = resolvedParams.append("page", this.dealPage.toString());
            }

            if (this.dealPageSize) {
                resolvedParams = resolvedParams.append("pageSize", this.dealPageSize.toString());
            }

            if (this.dealSortField) {
                resolvedParams = resolvedParams.append("sort", this.dealSortField);
            }

            if (this.dealSortDirection) {
                resolvedParams = resolvedParams.append("sortDirection", this.dealSortDirection);
            }

            const serialized: Record<string, Array<any> | string | number | boolean> = this.dealSearchFilterOptions.serialize();

            Object.keys(serialized).forEach((key: string) => {

                if (serialized[key]) {
                    // filter keywords are keyed using camelCase, but we prefix with snake_case before feeding into the
                    // caseTransform so that we can force the first character of the original key to capitalize.
                    // The prefix prevents potential collisions with other query params
                    resolvedParams = resolvedParams.append(
                        ModelUtil.CamelCase(`filter_${key}`),
                        Array.isArray(serialized[key]) ? JSON.stringify(serialized[key]) : (serialized[key] as string | number | boolean)
                    );
                }
            });

            if (this.searchHistoricalDeals) {
                resolvedParams = resolvedParams.append("historical", true);
            }
        }

        // https://mygrocerydeals.atlassian.net/wiki/spaces/TECHARCH/pages/1793064993/Angular+General+Standards+and+Patterns#Routing
        this._location.go(url, resolvedParams ? resolvedParams.toString() : undefined);
    }

    /**
     * Setup the actions for fileService
     */
    private _setupActions(): void {

        let label = "Export deals to CSV";
        let disabled = false;

        if (this._fileService.isDownloading) {
            label = "Cannot export deals, generating a file right now";
            disabled = true;
        }
        else if (this.dealTotal > 1000) {
            label = "Can only download a total of 1000 deals. Either narrow you search, or file a support ticket for a data slice";
            disabled = true;
        }
        else if (!this.dealTotal) {
            label = "No deals found to export";
            disabled = true;
        }
        this.dealSearchListActions = [
            {
                id: "export",
                icon: "file_download",
                label: label,
                disabled: disabled
            }
        ];
    }

}

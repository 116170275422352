import {
    Pipe, PipeTransform
} from "@angular/core";

@Pipe({
    name: "priorityPipe"
})

export class PriorityPipe implements PipeTransform {

    private readonly _alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    transform(value: number): string {

        return this._alphabet.charAt(value - 1);
    }
}

import {
    Component, OnInit
} from "@angular/core";

@Component({
    selector: "payload-generator",
    templateUrl: "./payload-generator.component.html",
    styleUrls: ["./payload-generator.component.scss"]
})
export class PayloadGeneratorComponent implements OnInit {

    latitude: string;

    longitude: string;

    zip: string;

    distance: string;

    dealId: string;

    chainId: string;

    chainName: string;

    payload: string;

    upc: string;

    decoded: string;

    constructor() {}


    ngOnInit(): void {

        this.payload = "";
    }


    generatePayload(): void {

        const object = {};
        if ((this.latitude && this.latitude) || this.zip || this.distance) {
            object["location"] = {};
        }
        if (this.latitude && this.longitude) {
            object["location"]["latitude"] = this.latitude;
            object["location"]["longitude"] = this.longitude;
        }
        if (this.zip) {
            object["location"]["zip"] = this.zip;
        }
        if (this.distance) {
            object["location"]["distance"] = this.distance;
        }
        if (this.dealId) {
            object["dealId"] = this.dealId;
        }
        if (this.chainId) {
            object["chainId"] = this.chainId;
        }
        if (this.chainName) {
            object["chainName"] = this.chainName;
        }
        if (this.upc) {
            object["upc"] = this.upc;
        }
        this.payload = encodeURIComponent(btoa(JSON.stringify(object)));
        this.decoded = atob(decodeURIComponent(this.payload));
    }
}

import {
    NgModule, ErrorHandler, Optional, SkipSelf
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    HttpClientModule, HTTP_INTERCEPTORS
} from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";

import {
    AuthenticationModule,
    CommonErrorHandler,
    CommonHttpInterceptor,
    ExceptionHandlerModule,
    InternalApiInterceptor,
    MgdHotkeyModule,
    NotificationModule
} from "@shopliftr/common-ng";

import { environment } from "../../environments/environment";


@NgModule({
    imports: [
        AuthenticationModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        ExceptionHandlerModule,
        HttpClientModule,
        NotificationModule,
        RouterModule,
        MgdHotkeyModule
    ],
    declarations: [],
    providers: [
        {
            provide: "AppConfig",
            useValue: environment
        },
        {
            provide: ErrorHandler,
            useClass: CommonErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CommonHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InternalApiInterceptor,
            multi: true
        }
    ],
    exports: []
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

        if (parentModule) {
            throw new Error("Core module cannot be imported multiple times");
        }
    }
}

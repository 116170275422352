import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    Component,
    ErrorHandler,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {
    DomSanitizer, SafeUrl
} from "@angular/platform-browser";

import { EnumUtil } from "@shopliftr/common-js/data";
import {
    DateTimeDisplayFormat,
    DateTimeMode,
    Deal,
    DealLocalization,
    Language,
    LocalizationUtil,
    MarketArea,
    ResourceBundle,
    Results
} from "@shopliftr/common-js/shared";
import {
    CommonDatePipe,
    CommonErrorHandler,
    ComponentEvent,
    MarketAreaService,
    NotificationService,
    ResourceService
} from "@shopliftr/common-ng";

import { DealSearchDetailsViewActionEventType } from "./enum/deal-search-details-view-action-event-type.enum";

import { DealSearchDetailsViewActionEvent } from "./event/deal-search-details-view-action-event";


@Component({
    selector: "deal-search-details-view",
    templateUrl: "./deal-search-details-view.component.html",
    styleUrls: ["./deal-search-details-view.component.scss"]
})
export class DealSearchDetailsViewComponent implements OnChanges, OnInit {

    @Input() deal: Deal;

    @Output() onAction: EventEmitter<DealSearchDetailsViewActionEvent> = new EventEmitter<DealSearchDetailsViewActionEvent>();


    displayLanguage = Language.English;

    chainImageUrl: SafeUrl;

    productImageUrl: SafeUrl;

    marketAreaText: string;

    allLanguages = EnumUtil.getEnumArray(Language);

    availableLanguages = new Array<{ key: string; value: string | number }>();

    localizedDeal: Deal;

    private readonly _dateTimeFormat = DateTimeDisplayFormat.getFormat(DateTimeMode.Date);

    private readonly _resources = new Map<Language, Array<ResourceBundle>>();

    private readonly _dealStatusText = {
        active: "Active Deal",
        expired: "Expired Deal",
        future: "Future Deal"
    };


    /**
     * Gets the status of the deal. If the deal is active, indicates when it wil become inactive
     */
    get dealStatus(): string {

        const now = new Date().valueOf();

        if (now < this.deal.saleStart.valueOf()) {
            return this._dealStatusText.future;
        }
        else if (now > this.deal.saleEnd.valueOf()) {
            return this._dealStatusText.expired;
        }
        else {
            return this._dealStatusText.active;
        }
    }


    /**
     * Determines whether or not the language selector should be enabled
     */
    get enableLanguageSelector(): boolean {

        return coerceBooleanProperty(this.availableLanguages.length > 1);
    }


    /**
     * Determines if the given deal is currently active
     */
    get isActive(): boolean {

        return (this.dealStatus === this._dealStatusText.active);
    }


    /**
     * Display alias for the promotion dates
     */
    get promotionDates(): string {

        const startDate = this._datePipe.transform(this.localizedDeal.saleStart, this._dateTimeFormat, this._appConfig.timezone as string);
        const endDate = this._datePipe.transform(this.localizedDeal.saleEnd, this._dateTimeFormat, this._appConfig.timezone as string);

        return `${startDate} to ${endDate}`;
    }


    /**
     * Display alias for UOM info
     */
    get uom(): string {

        return (
            this.localizedDeal ? `${LocalizationUtil.localizeNumber(this.localizedDeal.size, this.displayLanguage, false)} ${this.localizedDeal.uom}` : "--"
        );
    }


    constructor(
        private readonly _domSanitizer: DomSanitizer,
        private readonly _datePipe: CommonDatePipe,
        private readonly _marketAreaService: MarketAreaService,
        private readonly _notificationService: NotificationService,
        private readonly _resourceService: ResourceService,
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler,
        @Inject("AppConfig") private readonly _appConfig: any
    ) { }


    ngOnChanges(changes: SimpleChanges): void {

        if (changes?.deal) {
            if (this.deal) {
                this.chainImageUrl = this._domSanitizer.bypassSecurityTrustUrl(this.deal.chain.imageUrl);
                this.productImageUrl = this._domSanitizer.bypassSecurityTrustUrl(this.deal.imageUrl);

                this.availableLanguages = [{
                    key: "English",
                    value: Language.English
                }];

                // If there are multiple avilable languages, collect them so the user can toggle
                if (this.deal.localizations && this.deal.localizations.length) {
                    this.deal.localizations.forEach((localization: DealLocalization) => {

                        this.availableLanguages.push(this.allLanguages.find((value: { key: string; value: number | string }) => {

                            return (value.value === localization.language);
                        }));
                    });
                }

                // If the displayLanguage isn't valid for the incoming deal, reset it
                if (
                    this.availableLanguages.findIndex((language: { key: string; value: Language }) => {

                        return (language.value === this.displayLanguage);
                    }) === -1
                ) {
                    this.displayLanguage = Language.English;
                }

                // Get the qualified market areas from the element's stored IDs
                this._marketAreaService.multiGet(this.deal.marketAreaIds).subscribe(
                    {
                        next: (results: Array<MarketArea>) => {

                            if (results && results.length) {
                                this.marketAreaText = results.map((marketArea: MarketArea) => {

                                    return marketArea.name;
                                }).join(", ");
                            }
                            else {
                                return undefined;
                            }

                            // Build out the display fields after the market areas are retrieved
                            this.setDisplayModel();
                        },
                        error: (error) => {

                            this.marketAreaText = undefined;

                            this._errorHandler.handleComponentError(error);

                            this._notificationService.displayErrorMessage("Unable to retrieve market areas");

                            // If the call here fails, we still want to populate the rest of the field data
                            this.setDisplayModel();
                        }
                    }
                );
            }
            else {
                this.back();
            }
        }
    }


    ngOnInit(): void {

        // Gather container resources
        (EnumUtil.getValues(Language) as Array<Language>).forEach((language: Language) => {

            this._resourceService.search(
                0,
                Deal.resourceFields.length,
                undefined,
                undefined,
                {
                    languages: [language],
                    types: Deal.resourceFields
                }
            ).subscribe({
                next: (response: Results<ResourceBundle>) => {

                    if (response.count) {
                        this._resources.set(language, response.results);
                    }
                },
                error: (error) => {

                    this._errorHandler.handleComponentError(error);

                    this._notificationService.displayErrorMessage("Unable to retrieve resources. Some fields may not be displayed correctly in alternate languages");
                }
            });
        });
    }


    /**
     * Notifies the parent to return to the previous view
     */
    back(): void {

        this.onAction.emit(new ComponentEvent(DealSearchDetailsViewActionEventType.Cancel));
    }


    /**
     * Localizes the deal and sets its display values
     */
    setDisplayModel(): void {

        try {
            this.localizedDeal = LocalizationUtil.localizeModel<Deal>(
                this.deal,
                this._resources.get(this.displayLanguage),
                this.displayLanguage
            );
        }
        catch (error) {
            // The deal failed to localize. Handle the error, so it can be investigated, but continue the un-localized deal
            this.localizedDeal = this.deal?.clone();

            this._errorHandler.handleComponentError(error);
        }
    }
}

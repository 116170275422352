/**
 * A pipe that iterates over an object and
 * returns it's individual keys and values
 */

import {
    Pipe, PipeTransform
} from "@angular/core";

@Pipe({
    name: "sortAlphabeticallyPipe"
})

export class SortAlphabeticallyPipe implements PipeTransform {
    transform(value: Array<string>, property1?: any, property2?: any): any {

        const byName = value.slice(0);

        if (property2 && property1 && byName.length) {
            return byName.sort((a, b) => {

                const x = (`${a[property1]}${a[property2]}` || "").toLowerCase();
                const y = (`${b[property1]}${b[property2]}` || "").toLowerCase();

                return (x < y) ? -1 : (x > y) ? 1 : 0;
            });
        }
        else if (property1 && byName.length) {

            return byName.sort((a, b) => {

                const x = (a[property1] || "").toLowerCase();
                const y = (b[property1] || "").toLowerCase();

                return (x < y) ? -1 : (x > y) ? 1 : 0;
            });
        }
        else {
            value.sort();
            return value;
        }
    }
}

import { environment } from "../../environments/environment";

import { Subscription } from "rxjs";

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";

import {
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";

import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";

import {
    AuthenticationService,
    FullscreenService,
    NotificationService,
    SupportService
} from "@shopliftr/common-ng";

import { User } from "@shopliftr/common-js/shared";

import { BatchUtilityService } from "../services/batch-utility.service";
import { BatchIdDialogComponent } from "../shared/dialogs/batch-id-dialog/batch-id-dialog.component";


@Component({
    selector: "admin-container",
    templateUrl: "./admin.component.html",
    styleUrls: ["./admin.component.scss"]
})
export class AdminComponent implements OnDestroy, OnInit {

    @ViewChild(MatSidenav) sideNav: MatSidenav;

    currentUser: User;

    sideNavOpened = true;

    displayThemeToggle = !environment.production;

    build: string;

    private readonly _subscriptions = new Subscription();

    /**
     * Private properties for theme management
     */
    private _themeToggled = false;

    private readonly _themeKey = "partner-theme";

    private readonly _themeCss = "weyland-yutani-theme.css";

    private readonly _baseImg = "../../../assets/images/logo-white-shopliftr.svg";

    private readonly _themeImg = "../../../assets/themes/weyland-yutani-logo.png";


    constructor(
        private readonly _router: Router,
        private readonly _authService: AuthenticationService,
        private readonly _batchUtilityService: BatchUtilityService,
        private readonly _fullscreenService: FullscreenService,
        private readonly _supportService: SupportService,
        private readonly _notificationService: NotificationService,
        public dialog: MatDialog,
        @Inject("AppConfig") public appConfig: any
    ) {

        this._subscriptions.add(
            this._fullscreenService.connect().subscribe((fullscreen: boolean) => {

                setTimeout(() => {

                    this.sideNavOpened = !fullscreen;
                });
            })
        );
    }


    ngOnDestroy(): void {

        this._subscriptions.unsubscribe();
    }


    ngOnInit(): void {

        this.currentUser = this._authService.authenticatedUser.getValue();

        this._subscriptions.add(
            this._authService.authenticatedUser.subscribe((loggedInUser: User) => {

                this.currentUser = loggedInUser;
            })
        );

        this.build = this.appConfig.appVersion;
    }


    /**
     * Changes the current theme of the application
     */
    toggleTheme(): void {

        this._themeToggled = !this._themeToggled;

        if (this._themeToggled) {
            this._getLinkElementForKey(this._themeKey).setAttribute("href", this._themeCss);
            this._getImageElement().setAttribute("src", this._themeImg);
        }
        else {
            this._removeStyle(this._themeKey);
            this._getImageElement().setAttribute("src", this._baseImg);
        }
    }


    /**
     * Logs the user out of the application
     */
    logout(): void {

        this._authService.logout();
    }


    /**
     * Routes to the user's profile view
     */
    goToMyProfile(): void {

        void this._router.navigate(["/admin/settings/my-profile"]);
    }


    /**
     * Routes to the user's password update view
     */
    goToUpdatePassword(): void {

        void this._router.navigate(["/admin/settings/update-password"]);
    }


    /**
     * Performs a data export task on the requested batch
     */
    showDataExportDialog(): void {

        const dialogRef: MatDialogRef<BatchIdDialogComponent> = this.dialog.open(BatchIdDialogComponent, {
            data: {
                dialogTitle: "Enter Batch for Grocery Outlet Data Export"
            },
            width: "800px"
        });

        this._subscriptions.add(
            dialogRef.afterClosed().subscribe((batchId: string) => {

                if (batchId) {
                    this._subscriptions.add(
                        this._batchUtilityService.groceryOutletAdExport(batchId).subscribe({
                            next: () => {

                                this._notificationService.displaySuccessMessage(`Data successfully exported`);
                            },
                            error: () => {

                                this._notificationService.displayErrorMessage(`Failed to export data`);
                            }
                        })
                    );
                }
            })
        );
    }


    /**
     * Sends a status report relevant to the requested batch
     */
    showStatusReportDialog(): void {

        const dialogRef: MatDialogRef<BatchIdDialogComponent> = this.dialog.open(BatchIdDialogComponent, {
            data: {
                dialogTitle: "Enter Batch for Grocery Outlet Status Report"
            },
            width: "800px"
        });

        this._subscriptions.add(
            dialogRef.afterClosed().subscribe((batchId: string) => {

                if (batchId) {
                    this._subscriptions.add(
                        this._batchUtilityService.groceryOutletVideoAdsStatusReport(batchId).subscribe({
                            next: () => {

                                this._notificationService.displaySuccessMessage(`Status report sent`);
                            },
                            error: () => {

                                this._notificationService.displayErrorMessage(`Failed to send status report`);
                            }
                        })
                    );
                }
            })
        );
    }


    /**
     * Invoke the Support service to create a JIRA support ticket.
     */
    onReportProblem(): void {

        this._supportService.requestSupport();
    }


    /**
     * Private functions for lazy loading the partner theme when toggled.
     */

    private _removeStyle(key: string): void {
        const existingLinkElement = this._getExistingLinkElementByKey(key);
        if (existingLinkElement) {
            document.head.removeChild(existingLinkElement);
        }
    }


    private _getLinkElementForKey(key: string): Element {

        return this._getExistingLinkElementByKey(key) || this._createLinkElementWithKey(key);
    }


    private _getImageElement(): Element {

        return document.body.querySelector(
            `img.${this._getClassNameForImage()}`
        );
    }


    private _getExistingLinkElementByKey(key: string): Element {

        return document.head.querySelector(
            `link[rel="stylesheet"].${this._getClassNameForKey(key)}`
        );
    }


    private _createLinkElementWithKey(key: string): Element {

        const linkEl = document.createElement("link");
        linkEl.setAttribute("rel", "stylesheet");
        linkEl.classList.add(this._getClassNameForKey(key));
        document.head.appendChild(linkEl);
        return linkEl;
    }


    private _getClassNameForKey(key: string): string {

        return `style-toggle-${key}`;
    }


    private _getClassNameForImage(): string {

        return "partner-logo-image";
    }
}

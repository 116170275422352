import {
    Component
} from "@angular/core";
import { Keyword } from "@shopliftr/common-js/admin";
@Component({
    selector: "keyword",
    templateUrl: "./keyword.component.html",
    styleUrls: ["./keyword.component.scss"]
})
export class KeywordComponent {
    keyword: Keyword;


    constructor() {}


    clearKeyword(): void {

        this.keyword = null;
    }


    setKeyword(keyword: Keyword): void {

        this.keyword = keyword;
    }
}

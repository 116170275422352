import { NgModule } from "@angular/core";

import {
    ActionButtonGroupModule, AuthenticationModule, MultiSelectorModule, TeamSelectorModule
} from "@shopliftr/common-ng";

import { AdminMaterialModule } from "../shared/admin-material-module/admin-material.module";
import { SharedModule } from "../shared/shared.module";

import { UserComponent } from "./user.component";
import { UserDetailsComponent } from "./user-details/user-details.component";
import { UserListComponent } from "./user-list/user-list.component";

import { TeamCreateComponent } from "./dialogs/team-create/team-create.component";
import { UserBulkConfirmationComponent } from "./dialogs/user-bulk-confirmation/user-bulk-confirmation.component";


@NgModule({
    declarations: [
        TeamCreateComponent,
        UserBulkConfirmationComponent,
        UserComponent,
        UserDetailsComponent,
        UserListComponent
    ],
    imports: [
        AuthenticationModule,
        ActionButtonGroupModule,
        AdminMaterialModule,
        MultiSelectorModule,
        SharedModule,
        TeamSelectorModule
    ]
})
export class UserModule {}

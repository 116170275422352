import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {
    ActionButtonGroupModule,
    BreadcrumbsModule,
    CommonDateModule
} from "@shopliftr/common-ng";

import { DealSearchManagementComponent } from "./deal-search-management.component";

import { DealSearchFiltersFormModule } from "../deal-search-filters-form/deal-search-filters-form.module";
import { DealSearchDetailsViewModule } from "../deal-search-details-view/deal-search-details-view.module";
import { DealSearchListModule } from "../deal-search-list/deal-search-list.module";

import { SharedModule } from "../../shared/shared.module";


@NgModule({
    declarations: [
        DealSearchManagementComponent
    ],
    imports: [
        ActionButtonGroupModule,
        BreadcrumbsModule,
        CommonDateModule,
        CommonModule,
        DealSearchFiltersFormModule,
        DealSearchDetailsViewModule,
        DealSearchListModule,
        SharedModule
    ],
    exports: [
        DealSearchManagementComponent
    ],
    providers: [
    ]
})
export class DealSearchManagementModule {}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ObserversModule } from "@angular/cdk/observers";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

import {
    CommonDateModule
} from "@shopliftr/common-ng";

import { DealSearchDetailsViewComponent } from "./deal-search-details-view.component";

import { SharedModule } from "../../shared/shared.module";


@NgModule({
    declarations: [
        DealSearchDetailsViewComponent
    ],
    imports: [
        CommonModule,
        CommonDateModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ObserversModule,
        SharedModule
    ],
    exports: [
        DealSearchDetailsViewComponent
    ],
    providers: []
})
export class DealSearchDetailsViewModule {}

import {
    AfterViewInit, Component, ErrorHandler, EventEmitter, Inject, Input, OnInit, Output
} from "@angular/core";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import {
    ApiUsagePlan, ApiKey
} from "@shopliftr/common-js/admin";
import {
    User, Team, Results
} from "@shopliftr/common-js/shared";
import {
    UserService, NotificationService, TeamService, CommonErrorHandler
} from "@shopliftr/common-ng";

import { TeamCreateComponent } from "../dialogs/team-create/team-create.component";

import { fadeAnimation } from "@shopliftr/common-ng";

@Component({
    selector: "user-details",
    templateUrl: "./user-details.component.html",
    styleUrls: ["./user-details.component.scss"],
    animations: [
        fadeAnimation
    ]
})
export class UserDetailsComponent implements OnInit, AfterViewInit {

    @Input() currentUser: User;

    @Input() editableUser: User;

    @Input() savedRoute: string;

    @Input() selectedTeam: Team;

    @Output() setEditableUser = new EventEmitter<{ user?: User; selectedTeam?: Team }>();

    apiUsagePlans: Array<ApiUsagePlan>;

    originalTeam: Team;

    roles: Array<string>;

    selectedRoles: Array<string>;

    teams: Array<Team>;

    teamAccessArray: Array<string>;


    constructor(
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler,
        private readonly _location: Location,
        private readonly _notificationService: NotificationService,
        private readonly _userService: UserService,
        private readonly _teamService: TeamService,
        public dialog: MatDialog
    ) {

        this.roles = new Array<string>();
        this.selectedRoles = new Array<string>();
        this.apiUsagePlans = new Array<ApiUsagePlan>();
    }


    ngOnInit(): void {

        const permissions = this.currentUser.permissions;
        const rolePattern = "user-set-role-";

        const realm = this.editableUser.realm || (this.selectedTeam ? this.selectedTeam.realm : undefined);

        this._teamService.getTeams(undefined, realm).subscribe({
            next: (results: Results<Team>) => {

                // The user will always have access to their own team
                this.teams = results.results.filter((value: Team) => {

                    return (value.id !== this.editableUser.teamId);
                });
            },
            error: (error) => {
                const message = this._errorHandler.handleServiceError(error).message;
                this._notificationService.displayErrorMessage(`An exception occured displaying the user: ${message}`);
            }
        });

        this.teamAccessArray = (this.editableUser.teamAccess || []).filter((id: string) => {

            return (id !== this.editableUser.teamId);
        });

        for (const permission of permissions) {
            // Get roles current user is allowed to assign
            if (permission.includes(rolePattern)) {
                const role = permission.replace(rolePattern, "");

                this.roles.push(role);
            }
        }

        if (!this.selectedTeam) {
            this.selectedTeam = Team.deserialize({
                id: this.editableUser.teamId,
                name: this.editableUser.teamName
            });
        }
        else {
            this.originalTeam = this.selectedTeam.clone();
        }
    }


    ngAfterViewInit(): void {

        // set roles if existing user
        if (this.editableUser.roles) {
            for (const role of this.editableUser.roles) {
                this.selectedRoles.push(role);
            }
        }
    }


    /**
     * Closes this view and returns to the previous view. If the edit was cancelled, reset the selected team
     * to its original value
     *
     * @param {*} [event]
     * @param {boolean} [cancelEdit]
     * @memberof UserDetailsComponent
     */
    closeUserEdit(event?: Event, cancelEdit?: boolean): void {

        if (event) {
            event.preventDefault();
        }

        this._location.back();

        const emitObject: { user?: User; selectedTeam?: Team } = {
            user: null,
            selectedTeam: cancelEdit ? this.originalTeam : this.selectedTeam
        };

        this.setEditableUser.emit(emitObject);
    }


    /**
     * Shows the team creation dialog
     *
     * @param {*} [event]
     * @memberof UserDetailsComponent
     */
    createTeam(event?: Event): void {

        if (event) {
            event.preventDefault();
        }

        const dialogRef = this.dialog.open(TeamCreateComponent, {
            data: {
                currentUser: this.currentUser
            }
        });

        dialogRef.componentInstance.onCreate.subscribe((result: { team: Team; createKey: boolean; apiKey: ApiKey }) => {

            dialogRef.disableClose = true;

            this.selectedTeam = result.team;
            this.updateTeamAccess(this.selectedTeam);

            dialogRef.close();
        });
    }


    /**
     * Initiates a password reset for this user
     *
     * @memberof UserDetailsComponent
     */
    sendPasswordReset(): void {

        this._userService.forgotPassword(this.editableUser.email).subscribe({
            next: () => {

                this._notificationService.displaySuccessMessage("Password reset sent");
            },
            error: (error) => {

                const message = this._errorHandler.handleServiceError(error).message;
                this._notificationService.displayErrorMessage(`An exception occured resetting the password: ${message}`);
            }
        });
    }


    submitUser(): void {

        const userData = this.editableUser.clone();

        if (this.selectedTeam) {
            userData.teamId = this.selectedTeam.id;
            userData.teamName = this.selectedTeam.name;
        }
        else {
            userData.teamId = undefined;
            userData.teamName = undefined;
        }

        userData.teamAccess = [this.editableUser.teamId].concat(this.teamAccessArray);

        // Edit user if he already has an id (existing user)
        if (userData.id && userData.id !== "-1") {
            this._userService.updateUser(userData).subscribe({
                next: () => {

                    this._notificationService.displaySuccessMessage("User Updated", "Selected user successfully updated!");
                    this.closeUserEdit();
                },
                error: (error) => {

                    const message = this._errorHandler.handleServiceError(error).message;
                    this._notificationService.displayErrorMessage(`An exception occured updating the user: ${message}`);
                }
            });
        }
        // create a new user
        else {
            this._userService.create(userData).subscribe({
                next: () => {

                    this._notificationService.displaySuccessMessage("User Created", "New user successfully created!");
                    this.closeUserEdit();
                },
                error: (error) => {

                    const message = this._errorHandler.handleServiceError(error).message;
                    this._notificationService.displayErrorMessage(`An exception occured creating the user: ${message}`);
                }
            });
        }
    }


    toggleActiveState(): void {

        this.editableUser.active = !this.editableUser.active;
    }


    /**
     * Will update the teamAccess based on the selected team.  This way, if you change a team
     * then the teamAccess list will reflect the team to which you belong.
     *
     * @memberOf UserDetailsComponent
     */
    updateTeamAccess(newTeam: Team): void {

        const oldTeamId: string = this.editableUser.teamId;

        // remove old team from list of teamAccess
        for (let i = 0; i < this.editableUser.teamAccess.length; ++i) {
            if (this.editableUser.teamAccess[i] === oldTeamId) {
                this.editableUser.teamAccess.splice(i, 1);
                break;
            }
        }

        if (newTeam && newTeam.id) {
            this.editableUser.teamId = newTeam.id;

            // insert new team in teamAccess
            this.editableUser.teamAccess = this.editableUser.teamAccess.concat([newTeam.id]);
        }
    }
}

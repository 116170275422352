<div class="task-chart-view-component">
    <div data-layout="row">
        <div data-flex="40" class="chart-detail">
            <div class="chart-block">
                <canvas #canvas class="chart"></canvas>
            </div>
        </div>
        <div data-flex="20" class="chart-detail">
            <div class="view-stat-block">
                <div class="stat-block-figure">
                    {{ teamProgress.lateTasks }}
                </div>
                <div class="stat-block-label">
                    Late
                </div>
            </div>
        </div>
        <div data-flex="40" class="chart-detail">
            <div class="view-stat-block">
                <div class="stat-block-figure">
                    {{ teamProgress.finishedTasks }}
                    /
                    {{ teamProgress.tasksDue }}
                </div>
                <div class="stat-block-label">Flyers Complete</div>
            </div>
        </div>
    </div>
</div>

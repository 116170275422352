import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
    Injectable, Inject
} from "@angular/core";
import {
    HttpClient, HttpResponse, HttpHeaders, HttpParams
} from "@angular/common/http";

import { ModelUtil } from "@shopliftr/common-js/data";
import {
    Task, TaskCounts, TaskableType, Note, UserEventProgress
} from "@shopliftr/common-js/admin";
import {
    IFilter, Results
} from "@shopliftr/common-js/shared";
import {
    ExceptionHandlerService,
    HttpService,
    InvalidParametersException
} from "@shopliftr/common-ng";
import {
    throwError
} from "rxjs";

export interface ITaskUpdateOptions {
    assignee?: string;
    override?: boolean;
}


@Injectable({ providedIn: "root" })
export class TaskService extends HttpService {

    private readonly _teamTaskUrl: string;

    private readonly _teamTaskCountsUrl: string;

    private readonly _userUrl: string;

    private readonly _taskProgressUrl: string;

    private readonly _taskUrl: string;

    private readonly _searchTaskUrl: string;

    constructor(
        httpClient: HttpClient,
        exceptionHandlerService: ExceptionHandlerService,
        @Inject("AppConfig") private readonly _appConfig: any
    ) {

        super(httpClient, exceptionHandlerService);
        this._teamTaskUrl = `${_appConfig.apiUrl}/team/tasks`;
        this._userUrl = `${_appConfig.apiUrl}/user`;
        this._taskUrl = `${_appConfig.apiUrl}/task`;
        this._teamTaskCountsUrl = `${this._teamTaskUrl}/counts`;
        this._taskProgressUrl = `${this._userUrl}/task-progress`;
        this._searchTaskUrl = `${this._taskUrl}/search`;
    }


    getTask(id: any): Observable<Task> {

        const url = `${this._taskUrl}/${id}`;
        return this.get(url).pipe(
            map((response: HttpResponse<any>): Task => {

                return Task.deserialize(response.body);
            })
        );
    }


    getTaskCountsForTeam(startDate: number, endDate: number, teamId?: string): Observable<TaskCounts> {

        const body: {startTime?: number; endTime?: number; teamId?: string} = {};

        if (startDate) {
            body.startTime = startDate;
        }

        if (endDate) {
            body.endTime = endDate;
        }

        if (teamId) {
            body.teamId = teamId;
        }
        return this.post(this._teamTaskCountsUrl, body).pipe(
            map((response: HttpResponse<any>): TaskCounts => {

                return TaskCounts.deserialize(response.body);
            })
        );
    }


    getTaskProgressForUser(progressDate: string, startTime?: number, endTime?: number): Observable<UserEventProgress> {

        let queryParams = new HttpParams().append("progressDate", progressDate);

        if (startTime) {
            queryParams = queryParams.append("startTime", startTime.toString());
        }

        if (endTime) {
            queryParams = queryParams.append("endTime", endTime.toString());
        }

        return this.get(this._taskProgressUrl, queryParams).pipe(
            map((response: HttpResponse<any>): UserEventProgress => {

                return UserEventProgress.deserialize(response.body);
            })
        );
    }


    updateTaskState(task: Task, transition: string, options?: ITaskUpdateOptions): Observable<Task> {

        const url = `${this._taskUrl}/${task.id}/transition`;
        const body: any = {
            transition: transition,
            esVersion: task.esVersion
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        if (!task.stateManager.can(transition)) {
            return throwError(() => new InvalidParametersException(`Task cannot be transitioned to : ${transition}`));
        }

        if (options) {
            if (options.assignee) {
                body.assignee = options.assignee;
            }

            if (transition === "approve") {
                if (options.override) {
                    body.override = true;
                }
                else {
                    body.override = false;
                }
            }
        }

        if (transition === "reject" || transition === "wait") {
            body.notes = Note.serializeArray(task.notes);
        }

        return this.put(url, body).pipe(
            map((response: HttpResponse<any>): Task => {

                return Task.deserialize(response.body);
            })
        );
    }


    updateTaskNotes(task: Task, notes: Array<Note>): Observable<Task> {

        const url = `${this._taskUrl}/${task.id}`;
        const body: any = {
            notes: Note.serializeArray(notes),
            esVersion: task.esVersion
        };

        return this.put(url, body).pipe(
            map((response: HttpResponse<any>): Task => {

                return Task.deserialize(response.body);
            })
        );
    }


    assignTask(task: Task, userId: string, remember: boolean): Observable<Task> {

        const url = `${this._taskUrl}/${task.id}/assign`;
        const body = {
            assignee: userId || null,
            remember: remember,
            esVersion: task.esVersion
        };

        return this.put(url, body).pipe(
            map((response: HttpResponse<any>): Task => {

                return Task.deserialize(response.body);
            })
        );
    }


    unassignTask(task: Task): Observable<Task> {

        const url = `${this._taskUrl}/${task.id}/unassign`;

        const body = {
            esVersion: task.esVersion
        };

        return this.put(url, body).pipe(
            map((response: HttpResponse<any>): Task => {

                return Task.deserialize(response.body);
            })
        );
    }


    update(task: Task): Observable<Task> {

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const url = `${this._taskUrl}/${task.id}`;

        const values: any = {};
        for (const field in task.serialize()) {
            if (task[field] !== null) {
                values[field] = task[field];
            }
        }

        return this.put(url, JSON.stringify(values), headers).pipe(
            map((response: HttpResponse<any>): Task => {

                return Task.deserialize(response.body);
            })
        );
    }


    filterTasks(filters: Array<IFilter>, size: number, lastIndex: number, teamId?: string): Observable<Results<Task>> {

        const body: any = {
            filters: filters,
            size: size
        };

        if (lastIndex) {
            body["startIndex"] = lastIndex;
        }

        if (teamId) {
            body["teamId"] = teamId;
        }

        return this.post(this._searchTaskUrl, body).pipe(
            map((response: HttpResponse<any>): Results<Task> => {

                return Results.deserialize(response.body, ModelUtil.CamelCase, Task);
            })
        );
    }


    /**
     *
     * @param startDate
     * @param endDate
     * @param description
     * @param priority
     * @param teamId
     * @param assignableType
     * @param assignableId
     * @param taskableType
     * @param taskableId Can be FlyerVersion or String
     * @param notes
     */
    createFlyerTask(
        startDate: Date,
        endDate: Date,
        description: string,
        priority: number,
        teamId: string,
        assignableType: string,
        assignableId: string,
        notes: Array<Note>,
        flyerVersionId: string
    ): Observable<Task> {

        const body = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            description: description,
            priority: priority,
            teamId: teamId,
            assignableType: assignableType,
            assignableId: assignableId,
            taskableType: TaskableType.Flyer,
            notes: Note.serializeArray(notes),
            flyerVersionId
        };

        return this.post(`${this._appConfig.apiUrl}/task`, body).pipe(
            map((response: HttpResponse<any>): Task => {

                return Task.deserialize(response.body);
            })
        );
    }
}

import {
    Observable, throwError
} from "rxjs";
import {
    catchError, map
} from "rxjs/operators";

import {
    HttpClient, HttpHeaders, HttpParams
} from "@angular/common/http";
import {
    ErrorHandler, Inject, Injectable
} from "@angular/core";
import { InvalidParametersException } from "@shopliftr/common-js/data";

import {
    AuthenticationService, CommonErrorHandler
} from "@shopliftr/common-ng";


/**
 * Utility service to access certain reporting actions on batch
 */
@Injectable({ providedIn: "root" })
export class BatchUtilityService {

    private readonly _url: string;


    constructor(
        private readonly _authService: AuthenticationService,
        private readonly _httpClient: HttpClient,
        @Inject("AppConfig") private readonly _appConfig: any,
        @Inject(ErrorHandler) private readonly _errorHandler: CommonErrorHandler
    ) {

        this._url = this._appConfig.batchUrl;
    }


    /**
     * Triggers an email task to export grocery outlet ad units
     */
    groceryOutletAdExport(batchId: string): Observable<void> {

        if (batchId) {
            const url = `${this._url}/tasks/start/grocery-outlet-ad-export`;

            const params = new HttpParams();

            params.append("batchid", batchId);

            const headers = new HttpHeaders();

            headers.set("Authorization", this._authService.authToken.value.headerValue);

            return this._httpClient.get(url, {
                headers: headers,
                params: params,
                observe: "response"
            }).pipe(
                map((): any => {

                    // Using map to pass through this void observable allows the system to consistently process the output
                    // however the return type of this function needs to be any to avoid an automatic JSON conversion error
                    return;
                }),
                catchError((error: any) => {

                    return throwError(this._errorHandler.handleServiceError(error));
                })
            );
        }
        else {
            return throwError(new InvalidParametersException("A batch ID is required to run this task"));
        }
    }


    groceryOutletVideoAdsStatusReport(
        batchId: string,
        recipient?: string,
        campaignId = "9dea0b56-cbf2-4edb-ab65-57edc0d42062"
    ): Observable<void> {

        if (batchId) {
            const url = `${this._url}/tasks/start/grocery-outlet-ad-export`;

            const body: any = {
                batchId: batchId,
                campaignId: campaignId
            };

            if (!recipient) {
                const user = this._authService.authenticatedUser.value;

                body.recipients = user.email;
            }
            else {
                // TODO: if multiple emails are supported here, determine what the delimiter is and accept an array above
                body.recipients = recipient;
            }

            const headers = new HttpHeaders();

            headers.set("Authorization", this._authService.authToken.value.headerValue);

            return this._httpClient.post(url, body, {
                headers: headers,
                observe: "response"
            }).pipe(
                map((): any => {

                    // Using map to pass through this void observable allows the system to consistently process the output
                    // however the return type of this function needs to be any to avoid an automatic JSON conversion error
                    return;
                }),
                catchError((error: any) => {

                    return throwError(this._errorHandler.handleServiceError(error));
                })
            );
        }
        else {
            return throwError(new InvalidParametersException("A batch ID is required to run this task"));
        }
    }
}

import {
    Observable, of
} from "rxjs";
import {
    catchError, debounceTime, map
} from "rxjs/operators";

import {
    Directive, Input
} from "@angular/core";
import {
    AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors
} from "@angular/forms";

import {
    AbstractAsyncValidatorDirective, ProductService
} from "@shopliftr/common-ng";


/**
 * Validator to be attached to a UPC input field to determine whether or not the given UPC exists. It can be
 * configured to throw an error if the UPC doesn't exist (i.e. expectExists === true) or if it does exist
 * (i.e. expectExists === false)
 */
export const upcValidator = (expectExists: boolean, productService: ProductService): AsyncValidatorFn => {

    const upcError: any = {
        upcError: true
    };


    const fn = (control: AbstractControl): Observable<ValidationErrors | null> => {

        if (control.value) {
            return productService.getProduct(control.value as string).pipe(
                debounceTime(300),
                map(() => {

                    // The product was found
                    return (expectExists ? null : upcError);
                }),
                catchError(() => {

                    // If an error occurred, assume the product was not found
                    return of(expectExists ? upcError : null);
                })
            );
        }
        else {
            return of(null);
        }
    };

    return fn;
};


/**
 * Directive to utilize the upcValidator in declarative templates
 */
@Directive({
    selector: "[upcValidator]",
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: UpcValidatorDirective,
            multi: true
        }
    ]
})
export class UpcValidatorDirective extends AbstractAsyncValidatorDirective {

    /**
     * Controls the expectExists flag on the upcValidator function
     */
    @Input() upcValidator: boolean;


    inputName = "upcValidator";


    constructor(private readonly _productService: ProductService) {

        super();
    }


    createValidator = (): AsyncValidatorFn => {

        return upcValidator(this.upcValidator, this._productService);
    };


    /**
     * There are no special consideration for the input to this directive, as any further validation
     * would be handled by the input itself. This function is required by the AbstractAsyncValidatorDirective
     */
    normalizeInput = (input: string): string => {

        return input;
    };
}

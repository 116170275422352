<nav mat-tab-nav-bar class="secondary-nav-wrapper" [tabPanel]="tabPanel">
    <a mat-tab-link [active]="timePeriodIsToday" (click)="setDateFilter(filterTimePeriod.Today)">
        Today
    </a>
    <a mat-tab-link [active]="timePeriodIsWeek" (click)="setDateFilter(filterTimePeriod.Week)">
        This Week
    </a>
    <a mat-tab-link [active]="timePeriodIsMonth" (click)="setDateFilter(filterTimePeriod.Month)">
        This Month
    </a>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

<div class="user-dashboard-component">
    <div class="mgd-layout-page-content">
        <div class="mgd-layout-page-content-section-filter">
            <div data-layout="row" data-layout-gap="1rem">
                <div data-flex>
                    <mat-form-field>
                        <mat-label>Choose a date range</mat-label>
                        <mat-date-range-input
                            [rangePicker]="rangePicker"
                            mgdDateRangePicker
                        >
                            <input matStartDate placeholder="Start date" [(ngModel)]="startDate" name="start" #start="ngModel" readonly>
                            <input matEndDate placeholder="End date" [(ngModel)]="endDate" name="end" #end="ngModel" readonly required>
                        </mat-date-range-input>
                        <mat-hint>YYYY-MM-DD - YYYY-MM-DD</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #rangePicker disabled="false"></mat-date-range-picker>

                        <mat-error *ngIf="!start.control.getError('required') && start.errors">Invalid start date</mat-error>
                        <mat-error *ngIf="!end.control.getError('required') && end.errors">Invalid end date</mat-error>
                        <mat-error *ngIf="end.control.getError('required')">End date required</mat-error>
                        <mat-error *ngIf="start.control.getError('required')">Start date required</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <section class="mgd-layout-page-content-section-flat-top">
            <div data-layout="row">
                <div data-flex="33" class="view-stat">
                    <div class="view-stat-block">
                        <div class="stat-block-figure">{{ flyersCompleted }}</div>
                        <div class="stat-block-label">Flyers Completed</div>
                    </div>
                </div>
                <div data-flex="33" class="view-stat border-left">
                    <div class="view-stat-block">
                        <div class="stat-block-figure">{{ totalDealsEntered }}</div>
                        <div class="stat-block-label">Total Deals Entered</div>
                    </div>
                </div>
                <div data-flex="33" class="view-stat border-left">
                    <div class="view-stat-block">
                        <div class="stat-block-figure" *ngIf="averageDealEntryTime > 0">{{ averageDealEntryTime | number: '1.1-1' }}s</div>
                        <div class="stat-block-figure" *ngIf="averageDealEntryTime === 0">--</div>
                        <div class="stat-block-label">Avg. Deal Entry Time</div>
                    </div>
                </div>
            </div>

            <div>
                <div data-layout="row" class="headings">
                    <div class="flex-50-5">
                        Flyer
                    </div>
                    <div class="text-center flex-16-5">
                        Deals Added
                    </div>
                    <div class="text-center flex-16-5">
                        Deals Updated
                    </div>
                    <div class="text-center flex-16-5">
                        Deals Deleted
                    </div>
                </div>

                <div *ngIf="!searching">
                    <div data-layout="row" class="values" *ngFor="let progress of taskProgress">
                        <div class="task-name flex-50-5">
                            {{ progress.taskName }}
                            <span *ngIf="progress.complete">|
                                <em>
                                    Complete
                                </em>
                            </span>
                        </div>
                        <div class="text-center flex-16-5">
                            {{ progress.dealsAdded }}
                        </div>
                        <div class="text-center flex-16-5">
                            {{ progress.dealsUpdated }}
                        </div>
                        <div class="text-center flex-16-5">
                            {{ progress.dealsDeleted }}
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="searching" class="preloader-container">
                <div data-layout="row">
                    <div data-flex="100">
                        <div class="loader">Loading...</div>
                    </div>
                </div>
            </div>
            <div class="mgd-list-empty-state-container" data-layout-align="center center"
                    *ngIf="!searching && (!taskProgress || taskProgress.length === 0)">
                <div data-layout="row">
                    <div data-flex="100">
                        <img src="../../../assets/images/empty-state-stats.svg"/>
                        <p class="empty-state-warning">No statistics to show</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


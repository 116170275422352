import {
    Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy
} from "@angular/core";
import { Subscription } from "rxjs";

import { Team } from "@shopliftr/common-js/shared";
import {
    ApiKey, ApiUsagePlan
} from "@shopliftr/common-js/admin";
import {
    ApiKeyService, ExceptionHandlerService, NotificationService
} from "@shopliftr/common-ng";


@Component({
    selector: "api-key-form",
    templateUrl: "./api-key-form.component.html",
    styleUrls: ["./api-key-form.component.scss"]
})
export class ApiKeyFormComponent implements OnInit, OnChanges, OnDestroy {

    @Input() selectedApiKey: ApiKey;

    @Output() complete = new EventEmitter<ApiKey>(false);

    processing = false;

    selectedUsagePlan: ApiUsagePlan;

    usagePlans: Array<ApiUsagePlan>;

    team: Team;

    private readonly _subscription = new Subscription();

    constructor(
        private readonly _apiKeyService: ApiKeyService,
        private readonly _exceptionHandlerService: ExceptionHandlerService,
        private readonly _notificationService: NotificationService
    ) {}


    ngOnInit(): void {

        this._subscription.add(
            this._apiKeyService.getApiUsagePlans().subscribe({
                next: (usagePlans: Array<ApiUsagePlan>) => {

                    this.usagePlans = usagePlans;

                    if (this.selectedApiKey.usagePlan) {
                        for (const targetPlan of this.usagePlans) {
                            if (this.selectedApiKey.usagePlan.id === targetPlan.id) {
                                this.selectedUsagePlan = targetPlan;
                                break;
                            }
                        }
                    }
                },
                error: (error) => {

                    this._exceptionHandlerService.sendException(error);
                }
            })
        );
    }


    ngOnChanges(changes: SimpleChanges): void {

        if (changes.selectedApiKey) {
            if (this.selectedApiKey && this.selectedApiKey.teamId) {

                this.team = Team.deserialize({
                    id: this.selectedApiKey.teamId
                });
            }
            else {
                this.team = undefined;
            }
        }
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }


    setSelectedUsagePlan(): void {

        this.selectedApiKey.usagePlan = this.selectedUsagePlan;
    }


    submitApiKey(event: Event): void {

        event.preventDefault();

        this.processing = true;

        this.selectedApiKey.teamId = this.team ? this.team.id : undefined;

        if (this.selectedApiKey.key) {
            this._subscription.add(
                this._apiKeyService.updateApiKey(this.selectedApiKey).subscribe({
                    next: () => {

                        this._notificationService.displaySuccessMessage("Api Key Updated");
                        this.processing = false;
                        this.complete.emit(this.selectedApiKey);
                    },
                    error: (error) => {

                        this.processing = false;
                        this._exceptionHandlerService.sendException(error);
                    }
                })
            );
        }
        else {
            this._subscription.add(
                this._apiKeyService.createApiKey(this.selectedApiKey).subscribe({
                    next: (newApiKey: ApiKey) => {

                        this.processing = false;
                        this._notificationService.displaySuccessMessage("Api Key Created");
                        this.complete.emit(newApiKey);
                    },
                    error: (error) => {

                        this.processing = false;
                        this._exceptionHandlerService.sendException(error);
                    }
                })
            );
        }
    }


    cancel(event: Event): void {

        event.preventDefault();
        this.complete.next(undefined);
    }
}

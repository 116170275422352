import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    FormsModule, ReactiveFormsModule
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";

import { FormDirectivesModule } from "@shopliftr/common-ng";

import { BatchIdDialogComponent } from "./batch-id-dialog.component";
import { SharedModule } from "../../shared.module";


@NgModule({
    declarations: [
        BatchIdDialogComponent
    ],
    imports: [
        CommonModule,
        FormDirectivesModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        SharedModule
    ],
    exports: [
        BatchIdDialogComponent
    ],
    providers: []
})
export class BatchIdDialogModule {}

import { NgModule } from "@angular/core";

import { AdminModule } from "./admin/admin.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { ToolsModule } from "./tools/tools.module";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SmartRedirectGuard } from "./admin/smart-redirect.guard";

@NgModule({
    imports: [
        CoreModule,
        AdminModule,
        ToolsModule,
        SharedModule,
        AppRoutingModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        SmartRedirectGuard
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}

// Angular Components
import {
    Component, ViewEncapsulation, ViewContainerRef, OnDestroy
} from "@angular/core";
import {
    Router, Event, NavigationEnd
} from "@angular/router";
import { Subscription } from "rxjs";

declare let window: any;

@Component({
    selector: "mgd-admin",
    templateUrl: "./app.component.html",
    encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnDestroy {

    private readonly _subscriptions = new Subscription();

    constructor(
        private readonly _router: Router,
        public viewContainerRef: ViewContainerRef
    ) {

        this.viewContainerRef = viewContainerRef;

        this._subscriptions.add(
            this._router.events.subscribe((event: Event) => {

                if (event instanceof NavigationEnd) {
                    (window.dataLayer as Array<unknown>).push({
                        event: "pageView",
                        action: event.urlAfterRedirects
                    });
                }
            })
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }
}

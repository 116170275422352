import { FileSaverService } from "./../../services/file-saver.service";
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import { Subscription } from "rxjs";

import {
    DateTimeDisplayFormat, FilterTimePeriod, Team, User
} from "@shopliftr/common-js/shared";
import {
    TaskCounts, TeamProgress, UserProgress
} from "@shopliftr/common-js/admin";
import {
    AuthenticationService, CommonDatePipe, IAction, NotificationService, UserService
} from "@shopliftr/common-ng";

import { SecondsToTimePipe } from "../../pipes/seconds-time.pipe";

import { TaskService } from "../../services/task.service";
import {
    DateAdapter, MAT_DATE_LOCALE
} from "@angular/material/core";
import { LuxonDateAdapter } from "@angular/material-luxon-adapter";
import { DateTime } from "luxon";

@Component({
    selector: "team-dashboard",
    templateUrl: "./team-dashboard.component.html",
    styleUrls: ["./team-dashboard.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
            deps: [MAT_DATE_LOCALE]
        }
    ]
})
export class TeamDashboardComponent implements OnDestroy, OnInit, OnChanges {

    @Input() team: Team;

    @Output() onTaskNavigate: EventEmitter<string> = new EventEmitter<string>(false);


    debounceTimer: NodeJS.Timeout;

    actions: Array<IAction>;

    chartsLoading: boolean;

    currentTeamName: string;

    currentUser: User;

    progressDate: FilterTimePeriod;

    searching: boolean;

    selectedTeamId: string;

    taskCounts: TaskCounts;

    userProgress: Array<UserProgress>;

    teamProgress: TeamProgress;


    // Exposing time periods to template
    filterTimePeriod = FilterTimePeriod;

    private readonly _subscriptions = new Subscription();

    private _startDate: DateTime;

    private _endDate: DateTime;

    private _previousStartDate: DateTime;

    private _previousEndDate: DateTime;

    constructor(
        private readonly _authService: AuthenticationService,
        private readonly _commonDatePipe: CommonDatePipe,
        private readonly _notificationService: NotificationService,
        private readonly _taskService: TaskService,
        private readonly _userService: UserService,
        private readonly _fileSaverService: FileSaverService
    ) {}

    get timePeriodIsMonth(): boolean {

        return (this.progressDate === FilterTimePeriod.Month);
    }

    get timePeriodIsToday(): boolean {

        return (this.progressDate === FilterTimePeriod.Today);
    }

    get timePeriodIsWeek(): boolean {

        return (this.progressDate === FilterTimePeriod.Week);
    }

    get startDate(): DateTime {

        return this._startDate;
    }

    set startDate(startDate: DateTime) {

        this._endDate = null;
        this._startDate = startDate;
    }

    get endDate(): DateTime {

        return this._endDate;
    }

    set endDate(endDate: DateTime) {

        this._endDate = endDate;
        if (this._endDate &&
            endDate &&
            (this._previousStartDate?.valueOf() !== this._startDate?.valueOf() ||
            this._previousEndDate?.valueOf() !== this._endDate?.valueOf())) {
            this.search();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes["team"]) {
            if (this.team) {
                this.selectedTeamId = this.team.id;
                this.currentTeamName = this.team.name;

                if (this.startDate && this.endDate) {
                    this.search();
                }
            }
        }
    }


    ngOnDestroy(): void {

        clearTimeout(this.debounceTimer);

        this._subscriptions.unsubscribe();
    }


    ngOnInit(): void {

        this.actions = [
            {
                id: "download-to-csv",
                icon: "file_download",
                label: "Download to CSV"
            },
            {
                id: "print",
                icon: "print",
                label: "Print"
            }
        ];

        this.progressDate = FilterTimePeriod.Today;
        this.currentUser = this._authService.authenticatedUser.getValue();
        this.taskCounts = new TaskCounts();
        this.setDateFilter(FilterTimePeriod.Today);
        this.teamProgress = new TeamProgress();
        this.userProgress = this.teamProgress.getUserProgress();
    }


    actionTriggered(actionId: string): void {

        if (actionId === "download-to-csv") {
            this.exportToCsv(",");
        }
        else if (actionId === "print") {
            this.printReports();
        }
    }


    exportToCsv(delimiter: string): void {

        const processedStartDate = this._commonDatePipe.transform(this.startDate?.toJSDate(), DateTimeDisplayFormat.getFormat());
        const processedEndDate = this._commonDatePipe.transform(this.endDate?.toJSDate(), DateTimeDisplayFormat.getFormat());

        let csvContent = "";
        const lineDelimiter = "\n";
        const fileName = `${processedStartDate} - ${processedEndDate} - progress.csv`;
        csvContent += "Team Member";
        csvContent += delimiter;
        csvContent += "Deals Entered";
        csvContent += delimiter;
        csvContent += "Average Entry Time";
        csvContent += delimiter;
        csvContent += "Incomplete";
        csvContent += delimiter;
        csvContent += "Completed";
        csvContent += delimiter;
        csvContent += "Late";
        csvContent += delimiter;
        csvContent += "Cancelled";
        csvContent += lineDelimiter;

        for (const progress of this.userProgress) {
            csvContent += progress.userName;
            csvContent += delimiter;
            csvContent += progress.dealsEntered;
            csvContent += delimiter;
            csvContent += new SecondsToTimePipe().transform(progress.avgEntryTime);
            csvContent += delimiter;
            csvContent += progress.unfinishedTasks;
            csvContent += delimiter;
            csvContent += progress.finishedTasks;
            csvContent += delimiter;
            csvContent += progress.lateTasks;
            csvContent += delimiter;
            csvContent += progress.cancelledTasks;
            csvContent += lineDelimiter;
        }

        const blob: Blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        try {
            this._fileSaverService.saveFile(blob, fileName);
        }
        catch (error) {
            const link = document.createElement("a");
            link.download = fileName;

            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = fileName;
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    goToTasks(filter?: string): void {

        this.onTaskNavigate.emit(filter);
    }


    getTaskCounts(): void {

        this.chartsLoading = true;

        this._previousStartDate = this._startDate;
        this._previousEndDate = this._endDate;

        this._subscriptions.add(
            this._taskService.getTaskCountsForTeam(
                this.startDate?.valueOf(),
                this.endDate?.valueOf(),
                this.selectedTeamId
            ).subscribe({
                next: (taskCounts: TaskCounts) => {

                    this.taskCounts = taskCounts;
                    this.chartsLoading = false;
                },
                error: (error) => {

                    this.chartsLoading = false;
                    this._notificationService.displayErrorMessage((error as Error).message);
                },
                complete: () => {

                    this.chartsLoading = false;
                }
            })
        );
    }


    getTeamProgress(): void {

        this.searching = true;

        this._subscriptions.add(
            this._userService.getUserProgressForTeam(
                this.startDate?.valueOf(),
                this.endDate?.valueOf(),
                this.selectedTeamId
            ).subscribe({
                next: (teamProgress: TeamProgress) => {

                    this.teamProgress = teamProgress;
                    this.userProgress = teamProgress.getUserProgress();

                    // sort the userProgress list alphabetically
                    this.userProgress.sort((u1, u2) => {

                        const name1 = u1.userName?.toLowerCase();
                        const name2 = u2.userName?.toLowerCase();
                        if (name1 < name2) {
                            return -1;
                        }
                        if (name1 > name2) {
                            return 1;
                        }
                        return 0;
                    });

                    this.searching = false;
                },
                error: (error) => {

                    this.searching = false;
                    this._notificationService.displayErrorMessage((error as Error).message);
                }
            })
        );
    }


    printReports(): void {

        window.print();
    }


    search(): void {

        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(() => {

            this.getTeamProgress();
            this.getTaskCounts();
        }, 500);
    }


    setDateFilter(filter: FilterTimePeriod): void {

        this.progressDate = filter;

        const startDate = new Date();
        const endDate = new Date();

        const day = startDate.getDay();
        const date = startDate.getDate();

        switch (filter) {
        case FilterTimePeriod.Week:
            startDate.setDate(date - day);
            endDate.setDate(date + (7 - day));

            break;
        case FilterTimePeriod.Month:
            startDate.setDate(1);

            if ([1, 3, 5, 7, 8, 10, 12].includes(endDate.getMonth())) {
                endDate.setDate(31);
            }
            else if (endDate.getMonth() === 2) {
                endDate.setDate(endDate.getFullYear() % 4 === 0 ? 29 : 28);
            }
            else {
                endDate.setDate(30);
            }

            break;
        }

        this._startDate = DateTime.fromJSDate(startDate).startOf("day");
        this._endDate = DateTime.fromJSDate(endDate).endOf("day");

    }

}

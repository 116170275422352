import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { bindCallback } from "rxjs";
import { of } from "rxjs";

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self
} from "@angular/core";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { FocusMonitor } from "@angular/cdk/a11y";
import {
    ControlValueAccessor, FormControl, NgControl
} from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";

import {
    Country, StateCa, StateUs
} from "@shopliftr/common-js/shared";
import {
    MapsApiLoaderService, ExceptionHandlerService
} from "@shopliftr/common-ng";
import { ObjectUtil } from "@shopliftr/common-js/data";


export interface ILocationPickerResult {
    label: string;
    address?: string;
    city?: string;
    country?: Country;
    state?: StateCa | StateUs;
    zip?: string;
    latLng: google.maps.LatLng;
}


@Component({
    selector: "location-picker",
    templateUrl: "./location-picker.component.html",
    styleUrls: ["./location-picker.component.scss"],
    providers: [{
        provide: MatFormFieldControl,
        useExisting: LocationPickerComponent
    }]
})
export class LocationPickerComponent implements ControlValueAccessor, OnDestroy, OnInit, MatFormFieldControl<google.maps.LatLng> {

    static nextId = 0;

    @HostBinding() id = `location-picker-${LocationPickerComponent.nextId++}`;

    @HostBinding("attr.aria-describedby") describedBy = "";

    locationControl = new FormControl();

    geocoder: google.maps.Geocoder;

    inputStream = new Subject<string>();

    isDisabled: boolean;

    location: google.maps.LatLng;

    controlType = "location-picker";

    errorState = false;

    focused = false;

    stateChanges = new Subject<void>();

    storedLocation: ILocationPickerResult = this.nullLocation;

    onChange: (_: any) => unknown;

    onTouched: () => unknown;

    @HostBinding("class.floating")
    get shouldLabelFloat(): boolean {

        return coerceBooleanProperty(this.location || this.focused || !this.empty);
    }

    @Input()
    get disabled(): boolean {

        return this._disabled;
    }

    set disabled(disabled: boolean) {

        this._disabled = coerceBooleanProperty(disabled);
        this.stateChanges.next();
    }

    private _disabled = false;

    get empty(): boolean {

        return !this.locationControl.value;
    }

    @Input()
    get placeholder(): string {

        return this._placeholder;
    }

    set placeholder(placeholder: string) {

        this._placeholder = placeholder;
        this.stateChanges.next();
    }

    private _placeholder = "Location";

    @Input()
    get required(): boolean {

        return this._required;
    }

    set required(required: boolean) {

        this._required = coerceBooleanProperty(required);
        this.stateChanges.next();
    }

    private _required = false;

    private _forceError: string;

    get nullLocation(): ILocationPickerResult {

        return {
            label: "",
            latLng: null
        };
    }

    get value(): google.maps.LatLng {

        return this.location;
    }

    set value(location: google.maps.LatLng) {

        this.writeValue(location);
    }

    constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _elementRef: ElementRef<HTMLElement>,
        private readonly _exceptionHandlerService: ExceptionHandlerService,
        private readonly _focusMonitor: FocusMonitor,
        private readonly _mapApiLoader: MapsApiLoaderService,
        @Optional() @Self() public ngControl: NgControl
    ) {

        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }

        _focusMonitor.monitor(_elementRef.nativeElement, true).subscribe((origin) => {

            this.focused = Boolean(origin);
            this.stateChanges.next();
        });
    }


    ngOnInit(): void {

        // ensure the map API is loaded before listening
        this.disabled = true;
        this._mapApiLoader.load().then(() => {

            this.disabled = this.isDisabled;
            this.geocoder = new google.maps.Geocoder();

            this.writeValue(this.location);

            this.inputStream.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((value: string): Observable<{ label: string; latLng: google.maps.LatLng }> => {

                    return this._getLocations(value);
                })
            ).subscribe({
                next: (location: ILocationPickerResult) => {

                    this.storedLocation = location;

                    // Required by the Google components
                    this._changeDetectorRef.detectChanges();

                    this.onTouched();
                },
                error: (error) => {

                    throw (error);
                }
            });
        }, (error) => {

            this.disabled = true;
            this._exceptionHandlerService.sendException({
                message: error
            });
        });
    }


    ngOnDestroy(): void {

        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
    }


    /* ***** ControlValueAccessor ***** */
    registerOnChange(fn: any): void {

        this.onChange = fn;
    }


    registerOnTouched(fn: any): void {

        this.onTouched = fn;
    }


    writeValue(value: google.maps.LatLng | {label: string; latLng: google.maps.LatLng } | { latitude: number; longitude: number}): void {

        if (this.geocoder) {
            if (value) {

                let inputValue: string;
                let latLng: google.maps.LatLng;
                if (typeof (value) === "string") {
                    inputValue = value;
                }
                else if (value instanceof google.maps.LatLng) {
                    latLng = value;
                }
                else if (ObjectUtil.isDefined((value as { label: string; latLng: google.maps.LatLng }).label)
                    && ObjectUtil.isDefined((value as { label: string; latLng: google.maps.LatLng }).latLng)) {
                    inputValue = (value as { label: string; latLng: google.maps.LatLng }).label;
                    latLng = (value as { label: string; latLng: google.maps.LatLng }).latLng;
                }
                else {
                    latLng = new google.maps.LatLng(
                        (value as { latitude: number; longitude: number }).latitude,
                        (value as { latitude: number; longitude: number }).longitude
                    );
                }

                this._getLocations(inputValue, latLng).subscribe((location: ILocationPickerResult) => {

                    this.storedLocation = location;

                    this.setText(this.storedLocation.label, true);
                });
            }
            else {
                this.storedLocation = this.nullLocation;

                this.setText(undefined, true);
            }
        }
        else {
            setTimeout(() => {

                this.writeValue(value);
            }, 300);
        }
    }


    /* ***** MatFormFieldControl ***** */
    onContainerClick(event: MouseEvent): void {

        if ((event.target as Element).tagName.toLowerCase() !== "input") {
            this._elementRef.nativeElement.focus();
        }
    }


    setDescribedByIds(ids: Array<string>): void {

        this.describedBy = ids.join(" ");
    }


    setErrorState(): void {

        if (this.ngControl && this._forceError) {
            this.ngControl.control.setErrors({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                "location-error": this._forceError
            });

            this.errorState = true;
        }
        else {
            let state = false;

            if (this.ngControl && this.ngControl.errors) {
                const oldErrors = this.ngControl.errors;

                delete (oldErrors["location-error"]);

                const keys = Object.keys(oldErrors);

                if (keys.length) {
                    this.ngControl.control.setErrors(oldErrors);

                    for (const condition of keys) {
                        state = state || this.ngControl.hasError(condition);
                    }
                }
                else {
                    this.ngControl.control.setErrors(undefined);

                    state = false;
                }
            }

            this.errorState = state;
        }
    }


    /* ***** Selector Functions ***** */
    onBlur(): void {

        setTimeout(() => {

            if (this.storedLocation && this.location !== this.storedLocation.latLng) {
                this.setText(this.storedLocation.label);
            }
        }, 300);
    }


    search(): void {

        this.onTouched();
        this.inputStream.next(this.locationControl.value as string);
    }


    setLocation(skipEvent?: boolean): void {

        this.location = this.storedLocation ? this.storedLocation.latLng : undefined;

        if (!skipEvent) {
            this.onChange(this.storedLocation);
        }
    }


    setText(value: string, skipEvent?: boolean): void {

        this.locationControl.setValue(value);

        this.setLocation(skipEvent);
    }


    private _getLocations(inputValue?: string, location?: google.maps.LatLng): Observable<ILocationPickerResult> {

        let locationObservable: Observable<ILocationPickerResult>;

        if (inputValue || location) {
            const geocodeRequest: google.maps.GeocoderRequest = {
                address: inputValue,
                location: location
            };

            // Eslint rule is being improperly applied.
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            const geocode = bindCallback(this.geocoder.geocode);
            locationObservable = geocode(geocodeRequest)
                .pipe(map((locationResponse: [Array<google.maps.GeocoderResult>, google.maps.GeocoderStatus]) => {

                    const results = locationResponse[0];
                    const status = locationResponse[1];

                    let response: ILocationPickerResult = this.nullLocation;

                    if (status === google.maps.GeocoderStatus.OK) {
                        const targetResult: any = results[0];
                        let streetNumber: string;
                        let route: string;

                        this._forceError = undefined;

                        response = {
                            label: targetResult.formatted_address,
                            latLng: targetResult.geometry.location
                        };

                        for (const component of targetResult.address_components) {
                            switch (component.types[0]) {
                            case "administrative_area_level_1":
                                response.state = component.short_name;
                                break;
                            case "administrative_area_level_2":
                                response.city = response.city || component.long_name;
                                break;
                            case "country":
                                response.country = component.short_name;
                                break;
                            case "locality":
                                response.city = component.long_name;
                                break;
                            case "postal_code":
                                response.zip = component.short_name;
                                break;
                            case "route":
                                route = component.short_name;
                                break;
                            case "street_number":
                                streetNumber = component.short_name;
                                break;
                            }
                        }

                        if (streetNumber && route) {
                            response.address = streetNumber + " " + route;
                        }

                        this.setErrorState();
                    }
                    else {
                        switch (status) {
                        case google.maps.GeocoderStatus.ZERO_RESULTS:
                            this._forceError = "Could not resolve the given location";

                            break;
                        default:
                            this._forceError = "Something went wrong, please try again.";
                        }

                        this.setErrorState();
                    }

                    return response;
                }));
        }
        else {
            locationObservable = of(this.nullLocation);
        }

        return locationObservable;
    }
}
